import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useTranslate from '../utils/useTranslate'
import CardBig from './CardBig';
import ConfirmationPopup from './ConfirmationPopup';
import ReprintLabelsFooter from './ReprintLabelsFooter'
import { motion } from 'framer-motion';
import { ProductContext } from '../contexts/ProductProvider';
import { Button, SelectBox, Validator } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/cjs/validator';
import { Form, GroupItem, Label, SimpleItem } from 'devextreme-react/cjs/form';
import ReprintLabelsList from './ReprintLabelsList';
import { LoadPanel } from 'devextreme-react/load-panel';


const animationProps = {
    initial: { x: '-100%', opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: '100%', opacity: 0 },
    transition: { duration: 0.3 }
  }

const ReprintLabelsConfirmPage = ({setPage, buttonsListPage2, isChooseStorePopupVisible, setIsChooseStorePopupVisible, role, startFromLabelNumber, setStartFromLabelNumber, isLoading
    // printOptionsFormRef
}) => {
    const translate = useTranslate()
    const { reprintLabelsStore, selectedLabelFormat, setSelectedLabelFormat, manualStockLabelPrint, setManualStockLabelPrint } = useContext(ProductContext)
    // const [labelFormats, setLabelFormats] = useState([]) //all the formats retrieved from API 
    
    const openChooseStorePopup = () => {
        setIsChooseStorePopupVisible(true)
    }

    const manualStockOptions = [
        { value: 'from detection', text: translate('from detection') },
        { value: 'from stock', text: translate('from stock') }
    ]

    //it is correct, they are not corresponding from front to value, copied from desktop app
    const labelFormats = [
        { value: 'REPORT_PRINT_LABEL', text: `${translate('Label')} 1`},
        { value: 'REPORT_PRINT_LABEL_1', text: `${translate('Label')} 2` },
        { value: 'REPORT_PRINT_LABEL_2', text: `${translate('Label')} 3` },
        { value: 'REPORT_PRINT_LABEL_3', text: `${translate('Label')} 4` },
        { value: 'REPORT_PRINT_LABEL_4', text: `${translate('Label')} 5` },
        { value: 'REPORT_PRINT_LABEL_5', text: `${translate('Label')} 6` },
        { value: 'REPORT_PRINT_LABEL_6', text: `${translate('Label')} 7` },
        { value: 'REPORT_PRINT_LABEL_7', text: `${translate('Label')} 8` },
        { value: 'REPORT_PRINT_LABEL_8', text: `${translate('Label')} 9` },
        { value: 'REPORT_PRINT_LABEL_9', text: `${translate('Label')} 10` },
    ]

    //generate array of numbers 1-50
    const startFromLabelOptions = Array.from({ length: 50 }, (_, i) => ({
        text: (i + 1).toString(),
        value: i + 1,
      }));
    // const fetchLabelFormats = async () => {
    //     // TODO: implement fetching label formats logic
    //     // we'll get an array
    //     let data = [
    //         {
    //             ID: 1,
    //             SEARCH_NAME: 'Label 1',
    //             DESCRIPTION: '24 x 30 cm'
    //         },
    //         {
    //             ID: 2,
    //             SEARCH_NAME: 'Label 2',
    //             DESCRIPTION: '24 x 30 cm'
    //         },
    //     ]
    //     setLabelFormats(data)
    // }

    // useEffect(() => {
    //     fetchLabelFormats()
    // }, [])

    const onLabelFormatChanged = useCallback((e) => {
        setSelectedLabelFormat(e.value)
    //   setReprintLabelsStore(e.component.option("text"))
    }, []);   

    const onManualStockChanged = useCallback((e) => {
        setManualStockLabelPrint(e.value)
        //   setReprintLabelsStore(e.component.option("text"))
    }, []);  

    const onStartFromLabelChanged = useCallback((e) => {
        setStartFromLabelNumber(e.value)
    },[]); 

    // console.log('printOptionsFormRef' ,printOptionsFormRef);
    
    

  return (
    <>
        <motion.div 
          className={`scroll-view bg-light-grey`}
          id='stock-loadpanel-container'
          {...animationProps}
        >
            <LoadPanel 
            visible={isLoading}
            hideOnOutsideClick={false}
            container='#stock-loadpanel-container'
            shading={true}
            shadingColor="rgba(0, 0, 0, 0.2)"
            />
            <CardBig title={translate('Store')}>
                <div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className='fs-14'>{reprintLabelsStore}</p>
                        {(role == 0 || role == 1) &&
                            <div className='p-2' onClick={openChooseStorePopup}>
                                <i className='fs-16 fa-solid fa-pen-to-square grey'></i>
                            </div>
                        } 
                    </div>
                </div>
            </CardBig>
            
            {/* <p className='list-title'>{translate('Currently selected store')}</p> */}

            <CardBig title={translate('Print options')}>
                {/* <div> */}
                {/* <form> */}
                    {/* <Form validationGroup='printOptions' ref={printOptionsFormRef}> */}
                    
                    {/* DroupItem al posto di div */}

                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <p className='fs-14'>{translate('Choose the label format *')}</p>
                        <SelectBox 
                            className='mt-1 mb-4 '
                            dataSource={labelFormats}
                            displayExpr= 'text'
                            valueExpr='value'   
                            // defaultValue={reprintLabelsStore} 
                            // searchEnabled={true}
                            onValueChanged={onLabelFormatChanged}
                            placeholder={translate('Select...')}
                            acceptCustomValue={false}
                            // editorOptions={storesEditorOptions}
                            noDataText={translate('No label format found')}
                            validationMessagePosition='right'
                        >
                            {/* <Validator>
                                <RequiredRule message="Required field" />
                            </Validator> */}
                        </SelectBox>
                    </div>

                    {/* DroupItem al posto di div */}
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <p className='fs-14'>{translate('Print labels *')}</p>
                        <SelectBox 
                            className='mt-1 mb-4 '
                            dataSource={manualStockOptions}
                            displayExpr= 'text'
                            valueExpr='value'   
                            defaultValue={manualStockLabelPrint} 
                            // searchEnabled={true}
                            onValueChanged={onManualStockChanged}
                            placeholder={translate('Select...')}
                            acceptCustomValue={false}
                            // editorOptions={storesEditorOptions}
                            // noDataText={translate('No store found')}
                            validationMessagePosition='right'
                        >
                            {/* <Validator>
                                <RequiredRule message="Required field" />
                            </Validator> */}
                        </SelectBox>
                    </div>

                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <p className='fs-14'>{translate('Start from label *')}</p>
                        <SelectBox 
                            className='mt-1 mb-4 '
                            dataSource={startFromLabelOptions}
                            displayExpr= 'text'
                            valueExpr='value'   
                            defaultValue={startFromLabelNumber} 
                            // searchEnabled={true}
                            onValueChanged={onStartFromLabelChanged}
                            placeholder={translate('Select...')}
                            acceptCustomValue={false} // Prevents manual input
                            validationMessagePosition='right'
                        >
                            {/* <Validator>
                                <RequiredRule message="Required field" />
                            </Validator> */}
                        </SelectBox>
                    </div>
                    <div className='d-flex mt-1'>
                        <p>{translate("* mandatory fields")}</p>
                    </div>

                
                {/* </Form> */}
                {/* </form> */}
                {/* </div> */}

                    {/* dropdown / radio btn ? */}
                {/* piccolo paragrefo su cosa cio significa, o nella i di fianco a giacenza, meglio */}

            </CardBig>
            <CardBig title={translate('Items list')}>
                {/* {manualStockLabelPrint == 'manually' ? ( */}
                
                {/* // {updatedLabelsList && */}
                    <ReprintLabelsList 
                        showTitle={false}
                        showQuantities={manualStockLabelPrint != 'from stock'}  
                    /> {/* hide quantities when print is set to 'from stock' */}
                {/* //   } */}
                {/* ) : (
                    <ReprintLabelsList/>
                )
                } */}
            </CardBig>



            {/* possibility to change qty if manually ? */}


            {/* lista? */}
        </motion.div>


    
    
        <ReprintLabelsFooter 
            // handleGoBack={handleGoBack}
            // handlePrint={handlePrint}
            buttonsList={buttonsListPage2}
            // roleString={roleString}
            // inventoryApi={inventoryApi}
            // updatedLabelsList={updatedLabelsList}
        />

    {/* <ConfirmationPopup
        popupTitle={popupTitle} 
        popupMessage={renderPopupBody()}
        popupButtons={popupButtons}
        dependency={popupButtons} //allows the enable of btn when store is selected
        isPopupVisible={isPopupVisible}
        // hidePopup
        hideOnOutsideClick={false}
        // showTitle = {false}
        showCloseButton = {false} 
      /> */}
    </>
  )
}

export default ReprintLabelsConfirmPage



// <GroupItem 
// caption={translate('Personal Data')}
// >
// <SimpleItem 
//     dataField='firstname' 
//     editorType="dxTextBox" 
//     isRequired={true} 
//     // editorOptions={textFieldEditorOptions}
// >
//     <RequiredRule message={translate('Required field')} />
//     <Label visible={true} text={translate('Name')}/>
// </SimpleItem>
// </GroupItem>