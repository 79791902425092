import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ArgumentAxis, Chart, CommonSeriesSettings, Legend, Series, Tooltip} from 'devextreme-react/chart';
// import { dataSource } from '../assets/mockDataChart';
import Cookies from 'js-cookie';
// import useTranslate from '../../utils/useTranslate';
import { ProductContext } from '../contexts/ProductProvider';
// import Stock from '../../api/ApiCallsStock';
// import notify from 'devextreme/ui/notify';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import useTranslate from '../utils/useTranslate';
import { differenceInCalendarDays, differenceInCalendarWeeks, eachDayOfInterval, eachWeekOfInterval, endOfDay, format, getDaysInMonth, startOfWeek, subMonths } from 'date-fns';
import { status } from '../utils/constants/filters';
import { getStatusColor } from '../utils/functions';
// import { getDaysInMonth } from 'date-fns';

const currency = Cookies.get('currency')
const now = new Date()

const OrdersChart = ({ 
    orders, // saranno gli ordini gia iltrati dal padre
//   filter,
  formatDateToYMD
}) => {


  const {calendarDate, isCumulative, dashboardStoreId, dashboardSupplierId, isGross, timeFrame, dashboardRawData, setDashboardRawData, datesRange, filterOrders: filter, openClosedOrders} = useContext(ProductContext);
  const [filteredStatus, setFilteredStatus] = useState(status)

//   const translate = useTranslate();
//   const currency = Cookies.get('currency')

//   const { getDashboard4 } = Stock() // removed getDashboard2,
//   const abortControllerRefDashChart = useRef(null);
//   const [errors, setErrors] = useState(false);
//   // const [errorMessage, setErrorMessage] = useState(false)
//   const [chartData, setChartData] = useState()  //datasource for chart
//   const [activeRequestCount, setActiveRequestCount] = useState(0); 
  const [isLoading, setLoading] = useState(false) //TODO set true once API CALL
  const [chartData, setChartData] = useState()  //datasource for chart

  const translate = useTranslate()


  //nel moment in cui passo mese corrente mese precedente devo ricavare comunque il mese per sapere quanti gg ha 

//  // populate the chart based on isCumulative and isGross
useEffect(() => {
    const calculateChartData = (data) => { //data = orders
   
    
      //////////option 2 directly with the 2 set dates
      let part1 = 'TOTAL';
      let part2 = isGross ? 'GROSS' : 'NET';
      const valueField = `${part1}_${part2}`


      // if startDate and endDate are not dates, but i think they are, TODO check
      const startDate = new Date(datesRange.startDate);      
      const endDate = new Date(datesRange.endDate);      
      let daysDiff  = (differenceInCalendarDays(datesRange.endDate, datesRange.startDate)+1); //+1 to iclude both starting and ending day     
      let groupedByWeek = daysDiff > 31

      // ottengo un array di date
      let transformed = groupedByWeek 
        ? eachWeekOfInterval({start: startDate, end: endDate }).map(date => ({
            DATE: `${translate('Week')} ${format(startOfWeek(date), 'dd-MM')}`,
            'INSERITO': 0,
            'LAVORAZIONE': 0,
            'LETTERA VETTURA': 0,
            'PARZIALMENTE EVASO': 0,
            'EVASO': 0,
            'RESO': 0,
            'RESO PARZIALE': 0,
        }))
        : eachDayOfInterval({start: startDate, end: endDate }).map(date => ({
            DATE: format(date, 'dd-MM'),
            'INSERITO': 0,
            'LAVORAZIONE': 0,
            'LETTERA VETTURA': 0,
            'PARZIALMENTE EVASO': 0,
            'EVASO': 0,
            'RESO': 0,
            'RESO PARZIALE': 0,
        }))

        console.log('transformed', transformed);

      //   //cerca in ordini se c'è un datapoint =, se c'è prende il datap[orderStatus] e lo somma a i precedenti
      //data = orders
      data.forEach(order =>{
        const orderDate = new Date(order.DATE);
        // const key = groupedByWeek ? `Week ${format(orderDate, 'w')}` : format(orderDate, 'dd-MM')
        const key = groupedByWeek ? `${translate('Week')} ${format(startOfWeek(orderDate), 'dd-MM')}` : format(orderDate, 'dd-MM')
        const dataPoint = transformed.find(item => item.DATE == key)
        console.log(('datapoint', dataPoint));
        
        if(dataPoint){
          dataPoint[order.STATUS] += order.PRICE;
          // transformed.DATE[item.STATUS] += item.PRICE // add toLowerCase()? //if we change to total grosss/net --> order[valueField]
        }
        // if(dataPoint){
        //     dataPoint[item.STATUS] += item.PRICE // add toLowerCase()? //if we change to total grosss/net --> order[valueField]
        // }
    })
    console.log('transformed posst data', transformed);

    console.log('orders', orders);
    console.log('data', data);
    
    setChartData(transformed)
    }
    calculateChartData(orders)
}, [datesRange.endDate, datesRange.startDate, isGross, orders])
  // }
//   }, [calendarDate, isGross, timeFrame])




useEffect(()=>{
  // const filterStatus = () => {
      const statusWithoutAll = status.slice(1);
      const newStatus = openClosedOrders == 'all'
        ? statusWithoutAll //to remove the "all"
        : statusWithoutAll.filter(status => status.OPEN_CLOSED.includes(openClosedOrders));
      setFilteredStatus(newStatus);
      // }
  // filterStatus()
},[openClosedOrders])






const customizeTooltip = (pointInfo) => ({
  text: `${currency} ${Intl.NumberFormat('it-IT').format(parseInt(pointInfo.valueText))}`
});

const legendClickHandler = (e) => {
  const series = e.target;
  if (series.isVisible()) {
      series.hide();
  } else {
      series.show();
  }
}

  return (
    isLoading ? (
      <LoadIndicator
        visible={isLoading} 
      />
  ) : (
    <div>
      {/* {errors ? (
        <p className='pt-2'>
          <i className='dx-icon-pulldown big-number text-end'></i>
        </p>
      ) : (     */}
    <Chart
      id="orders-chart"
      dataSource={chartData}
      width={'100%'}
      height={240}
      onLegendClick={e => legendClickHandler(e)}
    >
      <Tooltip
        enabled={true}
        customizeTooltip={customizeTooltip}
        zIndex={1}
       />
       <ArgumentAxis 
        argumentType='string'
      />
      <CommonSeriesSettings
        argumentField='DATE'
        type='stackedbar'
      />

      {filteredStatus?.map((statusItem) => (
          <Series
            key={statusItem.DESCRIPTION}
            valueField={statusItem.DESCRIPTION}
            name={(translate(statusItem.DESCRIPTION)).toLowerCase()}
            color={getStatusColor(statusItem.DESCRIPTION)}
          />
      ))}

     
      <Legend 
        horizontalAlignment="center"
        verticalAlignment="top"
        itemTextPosition="right"
      />
    </Chart>
    {/* )} */}
    </div>
  )
  )
}

export default OrdersChart

 {/* <Series
        valueField="inserted"
        color="rgba(114, 165, 207, 0.5)"
        name={translate('inserted')}
      />
      <Series
        valueField="processing"
        color="rgba(238, 186, 105, 0.5)"
        name={translate('processing')}
      />
      <Series
        valueField="waybill"
        color="rgba(207, 201, 99, 0.5)"
        name={translate('waybill')}
      />
      <Series
        valueField="dispatched"
        color="rgba(213, 213, 213, 0.5)"
        name={translate('dispatched')}
      />
      <Series
        valueField="returned"
        color="rgba(104, 33, 122, 0.5)"
        name={translate('returned')}
      />
      <Series
        valueField="part. returned"
        color="rgba(171, 132, 174, 0.5)"
        name={translate('part. returned')}
      /> */}
 
      

// {chartData?.map(item => {
//   let color;
//   switch (item.STATUS) {
//     case 'inserted':
//       color="rgba(114, 165, 207, 0.5)"
//       break;
//     case 'processing':
//       color="rgba(238, 186, 105, 0.5)"
//       break;
//     case 'waybill':
//       color="rgba(207, 201, 99, 0.5)"
//       break;
//     case 'dispatched':
//       color="rgba(213, 213, 213, 0.5)"
//       break;
//     case'returned':
//       color="rgba(104, 33, 122, 0.5)"
//     break;
//     case 'part. returned':
//       color="rgba(171, 132, 174, 0.5)"
//       break;
//     default:
//       color = 'black';
//       break;
//   }

//   return (
//     <Series
//       key={item.DATE}
//       // type: 'bar',
//       argumentField= 'DATE'
//       valueField= {status.DESCRIPTION}
//       name={translate(status.DESCRIPTION)}
//       visible={filteredStatus.find(status => status.STATUS.toLowerCase() === item.STATUS.toLowerCase())? true : false}
//       color={color}
//     />
// )})}




//   const dashboardChart = useCallback(async (period) => {
//     setActiveRequestCount(prevCount => prevCount + 1); //keep trak of aborted calls to set loader properly
//     setLoading(true)
//     setErrors(false)

//     const optionalDate = calendarDate ? formatDateToYMD(calendarDate) : ''

//     try {
//       if (abortControllerRefDashChart.current) {
//         abortControllerRefDashChart.current.abort();
//       }
//       abortControllerRefDashChart.current = new AbortController();
//       const signal = abortControllerRefDashChart.current.signal;

//       const docType = 'DIVIDED'
      
//       let { success: successDivided, data: dataDivided, error: errorDivided } = await getDashboard4(`{"PERIOD":"${period}","ID_STORE":"${dashboardStoreId}","ID_SUPPLIER":"${dashboardSupplierId}","ID_SALE_CHANNEL":"00000000-0000-0000-0000-000000000000","DOCTYPE":"${docType}","TO_DATE":"${optionalDate}","PARTITION_BY":"PERIOD"}`,signal)
//       // LUCA: nuovo parametro opzionale TO_DATE che permette di personalizzare la data di riferimento.
//       // Per visualizzare correttamente i dati di un mese/anno passato andrebbe settato sull'ultimo giorno di quel periodo (es. 31/12 o 31/07, 30/05, ...)    

//       //DOCTYPE     ("ALL" tutti i dati assieme,"DIVIDED" tutti i dati divisi ,"INSTORE","ONLINE") 
//       //PARTITION_BY  ("" tutti i dati assieme,"PERIOD" divisi per frazione del periodo ,"STORE","SALE_CHANNEL")     
//       // if(successDivided){
//       setDashboardRawData(dataDivided)
//       calculateChartData(dataDivided);
//       // } else {
//       //   // setErrors(errorDivided?.message || 'Failed to fetch chart data');
//       //   setErrors(true);
//       // }
//     } catch (error) {
//       if (!error.name === 'CanceledError') {  //if api call not aborted 
//         notify(translate(error.message) || translate('Error'), 'error', 3000);
//         // setErrors(true)
//       }
//     } finally {
//       setActiveRequestCount(prevCount => prevCount - 1);
//       // setErrors(dashboardRawData ? false : true);
//     }
//   },[calendarDate, timeFrame, dashboardStoreId, dashboardSupplierId]); //removed getDashboard2, setDashboardRawData

//   useEffect(() => {
//     setLoading(activeRequestCount > 0);
//   }, [activeRequestCount])

//   // if after loading is set to false, data is not populated, then set the error
//   useEffect(() => {
//     if(!isLoading) setErrors(dashboardRawData ? false : true);
//   }, [dashboardRawData, isLoading])
  


//   useEffect(() => {
//     if (dashboardSupplierId && dashboardStoreId 
//     ) {
//       dashboardChart(timeFrame); // executes funct with updated value
//     }
//   }, [dashboardSupplierId, dashboardStoreId, timeFrame, calendarDate]); //removed docType , isCumulative, isGross, filter ( bc function is already triggered when supplID or storeID change, gross is handled downstream),        //da rimettere: 

//   useEffect(() => {
//     calculateChartData(dashboardRawData)
//   }, [isGross])


// //end from parent component












//   let part1 = 'TOTAL';
    //   let part2 = isGross ? 'GROSS' : 'NET';
    //   const valueField = `${part1}_${part2}`
  
    //   let intervalNumber;
    //   let difference = differenceInCalendarDays(datesRange.endDate, datesRange.startDate);
  
    //   // get days interval
    //   switch (filter.archive) {
    //       case 'this month':
    //           intervalNumber = getDaysInMonth(now); // Days in month
    //           break;
    //       case 'last month':
    //           intervalNumber = getDaysInMonth(subMonths(now, 1)); // remove 1 month from today, then get days in month
    //           break;
    //       case 'last 3 months':
    //           // intervalNumber = getDaysInMonth(now) + getDaysInMonth(subMonths(now, 1)) + getDaysInMonth(subMonths(now, 2)); // sum all days in last 3 months , if want to devide by weeks gotta rearange
    //           intervalNumber = differenceInCalendarWeeks(getDaysInMonth(now), getDaysInMonth(subMonths(now, 2))); // sum all days in last 3 months , if want to devide by weeks gotta rearange
    //           break;
    //       case 'select dates':
    //           intervalNumber = difference < 32 ? difference : differenceInCalendarWeeks(datesRange.endDate, datesRange.startDate)
    //           break;
    //       default:
    //           break;
    //   }
   
    //   const transformed = Array.from({ length: intervalNumber }, (_, index) => ({
    //   //   INTERVAL: timeFrame === 'day' ? index : index + 1, // For days, use index (0-23), else (1-31) or (1-12)
    //     INTERVAL: index + 1, // For days, use index (0-23), else (1-31) or (1-12)
    //     inserted: 0,
    //     waybill: 0,
    //     processing: 0,
    //     dispatched: 0,
    //     returned: 0,
    //     partReturned: 0,
    //   }));
  
    //   data.forEach(item => {
    //   //   const intervalIndex = item.INTERVAL - (timeFrame === 'day' ? 0 : 1); // Adjust for 1-based indexing in non-day timeframes
    //     const intervalIndex = item.INTERVAL; // Adjust for 1-based indexing in non-day timeframes
    //     if (transformed[intervalIndex]) {
    //       transformed[intervalIndex][item.STATUS] += item[valueField]; //total gross or net ora, nei miei orders ho solo price
    //     }
    //   });
    //   console.log('transformed', transformed);
      
    //   setChartData(transformed)


