import { Button } from 'devextreme-react/button';
import useTranslate from '../utils/useTranslate';

const ButtonsList = ({buttonsList}) => {
    const translate = useTranslate()

  return (
    buttonsList.map((button) => (
        <Button
          key={button.id}
          type={button.type}
          text={translate(button.text)}
          onClick={button.function}
          stylingMode={button.stylingMode || "contained"}
          className={button.className || ''}
          disabled={button.disabled || false}
          validationGroup={button.validationGroup}
          // className='bg-grey'
        />
    ))
  )
}

export default ButtonsList