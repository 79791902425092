import PropTypes from 'prop-types'; // ES6
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import logo from "../assets/images/logo-small.png"
// import Cookies from 'js-cookie';
// import useTranslate from '../utils/useTranslate';


function PageHeader({title, subtitle, toggleSidebar, backArrowVisible, burgerMenuRef, bell, info, store}) {
  // const translate = useTranslate();
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 786);

  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 786);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [window.innerWidth]);

  return (
    <>
    {/* <div id="page-header" className='d-flex align-items-center position-relative'> */}
    <div id="page-header" className='d-flex justify-content-between align-items-center px-3'>

        <div className={`p-2 rounded ${backArrowVisible? '' : 'd-none'}`} onClick={()=>navigate(-1
          // , {state: {isFromDetails: true}}
          )}>
          <i className={`fa fa-arrow-left ${backArrowVisible? '' : 'd-none'}`}></i>
        </div>

        {/* <div className='mx-3'>
          {/* <p className='small'>{`${user}${roleString ? `, ${roleString}` : ''}`}</p> 
          <p className='fs-09'>{`${user}`}</p>
          <p className='fs-09'>{`${roleString ? `${roleString}` : ''}`}</p>
          {/* <h2 className='nunito-ff text-white fs-4'>eversell</h2> 
            <img src={logo} alt="eversell logo"/>
        </div> */}


        <h1 className='fs-4'>
          {title}
          {(subtitle && !smallScreen) &&
            subtitle
          }
        </h1>

        {/* <h1 className='fs-4 m-0'>{title}</h1> */}

        <div className='d-flex align-items-center'>
          {bell && bell}
          {info && info}
          {store && store}
          {/* <button type="button" className="btn" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="bottom" data-bs-content="Bottom popover">
            <i className='fa-solid fa-bell fs-18'></i>
          </button> */}

          <div ref={burgerMenuRef} id='top-right' className='p-2 rounded' onClick={toggleSidebar}>
            <i className='fa fa-bars fs-14'></i>
          </div>
        </div>
    </div>
    </>
  )
}

export default PageHeader

PageHeader.propTypes = {
  title: PropTypes.string,
  toggleSidebar: PropTypes.func,
  backArrowVisible: PropTypes.bool
}


// const user = Cookies.get('company_name');
//converts role from cookie string into number

//no more needed, moved user to sidebar
// const role = parseInt(Cookies.get('role'), 10);
// const roleMap = {
//     0: 'admin',
//     1: 'admin',
//     300: 'store',
//     550: 'customer'
// };
// const roleString = translate(roleMap[role] || '');


// const animationFromDetail = {
//   initial: { x: '-100%', opacity: 0 },   
//   animate: { x: 0, opacity: 1 },      
//   exit: { x: '100%', opacity: 0 },   
//   transition: { duration: 0.3 }
// }

// const user = {
//   name: 'Carlo',
//   role: 'admin' //to do: pescare dai cookies e rendere la scritta condizionale, se 0 o 1 --> admin, se 300 --> user? commesso? punto vendita?
// }
