import { Popup } from 'devextreme-react/popup';
import { useCallback, useMemo } from 'react';
import useTranslate from '../utils/useTranslate';
import { Button } from 'devextreme-react/button';
import ConfirmationPopup from './ConfirmationPopup';

const ConfirmEditPopup = ({handleEdit, handleCancel, openItemId, isPopupVisible, hidePopup}) => {
    const translate = useTranslate()
    const popupTitle = useMemo(() => translate('Edit confirmation'), []);
    const popupButtons= useMemo(() => [
        {
            id: '1',
            type: 'normal', //"normal", "success", "default", "danger"
            text: 'Cancel',
            function: () => handleCancel(),
            stylingMode: 'contained', //default is "contained"
            className: 'bg-grey w-40',
        },
        {
            id: '2',
            type: 'default', //"normal", "success", "default", "danger"
            text: 'Edit',
            function: () => handleEdit(),
            stylingMode: 'contained', //default is "contained"
            // className: 'bg-grey',
            className: 'w-40'
        },
    ], [openItemId])
   
    return (
        <ConfirmationPopup
        popupTitle={popupTitle}
        popupMessage='Are you sure you want to edit this item?' 
        popupButtons={popupButtons}
        dependency={openItemId}
        isPopupVisible={isPopupVisible} 
        hidePopup={hidePopup}
    />
)
}

export default ConfirmEditPopup
// const renderContent = useCallback(() => {
//     return (
//     <div className="px-4 py-3">
//         <p>
//         {translate('Are you sure you want to delete this item from the current inventory?')}
//         </p>
//         <div className='d-flex justify-content-between mt-3'>

//         <Button
//         text={translate('Cancel')}
//         stylingMode="contained"
//         type="normal"
//         className='bg-grey'
//         //   onClick={hidePopup}
//         onClick={() => handleCancel()}
//         />
//         <Button
//         text={translate('Delete')}
//         stylingMode="contained"
//         type="danger"
//         onClick={() => handleDelete()}
//         />
//         </div>
//     </div>
//     );
// }, [openItemId]);


// <Popup
//     contentRender={renderContent}
//     visible={isPopupVisible}
//     hideOnOutsideClick={true}
//     onHiding={hidePopup}
//     showTitle={true}
//     title={popupTitle}
//     showCloseButton={true}
//     defaultWidth={290}
//     defaultHeight={'auto'}
//     // resizeEnabled={true}
// />     