import { registerSW } from 'virtual:pwa-register';
import ReactDOM from 'react-dom/client';
import config from 'devextreme/core/config'; 
import { licenseKey } from './devextreme-license';  
import Cookies from 'js-cookie'; 
import packageJson from '../package.json';
import { expireOptions } from './utils/constants/expireOptions.js';

import {
  RouterProvider,
} from "react-router-dom";
import { router } from './routes.jsx';

//commented on version 2.1.3
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.getRegistrations().then((registrations) => {
//     registrations.forEach((registration) => {
//       registration.unregister();
//     });
//   });
// }
// window.location.reload(true);


registerSW({immediate: true}); //ensures that any new app version will be loaded by all open tabs/windows as soon as it is available



// registerSW(); //claude 



/////////////////////attempt upd SW just on version update 20250324
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.getRegistration().then((registration) => {
//     if (!registration) {
//       registerSW({ immediate: true });
//     }
//   });
// }


//////old code but integrated in this new attempt
// const updateSW = registerSW({
//   onNeedRefresh() {
//     if (confirm('New content available. Reload?')) {
//       updateSW(true);
//     }
//   },
// });
///////////////////// end attempt upd SW just on version update 20250324


/////////////
// This works but deletes data --> save data in local storage, also dont i
//to periodically update service worker //todo: da testare prima dell'official release
if ("serviceWorker" in navigator) {
  registerSW({
  onRegistered(r) {
      r && setInterval(() => {
          console.log('Updating worker...');
          r.update();
      }, 1000 * 60 * 20) //in milliseconds , 20 mins
  }
});
}
//////////////


const newVersionMessage = Cookies.get('language') == 'it-IT' ? 'Nuova versione disponbile, l\'app si aggiornerà ora' : 'New version available, the app will now update'
//reload the app if the version is different from the current one
function checkAppVersionAndReload(){
  if((String(Cookies.get('app-version')) != String(packageJson.version)) || (String(localStorage.getItem('app-version')) != String(packageJson.version)) ){

    alert(newVersionMessage)    
    
    localStorage.setItem('app-version', packageJson.version)
    Cookies.set('app-version', packageJson.version, {expires: expireOptions });
    setTimeout(() => { // Delay reload to ensure the cookie is set
      window.location.reload(true);
    }, 500);
  }
}
// Call the function when the page loads
window.addEventListener('load', checkAppVersionAndReload);



// // Add offline status check
// window.addEventListener('online', () => {
//   document.body.classList.remove('offline');
// });

// window.addEventListener('offline', () => {
//   document.body.classList.add('offline');
// });




// const updateSW = registerSW({
//   immediate: true,  //ensures that any new app version will be loaded by all open tabs/windows as soon as it is available

//   //message that appears when new version of the app is available --> NOT
//   onNeedRefresh() {
//     const userConfirmed = confirm("A new version of the app is available. Would you like to reload?");

//     if (userConfirmed) {
//       updateSW(true); // Trigger an update and reload the app
//     }
//   },
// }); //ensures that any new app version will be loaded by all open tabs/windows as soon as it is available



//message that appears when new version of the app is available
// const updateSW = registerSW({
//   onNeedRefresh() {
//     const userConfirmed = confirm("A new version of the app is available. Would you like to reload?");

//     if (userConfirmed) {
//       updateSW(true); // Trigger an update and reload the app
//     }
//   },
//   // onOfflineReady() {
//   //   console.log("The app is ready to work offline.");
//   // }
// })




config({ 
  licenseKey,
  editorStylingMode: "outlined"
});   

ReactDOM.createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} />
)


///////////////////////////////////////////////////20241120
//pre upd to react router dom 6.4

// import { registerSW } from 'virtual:pwa-register';
// import ReactDOM from 'react-dom/client';
// import App from './App.jsx';
// // import './index.css';
// import config from 'devextreme/core/config'; 
// import { licenseKey } from './devextreme-license';  
// import { BrowserRouter } from 'react-router-dom';
// import { AuthProvider } from './contexts/LoginAuthProvider.jsx';
// import { ProductProvider } from './contexts/ProductProvider.jsx';
// import { LanguageProvider } from './contexts/LanguageProvider.jsx';
// import Cookies from 'js-cookie'; 
// import packageJson from '../package.json';
// import { expireOptions } from './utils/constants/expireOptions.js';


// registerSW({immediate: true}); //ensures that any new app version will be loaded by all open tabs/windows as soon as it is available
// // registerSW(); //claude 

// // if ('serviceWorker' in navigator) {
// //   navigator.serviceWorker.getRegistrations().then((registrations) => {
// //     registrations.forEach((registration) => {
// //       registration.unregister();
// //     });
// //   });
// // }
// // window.location.reload(true);

// // const updateSW = registerSW({
// //   onNeedRefresh() {
// //     if (confirm('New content available. Reload?')) {
// //       updateSW(true);
// //     }
// //   },
// // });


// /////////////
// //to periodically update service worker //todo: da testare prima dell'official release
// if ("serviceWorker" in navigator) {
//   registerSW({
//   onRegistered(r) {
//       r && setInterval(() => {
//           console.log('Updating worker...');
//           r.update();
//       }, 1000 * 60 * 20) //in milliseconds
//   }
// });
// }
// //////////////

// //reload the app if the version is different from the current one
// function checkAppVersionAndReload(){
//   if(Cookies.get('app-version') != packageJson.version){
//     Cookies.set('app-version', packageJson.version, {expires: expireOptions });
//     window.location.reload(true);
//   }
// }
// // Call the function when the page loads
// window.addEventListener('load', checkAppVersionAndReload);



// // // Add offline status check
// // window.addEventListener('online', () => {
// //   document.body.classList.remove('offline');
// // });

// // window.addEventListener('offline', () => {
// //   document.body.classList.add('offline');
// // });




// // const updateSW = registerSW({
// //   immediate: true,  //ensures that any new app version will be loaded by all open tabs/windows as soon as it is available

// //   //message that appears when new version of the app is available --> NOT
// //   onNeedRefresh() {
// //     const userConfirmed = confirm("A new version of the app is available. Would you like to reload?");

// //     if (userConfirmed) {
// //       updateSW(true); // Trigger an update and reload the app
// //     }
// //   },
// // }); //ensures that any new app version will be loaded by all open tabs/windows as soon as it is available



// //message that appears when new version of the app is available
// // const updateSW = registerSW({
// //   onNeedRefresh() {
// //     const userConfirmed = confirm("A new version of the app is available. Would you like to reload?");

// //     if (userConfirmed) {
// //       updateSW(true); // Trigger an update and reload the app
// //     }
// //   },
// //   // onOfflineReady() {
// //   //   console.log("The app is ready to work offline.");
// //   // }
// // })






// config({ 
//   licenseKey,
//   editorStylingMode: "outlined"
// });   


// ReactDOM.createRoot(document.getElementById('root')).render(
//   // <React.StrictMode>
//   <BrowserRouter>
//     <LanguageProvider>
//       <AuthProvider>  {/* this allows te whole app to access the authentication status from useContext */} 
//         <ProductProvider>  
//           <App />
//         </ProductProvider>
//       </AuthProvider>
//     </LanguageProvider>
//   </BrowserRouter>
//   // </React.StrictMode>
// )


/////////////////////////// attempt to move providers inside an outlet component, before finding out providers can be parents of BrowserProvider
// //move providers in a pathless route that is envelope to all other orutes and child of browserRouter
// export default function Providers() {
//   return (
//     <LanguageProvider>
//       <AuthProvider>  {/* this allows te whole app to access the authentication status from useContext */} 
//         <ProductProvider>  
//           <Outlet />
//         </ProductProvider>
//       </AuthProvider>
//     </LanguageProvider>
//   )
// }

// const router = createBrowserRouter(
//   createRoutesFromElements(
//     <Route element={<Providers />}>
//       <App/>
//     </Route>
//   )
// );




// ReactDOM.createRoot(document.getElementById('root')).render(

//   <RouterProvider router={router} />;

//   // // <React.StrictMode>
//   // <BrowserRouter>
//   // <Routes>
//   //   <Route element={<Providers />}>
//   //     <App/>
//   //   </Route>
//   // </Routes>
//   //   {/* <LanguageProvider>
//   //     <AuthProvider>  {/* this allows te whole app to access the authentication status from useContext 
//   //       <ProductProvider>  
//   //         <App />
//   //       </ProductProvider>
//   //     </AuthProvider>
//   //   </LanguageProvider> */}
//   // </BrowserRouter>
//   // // </React.StrictMode>
// )