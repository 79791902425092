import { useNavigate } from 'react-router-dom';
import SidebarHeader from '../components/SidebarHeader';
import useTranslate from '../utils/useTranslate';
import Item from '../api/ApiCallsProductLookup';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import notify from 'devextreme/ui/notify';
import Cookies from 'js-cookie';
// import HeaderInfo from '../components/HeaderInfo';
// import { ProductContext } from '../contexts/ProductProvider';
import ConfirmationPopup from '../components/ConfirmationPopup';
import Docs from '../api/ApiCallsDocs';
import {SelectBox} from 'devextreme-react/cjs/select-box';
import MainReprintLabels from '../components/MainReprintLabels';
import HeaderStore from '../components/HeaderStore';
import { ProductContext } from '../contexts/ProductProvider';
import { saveToLocalStorage, getLocalStorageSizeInMB } from '../utils/functions';
import HeaderInfo from '../components/HeaderInfo';
import QRbutton from '../components/QRbutton';
import ConfirmResumeListPopup from '../components/ConfirmResumeListPopup';


const ReprintLabels = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { getStores } = Docs()
  // const { getData } = Item(); // call to fetch items data
  // const firstLoad = useRef(true) // Track the first load
  const myStoreName = Cookies.get('store_name') ? Cookies.get('store_name') : ''; // TODO modify when we have the store name from get_BASE_VALUES
  const myStoreId = Cookies.get('id_store') ? Cookies.get('id_store') : ''; // TODO modify when we have the
  const role = Cookies.get('role')

  // const myStoreName = 'Milano'; // TODO modify when we have the store name from get_BASE_VALUES
  // const myStoreId = '545et4-6h6th4-tdhth7-wtm'; // TODO modify when we have the

  const [startFromLabelNumber, setStartFromLabelNumber] = useState(1)
  
  // const {stores, setStores} = useContext(ProductContext); //DO NOT USE GLOBAL, it has "all" as first
  const [localStores, setLocalStores] = useState()
  const [isChooseStorePopupVisible, setIsChooseStorePopupVisible] = useState(false);
  const [isChooseStoreExitPopupVisible, setIsChooseStoreExitPopupVisible] = useState(false)
  const [isResumeListPopupVisible, setIsResumeListPopupVisible] = useState(false)

  const {reprintLabelsStore, setReprintLabelsStore, reprintLabelsStoreId, setReprintLabelsStoreId, reprintLabelsSearchText, setReprintLabelsSearchText, reprintLabelsTypeSearch, setReprintLabelsTypeSearch, reprintLabelsPagination, setReprintLabelsPagination, updatedLabelsList, setUpdatedLabelsList, fetchStoresWithErpCode, storesWithErpCode, storeBarcode, seasons, fetchSeasons} = useContext(ProductContext) 

  // const [reprintLabelsStore, setReprintLabelsStore] = useState(null) //selected store, to pass down to children; meanwhile saved here, if need more global, move to provider
  // const [reprintLabelsStoreId, setReprintLabelsStoreId] = useState(null) //selected store, to pass down to children; meanwhile saved here, if need more global, move to provider

  // const [reprintLabelsSearchText, setReprintLabelsSearchText] = useState(null)
  // const [reprintLabelsTypeSearch, setReprintLabelsTypeSearch] = useState(null)
  // const [reprintLabelsPagination, setReprintLabelsPagination] = useState(null)

  
  useEffect(() => {
    if(role == 550){
      navigate("/customer");  
    } else {
      if(role == 0 || role == 1) {
        fetchStores();
        fetchStoresWithErpCode()
        if(seasons.length == 0) {
          fetchSeasons();
        }
        //probabilmente ci sono gia da productprovider, farli cercare li e se non ci sono fare la chiamata per popolare il dropdown
        if(!reprintLabelsStore) setIsChooseStoreExitPopupVisible(true); // on load, shows popup with exit btn
      }
    }
  }, []);
  
  //if im a store, the name and id are automatically assigned (from cookies) and not changable
  useEffect(() => {
    if(role == 300) {
      setReprintLabelsStore(myStoreName)
      setReprintLabelsStoreId(myStoreId)
    }
  }, [role, myStoreName, myStoreId])

  
  const pageTitle = `${translate('Reprint labels')}`
  
  //select a store popup
  const popupTitle = useMemo(()=>translate('Select a store'),[])
    //1st render
    const popupButtonsWithExit = useMemo(() => [
      {
          id: '1',
          type: 'danger', //"normal", "success", "default", "danger"
          text: 'exit',
          function: () => handleExit(),
          stylingMode: 'contained', //default is "contained"
          className: 'bg-grey w-40',
      },
      {
          id: '2',
          type: 'default', //"normal", "success", "default", "danger"
          text: translate('ok'),
          function: () => confirmAndClose(), //when clicked, saves value which populates the scritta negozio (setReprintLabelsStore), in remote const and cloces popup 
          stylingMode: 'contained', //default is "contained"
          className: 'w-40',
          disabled: reprintLabelsStoreId == null ? true : false
      },
  ], [reprintLabelsStoreId])

    
    //2nd render
    const popupButtons= useMemo(() => [
          // {
          //     id: '1',
          //     type: 'danger', //"normal", "success", "default", "danger"
          //     text: 'exit',
          //     function: () => handleExit(),
          //     stylingMode: 'contained', //default is "contained"
          //     className: 'bg-grey w-40',
          // },
          {
              id: '2',
              type: 'default', //"normal", "success", "default", "danger"
              text: translate('ok'),
              function: () => confirmAndClose(), //when clicked, saves value which populates the scritta negozio (setReprintLabelsStore), in remote const and cloces popup 
              stylingMode: 'contained', //default is "contained"
              className: 'w-40',
              disabled: reprintLabelsStoreId == null ? true : false
          },
    ], [reprintLabelsStoreId])

  
  const handleExit = useCallback(() => { // on dropdown value change, setReprintLabelsStore to the value + setPopupVisisbility = false
    setIsChooseStoreExitPopupVisible(false)
    navigate('product-lookup')
  }, []);

  const confirmAndClose = useCallback(() => { // on dropdown value change, setReprintLabelsStore to the value + setPopupVisisbility = false
    setIsChooseStorePopupVisible(false)
    setIsChooseStoreExitPopupVisible(false)
  }, []);

  // const handleHidePopup = useCallback(() => { // on dropdown value change, setReprintLabelsStore to the value + setPopupVisisbility = false
  //   setIsChooseStorePopupVisible(false)
  // }, []);


//popupResumeListTitle
  // const popupResumeListTitle = useMemo(()=>translate('Resume previous list?'),[])
  // const popupResumeListBody = useMemo(()=>translate('A previous list is available in the memory, do you want to continue it?'),[])
  // const popupResumeListButtons= useMemo(() => [
  //         {
  //             id: '1',
  //             type: 'normal', //"normal", "success", "default", "danger"
  //             text: translate('new list'),
  //             function: () => handleNewList(),
  //             stylingMode: 'contained', //default is "contained"
  //             className: 'bg-grey w-40',
  //         },
  //         {
  //             id: '2',
  //             type: 'default', //"normal", "success", "default", "danger"
  //             text: translate('continue list'),
  //             function: () => handleResumePreviousList(), //when clicked, saves value which populates the scritta negozio (setReprintLabelsStore), in remote const and cloces popup 
  //             stylingMode: 'contained', //default is "contained"
  //             className: 'w-40',
  //         },
  // ], [reprintLabelsStoreId])

  const handleNewList = useCallback(() => { //no need to reset local storage list bc it will be automatically overwritten
    setUpdatedLabelsList([])
    // localStorage.removeItem("labelsList") //case scenario in cui avere lista vecchia potrebbe dare fastidio: la persona naviga ripetutamente tra le pagine, cliccando ogni volta "nuova lista". RARO+
    setIsResumeListPopupVisible(false)
  }, []);

  const handleResumePreviousList = useCallback(() => { 
    let savedLists = JSON.parse(localStorage.getItem("labelsList")) //the useEffect checks if there is a savedList, so here can't be null
    setUpdatedLabelsList(savedLists);
    setIsResumeListPopupVisible(false)
  }, []);

  //volontariamente lasciato il popup negozio dopo la scelta cosi da essere ben consci di che negozio si è selezionato
  useEffect(()=>{ //al caricam controlla se mandare popup, dopo aver settato il negozio (ed ogni volta che il negozio cambia, è per far apparire i popup in sequenza anziche assieme)
    const savedListsExists = localStorage.getItem("labelsList") != null;
    if((!updatedLabelsList || updatedLabelsList.length < 1) && savedListsExists && isChooseStoreExitPopupVisible == false && reprintLabelsStoreId ){ //if there is no useState list but there is one in the local storage
      setIsResumeListPopupVisible(true)
    } 
  },[reprintLabelsStoreId, isChooseStoreExitPopupVisible]) //mi serve solo al caricmento, removed  updatedLabelsList


  // useEffect(()=>{
  //   localStorage.setItem("labelsList", JSON.stringify(updatedLabelsList));
  // },[updatedLabelsList])

  useEffect(() => {
    if(updatedLabelsList && updatedLabelsList.length > 0){
      saveToLocalStorage("labelsList", updatedLabelsList);
      getLocalStorageSizeInMB('labelsList')
    }
}, [updatedLabelsList]);



  //end  popupResumeListTitle
    
//shops are the same fetched in dash, hence we'll use the already present useState --> no bc that has "ALL"
//BUT gotta remove "all" in 1st place of array
const fetchStores = async () => {
  // setErrors(prevErr => ({...prevErr, shops: null}))
  try {
      // let data = await getShops(`{"$id":"1","FILTER":{"Sortings":[{"Field":"SEARCH_NAME","Ascending":true}]}}`);
      let { success: success, data: data, error: error } = await getStores(`{"$id":"1","FILTER":{"Sortings":[{"Field":"SEARCH_NAME","Ascending":true}]}}`);
      if(success){        

      data.shift() //trim "all" (1st store)
      setLocalStores(data); //global, in provider
      console.log(data);
      
      // } else {
      // setErrors(prevErr => ({...prevErr, stores: error}))
      }
  } catch (error) {
  notify(translate(error.message) || translate('Error'), 'error', 3000);
  } finally {
    console.log("stores post local fetch", localStores);
  }
}


//da gestire con  QR, semplicemente dopo che qr ha letto setta lui il valore di reprint label store (usando nome o id o both?) --> QRreader set reprintLabelsStoreFromQR && reprintLabelsStore
    const onValueChanged = useCallback((e) => {
      setReprintLabelsStoreId(e.value)
      setReprintLabelsStore(e.component.option("text"))
    }, []);   


  const renderPopupBody = useCallback(() => {
    return (
      //dropdown + scanner
      <>
      <div className='d-flex justify-content-between'>
            <SelectBox 
                // className='typesearch-dropdown'
                dataSource={localStores}
                displayExpr= 'SEARCH_NAME'
                valueExpr='ID'   
                defaultValue={reprintLabelsStoreId || null} 
                // searchEnabled={true}
                onValueChanged={onValueChanged}
                placeholder={translate('Select...')}
                acceptCustomValue={false}
                // editorOptions={storesEditorOptions}
                noDataText={translate('No store found')}
              />
              <QRbutton
                additionalClasses='ms-2 fs-18'
                source="reprint-labels" 
                // onClick={()=>fetchStoresWithErpCode()}
                // fieldType="search" //name not needed bc there is only 1 field to fill whi the read barcode in the whole page
              />
        </div>
      </>
    )
  },[localStores, reprintLabelsStore, reprintLabelsStoreId])

  //MOVED TO PROVIDER to avoid render eror message every time i navigate back from QRreader
  // useEffect(()=>{
  //   if(storesWithErpCode && storesWithErpCode.length >0 && storeBarcode){
  //     let selectedStore = storesWithErpCode.find((store) => store.CODE.replace(/\s/g,'') == storeBarcode.replace(/\s/g,'')) 
  //     // || {SEARCH_NAME: 'bilbo', ID: 'baggins'}
  //     // console.log('selectedStore', selectedStore);
  //     if(selectedStore){
  //       setReprintLabelsStore(selectedStore.SEARCH_NAME)
  //       setReprintLabelsStoreId(selectedStore.ID)
  //     } else {
  //       notify(translate('Store not found, select a valid store'), 'error', 3000)
  //     }
  //   }
  // },[storeBarcode])

     



  return (
    <>
      <SidebarHeader 
        title={pageTitle} 
        store={<HeaderStore store={reprintLabelsStore} setIsPopupVisible={setIsChooseStorePopupVisible} role={role} />}
        info={<HeaderInfo link={"/faq#reprint-labels"}/>}/>
      <div className={`page-container d-flex flex-column `}>
        <MainReprintLabels 
          store={reprintLabelsStore} 
          storeId={reprintLabelsStoreId}
          isChooseStorePopupVisible={isChooseStorePopupVisible} 
          setIsChooseStorePopupVisible={setIsChooseStorePopupVisible}
          startFromLabelNumber={startFromLabelNumber} 
          setStartFromLabelNumber={setStartFromLabelNumber}
          // reprintLabelsSearchText={reprintLabelsSearchText}
          // setReprintLabelsSearchText={setReprintLabelsSearchText}
          // // typeSearch, setTypeSearch 
          // pagination={pagination} 
          // setPagination={setPagination}
          />
      </div>
    {/* {localStores && */}
      {/* <ConfirmationPopup
        popupTitle={popupResumeListTitle} 
        popupMessage={popupResumeListBody}
        popupButtons={popupResumeListButtons}
        dependency={popupResumeListButtons} //allows the enable of btn when store is selected
        isPopupVisible={isResumeListPopupVisible}
        // hidePopup
        hideOnOutsideClick={false}
        // showTitle = {false}
        showCloseButton = {false} 
        // hidePopup={()=>handleHidePopup()}
      /> */}
      <ConfirmResumeListPopup 
        handleNewList={handleNewList}
        handleResumePreviousList={handleResumePreviousList}
        dependency={reprintLabelsStoreId}
        isPopupVisible={isResumeListPopupVisible}
        // hidePopup={hidePopup}
      />
    {/* }  */}

    {localStores && (role == 0 || role == 1) &&
      <ConfirmationPopup
        popupTitle={popupTitle} 
        popupMessage={renderPopupBody()}
        popupButtons={popupButtons}
        dependency={popupButtons} //allows the enable of btn when store is selected
        isPopupVisible={isChooseStorePopupVisible}
        // hidePopup
        hideOnOutsideClick={false}
        // showTitle = {false}
        showCloseButton = {false} 
        // hidePopup={()=>handleHidePopup()}
      />
    }    
    {localStores && (role == 0 || role == 1) && // && !reprintLabelsStore to avoid seing it twice, after confirm keep old list, removed bc closes popup after i select a store
      <ConfirmationPopup
        popupTitle={popupTitle} 
        popupMessage={renderPopupBody()}
        popupButtons={popupButtonsWithExit}
        dependency={popupButtonsWithExit} //allows the enable of btn when store is selected
        isPopupVisible={isChooseStoreExitPopupVisible}
        // hidePopup
        hideOnOutsideClick={false}
        // showTitle = {false}
        showCloseButton = {false} 
        // hidePopup={()=>handleHidePopup()}
      />
    }   

  </>
  );
};

export default ReprintLabels;
