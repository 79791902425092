// import { TextBox } from 'devextreme-react/text-box';
import { useContext } from 'react';
// import PropTypes from 'prop-types';


// import { useDispatch, useSelector } from "react-redux";
// import { setStateStockSearch } from "../store/stateSlice";
import { ProductContext } from '../contexts/ProductProvider';
// import useTranslate from '../utils/useTranslate';
import SearchTextbox from './SearchTextbox';

const dropdownOptions = {
    inputAttr:{
        autocomplete: 'new-password', //to avoid autofill
    }
}


const  LookupTextbox = ({searchText, setSearchText, setPagination, placeholder, onEnterKey}) => {
    // const {stockSearch, setStockSearch, setStockPagination} = useContext(ProductContext)

    const onValueChange = (value) => {
        console.log('search input', value);
        
        setSearchText(value);
        // setPagination(1)
    }; 

    return (
        <div className="product-lookup-textbox me-2">
            <SearchTextbox 
                value={searchText} 
                editorOptions={dropdownOptions} 
                onValueChange={onValueChange}
                placeholder={placeholder}
                onEnterKey={onEnterKey}
            />
        </div>
    );
}
 
export default LookupTextbox;

// ProductLookupTextbox.propTypes = {
//     onSearchChanged: PropTypes.func
// }