//this component takes care of login, logout and authentication status 
//API calls are in API folder
// this file defines a context using React's Context API, which is used to manage global state or shared values that can be accessed by any component in your component tree without needing to pass props down manually at every level

//loginform calls the context/provider which in turns calls the api 

//todo: capire come fare il merge delle funzioni tra qui e "app" VEDI PRIMA RIGA
//todo: rendere omogenei nomi sign in, login, logout etc
//todo: scegliere la via che si preferisce tra user e cookies e organizzare il codice di conseguenza

//converted from tsx to jsx
import { useState, createContext, useCallback, useContext } from 'react';
import ApiCalls from '../api/ApiCalls'; 
import Cookies from 'js-cookie';
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';
import useApi from '../api/useApi';
import config from '../utils/constants/config'
import notify from 'devextreme/ui/notify';
import useTranslate from '../utils/useTranslate';


const AuthContext = createContext();  //todo: da passare la prop { loading: false } ?

const AuthProvider = ({children}) => {
  const translate = useTranslate()
  
  // const [isAuthenticated, setIsAuthenticated] = useState(checkCookies());  //the state will refresh when component refreshes, not available in the whole app in a stable way
  const [isAuthenticated, setIsAuthenticated] = useState(false);  //the state will refresh when component refreshes, not available in the whole app in a stable way
  const [errors, setErrors] = useState({
    account: null,
    accessLevelResponse: null,
    baseValuesResponse: null,
    urlResponse: null,
  })

  // const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState(() => {
    // Inizializza lo stato con i dati dai cookie se disponibili
    const token = Cookies.get('token');
    const role = Cookies.get('role');
    const id_store = Cookies.get('id_store');
    const company_name = Cookies.get('company_name');
    const url = Cookies.get('url');
    const company_login = Cookies.get('company_login');

    if (token && role && id_store && company_name && url) {
      return {
        token,
        role,
        id_store,
        company_name,
        url,
      };
    }
    return null;
  });
  
  // const [token, setToken] = useState(localStorage.getItem("site") || "");
  const navigate = useNavigate();
  const { getAccessLevel, getBaseValues, getUrl} = ApiCalls();
  const { callApi } = useApi();

  //  calls the login function in API, saves the results in Cookies (todo: check if this is the best way)
  const login = useCallback(async(formData) => {
    setErrors({
      account: null,
      accessLevelResponse: null,
      baseValuesResponse: null,
      urlResponse: null,
    })

    // const result = await sendLoginRequest(formData); //login function in API authApiCalls
    try {
      const { success, data, error } = await callApi('POST', `/Account/${config.language}/Login`, formData);  // <-- Modificato
      // console.log('IS OK', result);//todo: remove this after fix 
      // console.log('login result da provider', result.data);//todo: remove this after fix 
      // console.log('success:', success, 'data:', data, 'error:', error);
      
      if(success) {
        if(data.HAS_ERROR){
          navigate('/')
          notify(translate('Authentication failed'), 'error', 2000)
        } else 
        if (!data.HAS_ERROR) {
        // setUser(result.data);  //todo: check if needed
        // console.log('user logged in', user); //todo: remove this after fix 
        // setIsAuthenticated(true);
  
        //expires in 150 years
        const expireDate = formData.isPersistent ? new Date(Date.now() + 365 * 150 * 24 * 60 * 60 * 1000) : undefined; //or 0?
        
        //set cookies params with result value
        Cookies.set('token', data.SCALAR, { expires: expireDate });
        const token = Cookies.get('token');

        Cookies.set('username-toshow', formData.UserName, { expires: expireDate })
        // Cookies.set('company_login', formData.Customer.trimEnd().replace(/ /g,"_"), {expires: expireDate}); //what user types in the field company in login form
        Cookies.set('company_login', formData.Customer.trimEnd().replace(/\s+/g,"_"), {expires: expireDate}); //what user types in the field company in login form        

        Cookies.set('currency', '€', { expires: expireDate }); //todo: replace when available from API
        Cookies.set('language', 'it-IT', { expires: expireDate }); //todo: replace when available from API
        // Cookies.set('role', '0', { expires: expireDate }); //todo: replace when available from API
  

        //get access level after login
        const { success: successAccessLevelResponse, data: accessLevelResponse, error: errorAccessLevelResponse}  = await getAccessLevel(formData) 
        // const accessLevelResponse = await getAccessLevel(formData)
        if(successAccessLevelResponse){
        Cookies.set('role', accessLevelResponse.SCALAR, { expires: expireDate });
        } else {
          // console.log('accessLevelResponse', errorAccessLevelResponse);
          setErrors(prevErr => ({...prevErr, accessLevelResponse: errorAccessLevelResponse})) // or SetErrors(true ) e basta?
        }

        //get base values after login
        const { success: successBaseValuesResponse, data: baseValuesResponse, error: errorBaseValuesResponse} = await getBaseValues(formData)
        // const baseValuesResponse = await getBaseValues(formData)
        if (successBaseValuesResponse) {
        Cookies.set('id_store', baseValuesResponse.ROW.ID_STORE, { expires: expireDate });
        Cookies.set('company_name', baseValuesResponse.ROW.COMPANY_NAME, { expires: expireDate });
        } else {
          // console.log('baseValuesResponse', errorBaseValuesResponse);
          setErrors(prevErr => ({...prevErr, baseValuesResponse: errorBaseValuesResponse})) // or SetErrors(true ) e basta?
        }


        //get url after login //todo: understand if necessary
        const { success: successUrlResponse, data: urlResponse, error: errorUrlResponse} = await getUrl(formData)
        let secureUrl = null
        // const urlResponse = await getUrl(formData)
        if(successUrlResponse){
        secureUrl = urlResponse.SCALAR.replace('htttp://', 'https://');
        Cookies.set('url', secureUrl, { expires: expireDate });
        } else {
          // console.log('urlResponse', errorUrlResponse);
          setErrors(prevErr => ({...prevErr, urlResponse: errorUrlResponse})) // or SetErrors(true ) e basta?
        }

        // todo: check if it is necessary to have user and token saved in the sstate, since we already have them in the cookies
  
        setUser({
          token,
          role: accessLevelResponse.SCALAR,
          id_store: baseValuesResponse.ROW.ID_STORE,
          company_name: baseValuesResponse.ROW.COMPANY_NAME,
          url: secureUrl,
        });
        // setToken(token)
        // localStorage.setItem('site', secureUrl);
  
        // console.log('user logged in', user); //todo: remove this after fix 
        setIsAuthenticated(true)

        if( errors.accessLevelResponse || errors.baseValuesResponse|| errors.urlResponse ){
          return { success: false, error: 'Authentication failed' }
        } else {
          return { success: true, data: data };
        }
      }
      // return { isOk: true, data: data };  // <-- Modificato
      
      // return result;  // this is passed to login form, which only checks if result.isOf is true
    } else {
      setErrors(prevErr => ({...prevErr, account: error }));
      return { success: false, error: 'Authentication failed' };

      // return { isOk: false, message: error };  // <-- Modificato
      // // return result;  // this is passed to login form, which only checks if result.isOf is true
    }
    } catch (error) {
      console.error('Error in sendLoginRequest:', error); // Log dell'errore
      return { success: false, error: 'Authentication failed' };
    }
  }, [callApi, getAccessLevel, getBaseValues, getUrl]);



  //logout function, defined here, sets the user state to undefined
  const logout = useCallback(() => {
    //todo: check if it is clean to put cookies here 
    Cookies.remove('token');
    Cookies.remove('url');
    Cookies.remove('username-toshow');
    // Cookies.remove('currency');
    // Cookies.remove('language');
    Cookies.remove('role');
    Cookies.remove('id_store');
    Cookies.remove('company_name');
    Cookies.remove('stock-season'); //removes filter season if you log out from one account and log in in another , was not present in old code
    Cookies.remove('store_name');

    Cookies.remove('company_login'); //what user types in the field company in login form


    sessionStorage.removeItem('calendarDate');
    sessionStorage.removeItem('timeFrame');

    setUser(null);  //todo: or undefined?
    setIsAuthenticated(false)

    // setToken('');
    // localStorage.removeItem('site');
    navigate('/');
    // setIsAuthenticated(false);
    // console.log('cookies',
    // Cookies.get('token'),
    // Cookies.get('url'),
    // Cookies.get('currency'),
    // Cookies.get('language'),
    // Cookies.get('role'),
    // Cookies.get('id_store'),
    // Cookies.get('company_name'),
    // Cookies.get('stock-season'),
    //  )

  }, [navigate]);




  //this is a React.createContext that allows to share global data (contained in value) in the components tree
  //any AuthContext.provider child component (app child) can access these values using useContext(authContext)
  return (
    <AuthContext.Provider 
      value={{ user, isAuthenticated, login, logout }} 
      // value={{ user, isAuthenticated, login, logout, loading }} 
      // todo: check if i need isAuth and loading
       >
      {children}
    </AuthContext.Provider>
    // The AuthContext.Provider makes the authentication state and related functions available to its child components, accessible via the useAuth hook, enabling components to consume authentication data and actions within the application.
  );
};

// export default AuthProvider

// AuthProvider.propTypes ={
//   children: PropTypes.node.isRequired,  //a component that will be rendered when the AuthProvider is used
// }

// // moved to useAuth.js file since jsx doesnt allow multiple exports 
// export const useAuth = () => {
//   return useContext(AuthContext);
// };

// // old code. not working for preious reason
// // export { AuthProvider, useAuth };

export { AuthContext, AuthProvider}





//   //fetch user data when the component mounts, stores them in the AuthProvider
//   useEffect(() => {
//     (async function() {
//       const result = await getUser();
//       if (result.isOk) {
//         setUser(result.data);
//         setIsAuthenticated(true);
//       }

//       setLoading(false);
//     })();
//   }, []);

//   //todo: in alternative to the above: check cookies directly, instead of "user", check what angular did
//   //todo: i'd put this in an use effect
//   const checkCookies = () => {
//     const token = Cookies.get('token');
//     const url = Cookies.get('url');
//     const currency = Cookies.get('currency');
//     const role = Cookies.get('role');
//     const id_store = Cookies.get('id_store');

//     if (!token || !url || !currency || !role || !id_store) {
//         logout();
//         return false;
//     }
//     return true;
// }

//   //calls the login function 
//   const login = useCallback(async(company, username, password) => {
//     const result = await sendLoginRequest(company, username, password); //login function in API authApiCalls
//     if (result.isOk) {
//       setUser(result.data);
//       setIsAuthenticated(true);
//     }

//     return result;
//   }, []);

//   //logout function, defined here, sets the user state to undefined
//   const logout = useCallback(() => {
//     //todo: check if it is clean to put cookies here 
//     Cookies.remove('token');
//     Cookies.remove('url');
//     Cookies.remove('currency');
//     Cookies.remove('role');
//     Cookies.remove('id_store');

//     setUser(undefined);
//     setIsAuthenticated(false);

//   }, []);



 // versione try catch
        // //get access level after login
        // try {
        //   // const accessLevelResponse = await getAccessLevel(formData) 
        //   const accessLevelResponse = await callApi('POST', `/Config/${config.language}/Get_ACCESS_LEVEL`, formData);  // <-- Modificato
        //   Cookies.set('role', accessLevelResponse.SCALAR, { expires: expireDate });

        //   //get base values after login
        //   try {
        //     const baseValuesResponse = await callApi('POST', `/Config/${config.language}/Get_BASE_VALUES`, formData);  // <-- Modificato
        //     // const baseValuesResponse = await getBaseValues(formData)
        //     Cookies.set('id_store', baseValuesResponse.ROW.ID_STORE, { expires: expireDate });
        //     Cookies.set('company_name', baseValuesResponse.ROW.COMPANY_NAME, { expires: expireDate });
        //     // return { isOk: true, data: responseData };  // <-- Modificato

        //     //get url after login //todo: understand if necessary
        //     try {
        //       const urlResponse = await callApi('POST', `/Config/${config.language}/Get_URL`, formData);  // <-- Modificato
        //       // return { isOk: true, data: responseData };  // <-- Modificato
        //       // const urlResponse = await getUrl(formData)
        //       const secureUrl = urlResponse.SCALAR.replace('htttp://', 'https://');
        //       Cookies.set('url', secureUrl, { expires: expireDate });
        //     } catch (error) {
        //       console.error('Error in getUrl:', error); // Log dell'errore
        //       return { isOk: false, message: 'Authentication failed' };  // <-- Modificato
        //     }
        //   } catch (error) {
        //     console.error('Error in getBaseValues:', error); // Log dell'errore
        //     return { isOk: false, message: 'Authentication failed' };  // <-- Modificato
        //   }
        //   // return { isOk: true, data: accessLevelResponse };  // <-- Modificato
        // } catch (error) {
        //   console.error('Error in getAccessLevel:', error); // Log dell'errore
        //   return { isOk: false, message: 'Authentication failed' };  // <-- Modificato
        // }
  



