import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ArgumentAxis, Chart, CommonSeriesSettings, Legend, Series, Tooltip} from 'devextreme-react/chart';
import Cookies from 'js-cookie';
import useTranslate from '../../utils/useTranslate';
import { ProductContext } from '../../contexts/ProductProvider';
import Stock from '../../api/ApiCallsStock';
import notify from 'devextreme/ui/notify';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { getDaysInMonth } from 'date-fns';

const SalesDashboardChart = ({ 
  // setLoadingChart,
  // timeFrame,
  filter,
  formatDateToYMD
  // docType, 
  // chartData, 
  // isCumulative, 
  // handleCumulativeChange
  // chartCommonSeriesSettings, 
}) => {
  const {calendarDate, isCumulative, dashboardStoreId, dashboardSupplierId, isGross, timeFrame, dashboardRawData, setDashboardRawData} = useContext(ProductContext);
  // const {formatDateToYMD} = useDateHandling()

  const translate = useTranslate();
  const currency = Cookies.get('currency')

  const { getDashboard4 } = Stock() // removed getDashboard2,
  const abortControllerRefDashChart = useRef(null);
  const [errors, setErrors] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(false)
  const [chartData, setChartData] = useState()  //datasource for chart
  const [activeRequestCount, setActiveRequestCount] = useState(0); 
  const [isLoading, setLoading] = useState(true)


  // const showSeries = docType === 'instore' || docType === 'online' ? false : true  
  
          //FUTURE DEV: adjust cumulative function
          let chartCommonSeriesSettings = isCumulative ? 'area' : 'stackedbar'
          // const createCumulativeArray = (data) => {
          //   if (!isCumulative) {
              
          //     return data; // Return the original array if isCumulative is false
          //   }
          
          //   // Initialize accumulators for INSTORE and ONLINE
          //   let instoreAccumulator = 0;
          //   let onlineAccumulator = 0;
          
          //   // Map through the data to create a new cumulative array
          //   const cumulativeData = data.map((item) => {
          //     // Add the current values to the accumulators
          //     instoreAccumulator += item.INSTORE;
          //     onlineAccumulator += item.ONLINE;
          
          //     // Return a new object with the updated cumulative values
          //     return {
          //       INTERVAL: item.INTERVAL,
          //       INSTORE: instoreAccumulator,
          //       ONLINE: onlineAccumulator,
          //     };
          //   });
          
          //   return cumulativeData;
          // };

          // const chartDataProcessed = createCumulativeArray(chartData)



//MOVED FROM PARENT COMPONENT mainSalesDashboard

  // populate the chart based on isCumulative and isGross
  const calculateChartData = useCallback((data) => {
    // setErrorMessage(false)
    // console.log(data, 'chart dataError');

    // if (data.Message) {
    //   setErrorMessage(true)
    // } else {    // let part1 = isCumulative ? 'RUNNING' : 'TOTAL';
    let part1 = 'TOTAL';
    let part2 = isGross ? 'GROSS' : 'NET';
    // let part3 = isCumulative ? 'area' : 'stackedbar' //moved to the child component, not needed here
    const valueField = `${part1}_${part2}`
    // const selectedMonth = new Date(calendarDate).getMonth() +1        
    // const selectedYear = new Date(calendarDate).getFullYear()

    //process data so that each obj has the fields interval, instore, online and is correctly populated
    // const transformed = [];

    let intervalNumber;
    if (timeFrame == 'day') {
      intervalNumber = 24;
    } else if (timeFrame == 'month') {
      // intervalNumber = new Date(selectedYear, selectedMonth, 0).getDate(); // Days in month
      intervalNumber = getDaysInMonth(calendarDate); // Days in month
    } else if (timeFrame == 'year') {
      intervalNumber = 12;
    }

    const transformed = Array.from({ length: intervalNumber }, (_, index) => ({
      INTERVAL: timeFrame === 'day' ? index : index + 1, // For days, use index (0-23), else (1-31) or (1-12)
      INSTORE: 0,
      ONLINE: 0
    }));

    data.forEach(item => {
      const intervalIndex = item.INTERVAL - (timeFrame === 'day' ? 0 : 1); // Adjust for 1-based indexing in non-day timeframes
      if (transformed[intervalIndex]) {
        transformed[intervalIndex][item.DOCTYPE] = item[valueField];
      }
    });
    // console.log('transformed', transformed);
    
    setChartData(transformed)
  // }
  }, [calendarDate, isGross, timeFrame])



  const dashboardChart = useCallback(async (period) => {
    setActiveRequestCount(prevCount => prevCount + 1); //keep trak of aborted calls to set loader properly
    setLoading(true)
    setErrors(false)

    const optionalDate = calendarDate ? formatDateToYMD(calendarDate) : ''
    // const optionalDate = calendarDate || ''
    // const optionalDatePast = calculateDatePast(optionalDate)
    // const optionalDatePastLTD = isCurrentPeriod ? calculateDatePastLTD(optionalDate) : ''
    // setLastDate(optionalDatePast)

    try {
      if (abortControllerRefDashChart.current) {
        abortControllerRefDashChart.current.abort();
      }
      abortControllerRefDashChart.current = new AbortController();
      const signal = abortControllerRefDashChart.current.signal;

      const docType = 'DIVIDED'
      
      let { success: successDivided, data: dataDivided, error: errorDivided } = await getDashboard4(`{"PERIOD":"${period}","ID_STORE":"${dashboardStoreId}","ID_SUPPLIER":"${dashboardSupplierId}","ID_SALE_CHANNEL":"00000000-0000-0000-0000-000000000000","DOCTYPE":"${docType}","TO_DATE":"${optionalDate}","PARTITION_BY":"PERIOD"}`,signal)
      // LUCA: nuovo parametro opzionale TO_DATE che permette di personalizzare la data di riferimento.
      // Per visualizzare correttamente i dati di un mese/anno passato andrebbe settato sull'ultimo giorno di quel periodo (es. 31/12 o 31/07, 30/05, ...)    

      //DOCTYPE     ("ALL" tutti i dati assieme,"DIVIDED" tutti i dati divisi ,"INSTORE","ONLINE") 
      //PARTITION_BY  ("" tutti i dati assieme,"PERIOD" divisi per frazione del periodo ,"STORE","SALE_CHANNEL")     
      // if(successDivided){
      setDashboardRawData(dataDivided)
      calculateChartData(dataDivided);
      // } else {
      //   // setErrors(errorDivided?.message || 'Failed to fetch chart data');
      //   setErrors(true);
      // }
    } catch (error) {
      if (!error.name === 'CanceledError') {  //if api call not aborted 
        notify(translate(error.message) || translate('Error'), 'error', 3000);
        // setErrors(true)
      }
    } finally {
      setActiveRequestCount(prevCount => prevCount - 1);
      // setErrors(dashboardRawData ? false : true);
    }
  },[calendarDate, timeFrame, dashboardStoreId, dashboardSupplierId]); //removed getDashboard2, setDashboardRawData

  useEffect(() => {
    setLoading(activeRequestCount > 0);
  }, [activeRequestCount])

  // if after loading is set to false, data is not populated, then set the error
  useEffect(() => {
    if(!isLoading) setErrors(dashboardRawData ? false : true);
  }, [dashboardRawData, isLoading])
  


  useEffect(() => {
    if (dashboardSupplierId && dashboardStoreId 
    ) {
      dashboardChart(timeFrame); // executes funct with updated value
    }
  }, [dashboardSupplierId, dashboardStoreId, timeFrame, calendarDate]); //removed docType , isCumulative, isGross, filter ( bc function is already triggered when supplID or storeID change, gross is handled downstream),        //da rimettere: 

  useEffect(() => {
    calculateChartData(dashboardRawData)
  }, [isGross])


//end from parent component

  


const customizeTooltip = (pointInfo) => ({
  // text: `${currency} ${parseInt(pointInfo.valueText)}`;
  text: `${currency} ${Intl.NumberFormat('it-IT').format(parseInt(pointInfo.valueText))}`
  
});

const legendClickHandler = (e) => {
  const series = e.target;
  if (series.isVisible()) {
      series.hide();
  } else {
      series.show();
  }
}



  return (
    isLoading ? (
      <LoadIndicator
        visible={isLoading} 
      />
  ) : (
    <div id="sales-chart">
        {/* option 1 chart */}
      {errors ? (
        <p className='pt-2'>
          <i className='dx-icon-pulldown big-number text-end'></i>
        </p>
      ) : (    
    <Chart
      id="dashboard-chart"
      dataSource={chartData}
      // series={chartSeries}
    //   tooltip: {
    //     enabled: true,
    //     customizeTooltip: function (arg) {
    //         return {
    //             text: $scope.currency+' '+parseInt(arg.valueText)
    //         };
    //     }
    // },
      // width={chartWidth}
      width={'100%'}
      height={250}
      onLegendClick={e => legendClickHandler(e)}
    >
      <Tooltip
        enabled={true}
        customizeTooltip={customizeTooltip}
       />
       <ArgumentAxis 
        argumentType='string'
      />
      {/* <ValueAxis 
        label={customizeText}
        tickInterval={1000}
        //todo capire some settare il valore della griglia
        // grid={
        //   color: '#eee'
        // }
      /> */}
      <CommonSeriesSettings
        argumentField='INTERVAL'
        // type={chartCommonSeriesSettings}
        type='stackedbar'
      />
      <Series
        valueField="INSTORE"
        color="#5793bb"
        name={translate('instore')}
        // todo: render condizionale del tipo di grafico a se conda che nelle opzioni sia selezionato o meno cumulative
      />
      <Series
        valueField="ONLINE"
        color="#3cbab2"
        // visible={showSeries}
        name={translate('online')}
        //allows to hide a series when instore or online filters are selected
      />
      <Legend 
        // position="outside"
        // horizontalAlignment="right"
        horizontalAlignment="center"
        verticalAlignment="top"
        itemTextPosition="right"
      />
    </Chart>
    )}
    </div>
  )
  )
}

export default SalesDashboardChart



//removed 20240924
{/* option 2 chart, commented beacuse differential rendder of the first graph (above)   */}
    {/* <Chart
        dataSource={dataSource}
    >
        <Series
            valueField="oranges"
            argumentField="day"
            color="#ABC9DD"
                  type='area'


        />
                
        <Margin bottom={20} />
        <ArgumentAxis valueMarginsEnabled={false} />
        <Legend
            visible={false}
        //   verticalAlignment="bottom"
        //   horizontalAlignment="center"
        />
        <Export enabled={true}/>
    </Chart> */}


      
      {/* <div className="options">
        <div className="caption">Options</div>
        <div className="option">
          <span>Series Type </span>
          <SelectBox
            dataSource={types}
            value='area'
            inputAttr={seriesTypeLabel}
          />
        </div>
      </div> */}

      

    // useEffect(() => {
    //     onToolbarPreparing(e) {
    //         e.toolbarOptions.items.push({
    //             widget: 'dxButton',
    //             name: "myexport",
    //             options: {
    //                 icon: "export",
                    
    //             }
    //         });
    // }
    // })


// const chartWidth = screen.width < 480 ? '300px' : '400px';
//todo check use screen width


// SalesDashboardChart.propTypes = {
//   // timeFrame: PropTypes.string.isRequired, //todo: set isRequired once we have it from backend
//   // docType: PropTypes.string.isRequired,
//   isCumulative: PropTypes.bool, 
// }

// const customizeText = (value) => ({
//   text: `${currency} ${value}K`  //todo  //return $scope.currency+' '+(this.value/1000)+'K'
// })



//old code
// function (arg) {
  //         return {
  //             text: $scope.currency+' '+parseInt(arg.valueText)
  //         };