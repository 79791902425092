import {Button} from 'devextreme-react/button';
import { useCallback, useState } from 'react';
import useTranslate from '../utils/useTranslate';
import { Popover } from 'devextreme-react/popover';


const HeaderStore = ({store, setIsPopupVisible, role}) => {
    const openPopup = () => {
        if(role == 0 || role == 1)
        setIsPopupVisible(true)
    }

  return (
    <div className="notification-bell-container">
        <div className="notification-bell" onClick={openPopup} id='notification-bell'>
        {/* <button className='p-0 border-0 bg-light-grey'> */}
            <i id='notification-bell-target' className={`fa-solid fa-store fs-18 ${(role == 0 || role == 1) ? 'selected' : 'light-grey'}`}></i>
            <p className={`text-uppercase fs-12 m-0 ${(role == 0 || role == 1) ? 'selected' : 'light-grey'}`}>{store}</p>
        {/* </button> */}
        </div>
    </div>  
    )
}

export default HeaderStore