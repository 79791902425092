import { DropDownButton } from 'devextreme-react/drop-down-button';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { Popover } from 'devextreme-react/popover';
import DxSwitch from './DxSwitch'
import { useContext } from 'react';
import { ProductContext } from '../contexts/ProductProvider';
import Cookies from 'js-cookie';
import { expireOptions } from '../utils/constants/expireOptions';
import { AnimatePresence, motion } from 'framer-motion';


const animationConfig = {
    show: {
      type: 'pop',
      from: {
        scale: 0,
      },
      to: {
        scale: 1,
      },
    },
    hide: {
      type: 'fade',
      from: 1,
      to: 0,
    },
  };

const animationContent = {
  initial: { height: 0, opacity: 0 },
  animate: { height: 'auto', opacity: 1 },
  exit: { height: 0, opacity: 0 },
  transition: {
    duration: 0.3,
    ease: 'easeInOut'
  },
}


const CardBig = (
    {
        children,
        title,
        menuItems,
        contentClass,
        isLoading = false,
        additionalHeaderContent,
        menuVisible = false,
        width,
        heightCustom,
        maxHeight,
        scroll = 'auto',
        // isCumulative,
        // handleCumulativeChange,
        optionsVisible,
        optionsText,
        info, //bool,
        infoText, //string
        infoPopID, 
        marginTitle,
        marginTopContent,
        paddingBottomContent,
        noMarginAround, 
        isAnimated = false,
        isAccordionExpanded,
        onHeaderClick
      }
) => {

  const { isCumulative, setIsCumulative} = useContext(ProductContext)

      //ex cumulativeChange
  const handleCumulativeChange = (e) => {
    setIsCumulative(e.value);
    Cookies.set('dashboard-is_cumulative', e.value, { expires: expireOptions });
    // dashboard(tab);
  };

  // Create header click handler that only fires if onHeaderClick is provided
  const headerClickHandler = onHeaderClick ? {
    onClick: onHeaderClick,
    style: { cursor: 'pointer' }
  } : {};


  return (
    <div 
        className={`card ${contentClass} ${noMarginAround ? 'm-1 mt-2' : 'm-2 mb-3'}`}
        style={{width: width}}
    >
        
        <div className={`header ${optionsVisible || additionalHeaderContent ? 'd-flex justify-content-between align-items-baseline' : ''}`}
        {...headerClickHandler}
        >
        {menuVisible && (
            <DropDownButton
                className='overflow-menu'
                items={menuItems}
                visible={menuVisible}
                icon='overflow'
                stylingMode='text'
                showArrowIcon={false}
                dropDownOptions={{ width: 'auto' }}
            />
        )}
        {title && <div className='title-card' style={{marginBottom: marginTitle}}>
            {title}
            {info && 
            <>
            <span id={infoPopID}><i className="fa-solid fa-circle-info ms-1 light-grey"></i></span>
            <Popover
            target={`#${infoPopID}`}
            showEvent='click' //"mouseenter"
            // hideEvent='click' //"mouseleave"
            position="top"
            width={300}
            animation={animationConfig}
            >
            <div className='p-3'>
            {infoText}
            </div>
            </Popover>
          </>
          }
        </div>}
        {optionsVisible && (
            <div className='d-flex align-items-center'>            
            <span className='me-1'>{optionsText}</span>
            <DxSwitch 
                value={isCumulative}
                onValueChanged={handleCumulativeChange}
            />
            </div>
        )
        }
        {additionalHeaderContent}
        </div>
        {/* {!isLoading && <div 
            className='card-contents' 
            // style={`width: ${width}, height: ${height},`}
            style={{width: width, height: heightCustom}}
            >
            {children}
        </div>} */}
        {isLoading ? (
        <div 
            className='card-contents' 
            // style={`width: ${width}, height: ${height},`}
            style={{width: width, height: heightCustom, maxHeight: maxHeight, overflow: scroll, marginTop: marginTopContent, paddingBottom: paddingBottomContent}}
            >
            <LoadIndicator/>
        </div>

        ) : (
          isAnimated? (
            <AnimatePresence>
              {isAccordionExpanded && (
                <motion.div 
                  className='card-contents' 
                  // style={`width: ${width}, height: ${height},`}
                  style={{width: width, height: heightCustom, maxHeight: maxHeight, overflow: scroll, marginTop: marginTopContent, paddingBottom: paddingBottomContent}}

                  initial={animationContent.initial}
                  animate={animationContent.animate}
                  exit={animationContent.exit}
                  transition={animationContent.transition}
                  >
                    {children}
                </motion.div>
              )}
            </AnimatePresence>

          ) : (
            <div 
            className='card-contents' 
            // style={`width: ${width}, height: ${height},`}
            style={{width: width, height: heightCustom, maxHeight: maxHeight, overflow: scroll, marginTop: marginTopContent, paddingBottom: paddingBottomContent}}
            >
            {children}
        </div>
          )
        ) 
        }
    </div>  
    )
}

export default CardBig