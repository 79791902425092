

//ex file Item,  
//these functions get list of seasons, brands, ...

import config from '../utils/constants/config';
import useApi from './useApi';
import Helper from './Helper';

const Item = () => {
  const { callApi } = useApi()
  const { removeBadValue } = Helper()
 
  async function getGroup (params) {
    const { success, data, error } = await callApi('POST', `/ITEM/${config.language}/Get_All_qry_tbl_ITEM_GROUP_VALUES`, params); 
    if(success) {
      return { success: true, data: removeBadValue(data.ELENCO, 'DESCRIPTION', 'all') };
    } else {
      return { success: false, error: error };    
    }
  }
  
  async function getData (params, signal) {
    const { success, data, error } = await callApi('POST', `/ITEM/${config.language}/Find_ITEMS_WEB_page`, params, signal); 
    if(success) {
      return { success: true, data: removeBadValue(data.ELENCO, null, 'empty')};
    } else {
      return { success: false, error: error };    
    }
    // try {
      // const { success, data, error } = await callApi('POST', `/ITEM/${config.language}/Find_ITEMS_WEB_page`, params, signal);  
      // return removeBadValue(response.ELENCO, null, 'empty');  
    // } catch (error) {        
    //   throw error;
    // }
  }

        //WAIT LUCA: modify api call when ready. Signal? maybe
        async function getItemsWithSizes (params) {
          const { success, data, error } = await callApi('POST', `/ITEM/${config.language}/Find_ITEMS_WEB_APP`, params); 
          if(success) {
            return { success: true, data: removeBadValue(data.ELENCO, null, 'empty')};
          } else {
            return { success: false, error: error };    
          }
        }
  

  async function getStockSizeQuantity (params) {
    const { success, data, error } = await callApi('POST', `/ITEM/${config.language}/STK_FOR_WEB_01`, params); 
    if(success) {
      return { success: true, data: removeBadValue(data.ELENCO, null, 'empty')};
    } else {
      return { success: false, error: error };    
    } 
  }     

  //DONE: LUCA: removed pw. No need for signal, if 2 calls are sent, wait for the 1st to finish
  async function getReport(params) {
    const { success, data, error } = await callApi('POST', `/ITEM/${config.language}/GET_REPORT`, params);
    if (success) {
      return { success: true, data: data };
    } else {
      return { success: false, error: error };    
    }
  }

  return {getGroup, getData, getStockSizeQuantity, getItemsWithSizes, getReport}

}

export default Item


// async function getGroup (params) {
//   try {
//     const response = await callApi('POST', `/ITEM/${config.language}/Get_All_qry_tbl_ITEM_GROUP_VALUES`, params); 
//     return removeBadValue(response.ELENCO, 'DESCRIPTION', 'all');  
//   } catch (error) {
//     throw new Error(error);
//   }
// }

// async function getData (params, signal) {
// // try {
//   const response = await callApi('POST', `/ITEM/${config.language}/Find_ITEMS_WEB_page`, params, signal);  
//   return removeBadValue(response.ELENCO, null, 'empty');  
// // } catch (error) {        
// //   throw error;
// // }
// }

// async function getStockSizeQuantity (params) {
// try {
//   const response = await callApi('POST', `/ITEM/${config.language}/STK_FOR_WEB_01`, params); 
//   return removeBadValue(response.ELENCO, null, 'empty');  
// } catch (error) {
//   throw new Error(error);
// }
// }  