import { createContext, useCallback, useContext } from 'react';
import Docs from '../api/ApiCallsDocs';
import Item from '../api/ApiCallsProductLookup';
import Subject from '../api/ApiCallsSubject';
import { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import Helper from '../api/Helper';

import notify from 'devextreme/ui/notify'; 
import config from '../utils/constants/config';
import axios from 'axios';
import { AuthContext } from './LoginAuthProvider';
import useTranslate from '../utils/useTranslate';
import { endOfMonth, startOfMonth } from 'date-fns';
// import { mockItemFetchedByBarcode } from '../components/trash/mockData';
import { useLocation } from 'react-router-dom';


const ProductContext = createContext();

const ProductProvider = ({ children }) => {   
  const translate = useTranslate()  
  const { getStores } = Docs()
  const { getStoresWithErpCode } = Subject()
  const { getData, getGroup, getItemsWithSizes } = Item();
  const { allFirst } = Helper();
  const role = Cookies.get('role')
  const location = useLocation(); // Get current route



  //specific
  //product lookup
    const [filter, setFilter] = useState(
      {
        season: Cookies.get('stock-season'),
        department: config.all,
        supplier: config.all,
      }
    )

    //used in children to populate
    const [seasons, setSeasons] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    
    //to have updated values from the store
    const [stockSearch, setStockSearch] = useState(''); //stockSearch is taken from store at component mount
    const [typeSearch, setTypeSearch] = useState('Item');
    const [stockArticles, setStockArticles] = useState([]);

    const [ availableOnly, setAvailableOnly] = useState(Cookies.get('lookup-available-only') == 'true' ? true : false)  //TODO capire se volgiamo salvarlo nei cookies
    
    const [stockPagination, setStockPagination] = useState(1);
    const [stockScrollPosition, setStockScrollPosition] = useState(0)//saves scroll position to allow resume when navigate back
    
    
    // const [season, setSeason] = useState([]); // this comes from cookes
    const [stockSeason, setStockSeason] = useState('');
    const [stockDepartment, setStockDepartment] = useState();
    const [stockSupplier, setStockSupplier] = useState();

    const [activeRequestCount, setActiveRequestCount] = useState(0); //no need to export, used only here
    const [isLoading, setLoading] = useState(false);
    const [noResultsMessage, setNoResultsMessage] = useState(false);

    const [activeRequestCountMoreItems, setActiveRequestCountMoreItems] = useState(0);
    const [isLoadingMoreItems, setLoadingMoreItems] = useState(false);


    const abortControllerRef = useRef(null);
    const abortControllerMoreItemsRef = useRef(null);

//sales dashboard --> saved in provider to avoid re-fetch at each component load
    const [filterDash, setFilterDash] = useState({
      supplier: config.all,
      store: config.all,
    })
    const [suppliersDash, setSuppliersDash] = useState([]);
    const [stores, setStores] = useState([]);

    const [dashboardSupplier, setDashboardSupplier] = useState('');
    const [dashboardSupplierId, setDashboardSupplierId] = useState('');
    const [dashboardStore, setDashboardStore] = useState('');
    const [dashboardStoreId, setDashboardStoreId] = useState('');

    const now = new Date();
    const [calendarDate, setCalendarDate] = useState(sessionStorage.getItem('calendarDate') ? new Date(sessionStorage.getItem('calendarDate')) : now);  //todo: set by the click on calendar

    // const [tab, setTab] = useState(sessionStorage.getItem('timeFrame')? sessionStorage.getItem('timeFrame') : 'day');
    const [timeFrame, setTimeFrame] = useState(sessionStorage.getItem('timeFrame')? sessionStorage.getItem('timeFrame') : 'day');

    const [salesByStore, setSalesByStore] = useState();
    const [salesByChannel, setSalesByChannel] = useState();
  
    const [isGross, setIsGross] = useState();
    const [isCumulative, setIsCumulative] = useState(false);

    const [dashboardRawData, setDashboardRawData] = useState([])
  // end dash

  //QR scanner
    const [QRcode, setQRcode] = useState('')
    const [errors, setErrors] = useState(null)


  // orders
    const [filterOrders, setFilterOrders] = useState({
      store: config.all,
      channel: config.all,
      status: config.all,
      // archive: `${translate('this month')} (default)`
      history: 'this month'
    })
    const [saleChannels, setSaleChannels] = useState([])
    const [ordersStatus, setOrdersStatus] = useState('');
    const [ordersStore, setOrdersStore] = useState('');
    const [ordersStoreId, setOrdersStoreId] = useState('');
    const [ordersChannel, setOrdersChannel] = useState('');
    const [ordersChannelId, setOrdersChannelId] = useState('');
    const [ordersSearch, setOrdersSearch] = useState(''); //stockSearch is taken from store at component mount
    const [openClosedOrders, setOpenClosedOrders]  = useState('open');

    const [ordersPagination, setOrdersPagination] = useState(1);  //todo: capire se serve o se pesco in automatico tutti gli ordini //li pesca man mano, capire come agregare i dati, il primo che arriva in fondo fa partire la chimata che popola entrambe le pagine
    const [ordersCustScrollPosition, setOrdersCustScrollPosition] = useState(0) //todo: capire se serve
    const [ordersItemScrollPosition, setOrdersItemScrollPosition] = useState(0) //todo: capire se serve

    const [visualization, setVisualization] = useState('customer');
    const [isOrdersLoading, setOrdersLoading] = useState(false);
    const [noResultsMessageOrders, setNoResultsMessageOrders] = useState(false);

    const [datesRange, setDatesRange] = useState({
      startDate: startOfMonth(now),
      endDate: endOfMonth(now),
    })
    // const [datesRange, setDatesRange] = useState([])


    // inventory
    const [inventoryBarcode, setInventoryBarcode] = useState('')
    const [inventorySearch, setInventorySearch] = useState('')
    // const [inventoryList, setInventoryList] = useState(JSON.parse(localStorage.getItem('inventoryList')) || [])
    const [inventoryApi, setInventoryApi] = useState({
      // ID: null,
      // status: null,
      // list: []
    }) //add temporary save (understand how, i need inventory id + list)
    const [updatedInventoryList, setUpdatedInventoryList] = useState([])


    //barcode association
    const [ internalBarcode, setInternalBarcode ] = useState('') 
    const [ manufacturerBarcode, setManufacturerBarcode ] = useState('')
    

    //reprint labels
    const [reprintLabelsStore, setReprintLabelsStore] = useState(null)
    const [reprintLabelsStoreId, setReprintLabelsStoreId] = useState(null) 
    const [reprintLabelsSearchText, setReprintLabelsSearchText] = useState('')
    const [reprintLabelsTypeSearch, setReprintLabelsTypeSearch] = useState('Barcode')
    const [reprintLabelsPagination, setReprintLabelsPagination] = useState(1)
const [reprintLabelsArticles, setReprintLabelsArticles] = useState([])
// const [reprintLabelsBarcode, setReprintLabelsBarcode] = useState('')
const [updatedLabelsList, setUpdatedLabelsList] = useState([])
const [selectedLabelFormat, setSelectedLabelFormat] = useState('')
const [manualStockLabelPrint, setManualStockLabelPrint] = useState('from detection')
const [QRscannedItem, setQRscannedItem] = useState({})


  //global (built for reprint labels, reusable)
  const [ storesWithErpCode, setStoresWithErpCode] =useState([])
  const [ storeBarcode, setStoreBarcode] = useState('')
  const [ reprintLabelsSeason, setReprintLabelsSeason] = useState('')
  

// const [isScanning, setIsScanning] = useState(false); // State to control scanning
    
    // const {isAuthenticated} = useContext(AuthContext)

    //product lookup function


/////////////////////////////////////////////////////////////////////
  //   useEffect(()=>{
  //     if(filter && stockPagination) { //add isAuth to avoid calling funct befpre login  && isAuthenticated
  //       search();
  //     }
  //   }, [filter, stockPagination]) // removed , typeSearch, stockSearch, moved in delayed useEffect in product lookup list and in below use effect

  //   //trigger search with delay when user changes search or type --> in the product lookup list the stock/typeSearch triggers a change in pagination (with delay) 
  //     // --> if pagination = 1 and stock/typeSearch, search is not triggered
  //     // --> if pagination != 1 , is set to 1 and the change triggerse the above useEffect
    
    
  //     useEffect(() => {  
  //     // if(stockPagination == 1){  
  //       // if(isAuthenticated){  
  //     const delayTimer = setTimeout(() => {
  //       search()
  //     }, 300);
  //     return ()=> clearTimeout(delayTimer)
  //   // }
  //   // }
  // }, [ stockSearch, typeSearch ]);
//////////////////////////////////////////////////////////////

// da rimettere primo useEffect che controlla quando pagination cambia perch eraggiunto il bottom


  useEffect(() => {
    const delayTimer = setTimeout(() => {
      resetPaginationAndScroll();
      search();
    }, 500);
    return () => clearTimeout(delayTimer);
  }, [stockSearch, typeSearch]);

  useEffect(() => {
    resetPaginationAndScroll();
    search();
  }, [filter, availableOnly]);

  // useEffect(() => {
  //   if (stockPagination > 1) {  // Only search if it's not the initial pagination, when the stockPagination changes
  //     search();
  //   }
  // }, [stockPagination]);

  const resetPaginationAndScroll = () => {
    // setStockPagination(1); //moved after every param change that needs page reset, otherwise it does not work
    setStockScrollPosition(0);
  };

  const fetchSeasons = async () => {
    // setErrors(prevErr => ({...prevErr, seasons: null}))
    try {
      let { success: success, data: data, error: error } = await getGroup('{"$id":"1","FILTER":{"GetAll":false,"Sortings":[{"Field":"DATE_FROM","Ascending":true}, {"Field":"DESCRIPTION","Ascending":true}],"Id":"00000000-0000-0000-0000-000000000000","Id_2":"00000000-0000-0000-0000-000000000000","Id_3":"00000000-0000-0000-0000-000000000000","SimpleFilter":"","QueryFilter":"ID_ITEM_GROUP = \'81ECAAD3-FA0E-42CF-89A6-3B2802FA3C4F\'","Filters":[],"Top":-1,"StartIndex":-1,"PageLength":-1,"Parameter":"","TopParameter":0}}');

      if (success) {
        data = allFirst(data);
        setSeasons(data);
      // } else {
      //   setErrors(prevErr => ({...prevErr, seasons: error.message}))
      }
    } catch (error) {
      notify(translate(error.message) || translate('Error'), 'error', 3000);
    }
  };
  
  //EX comment: //Search items based on selected filters, function is called after populating all the season, department, supplier lists
    //List articles
    const search = useCallback(async () => {
      setActiveRequestCount(prevCount => prevCount + 1); //keep trak of aborted calls to set loader properly
      setLoading(true);
      setErrors(null)
      //In Eversell API if ALL must remove filter
      // console.log('filter', filter);
      

      let season = filter.season == config.all ? '' : filter.season;
      let department = filter.department == config.all ? '' : filter.department;
      let supplier = filter.supplier == config.all ? '' : filter.supplier;
      let localAvailableOnly = availableOnly == true ? 1 : 0 //stock if > 0 --> send 1 and returns avail only, if = 0 returns all, avial and not avail

      season == undefined ? '' : season;
      department == undefined ? '' : department;
      supplier == undefined ? '' : supplier;

      if(typeSearch == 'Barcode'){
        season = ''
        department = ''
        supplier = ''
        localAvailableOnly = 0
      }

      let localStockSearch = stockSearch == undefined ? '' : stockSearch.replace(/\s+/g, '%');  // \s is white space, + is for multiple, /g is global ; this replaces all spaces with %, if multiple spaces in a row, just puts one %
      let localTypeSearch = typeSearch;
      let localStockPagination = stockPagination;
      
      try {
        // Abort the previous request if it exists, when user types more chars in "stockSearch param"
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;

        // let data = await getData(`{
        //   "$id": "1",
        //   "TOP": 0,
        //   "ID_STORE": "${Cookies.get('id_store')}",
        //   "ID_PRICING_ITEM": "efc0ce89-f8c4-4ae0-a71d-478854827877",
        //   "LAST_SELL": 0,
        //   "STOCK": 0,
        //   "ORDERED": 0,
        //   "SEASON": "${season}",
        //   "FREE_SEARCH": "${typeSearch === 'Item' ? localStockSearch : ''}",
        //   "ID_ITEM": "00000000-0000-0000-0000-000000000000",
        //   "CODE": "",
        //   "SEASON_LIST": "",
        //   "REPARTO": "${department}",
        //   "BRAND": "",
        //   "SUPPLIER": "${supplier}",
        //   "BARCODE": "${typeSearch === 'Barcode' ? localStockSearch : ''}",
        //   "P_LENGTH": "10",
        //   "P_NUMBER": "${stockPagination}"
        // }`, signal) //todo: check velocita chiamata con 10 20 50 items 

        //"STOCK":0
        let { success: success, data: data, error: error} = await getData(`{"$id":"1","TOP":0,"ID_STORE":"${Cookies.get('id_store')}","ID_PRICING_ITEM":"efc0ce89-f8c4-4ae0-a71d-478854827877","LAST_SELL":0,"STOCK":${localAvailableOnly},"ORDERED":0,"SEASON":"${season}","FREE_SEARCH":"${localTypeSearch==='Item'?localStockSearch:''}","ID_ITEM":"00000000-0000-0000-0000-000000000000","CODE":"","SEASON_LIST":"","REPARTO":"${department}","BRAND":"","SUPPLIER":"${supplier}","BARCODE":"${localTypeSearch==='Barcode'?localStockSearch:''}","P_LENGTH":"10","P_NUMBER":"${localStockPagination}"}`,signal) //todo: check velocita chiamata con 10 20 50 items 
        if(success){
        if(stockPagination != 1){ //attualm the search call does not handle pages after the 1st, "loadMoreItems" does
          data = stockArticles.concat(data) 
        }
        setStockArticles(data);
        setNoResultsMessage(data.length == 0) //bool
      } else {
        setErrors(error?.message);
      }
      } catch (error) {
        // console.log('Request canceled GAGA PROVIDER ERR', error.name);
        if (!error.name === 'CanceledError') {  //if api call not aborted 
          notify(translate(error.message) || translate('Error'), 'error', 3000);
        }
      } finally {
        setActiveRequestCount(prevCount => prevCount - 1);
      }
    }, [filter, typeSearch, stockSearch, stockArticles, availableOnly]) //removed stockPagination to fix bug Safari

    //to check the current number of ongoing calls (avoids wrong isLoading setting upon abort)
    useEffect(() => {
      setLoading(activeRequestCount > 0)
    }, [activeRequestCount])


    const loadMoreItems = async () => {
      setActiveRequestCountMoreItems(prevCount => prevCount + 1); //keep trak of aborted calls to set loader properly

      setLoadingMoreItems(true);
      setErrors(null)
      let localStockPagination = stockPagination + 1;


      let season = filter.season == config.all ? '' : filter.season;
      let department = filter.department == config.all ? '' : filter.department;
      let supplier = filter.supplier == config.all ? '' : filter.supplier;
      let localAvailableOnly = availableOnly == true ? 1 : 0 //stock if > 0 --> send 1 and returns avail only, if = 0 returns all, avial and not avail

      season == undefined ? '' : season;
      department == undefined ? '' : department;
      supplier == undefined ? '' : supplier;

      if(typeSearch == 'Barcode'){
        season = ''
        department = ''
        supplier = ''
        localAvailableOnly = 0
      }

      let localStockSearch = stockSearch == undefined ? '' : stockSearch.replace(/\s+/g, '%');  // \s is white space, + is for multiple, /g is global ; this replaces all spaces with %, if multiple spaces in a row, just puts one %
      let localTypeSearch = typeSearch;
      
      try {
        if (abortControllerMoreItemsRef.current) {
          abortControllerMoreItemsRef.current.abort();
        }
        abortControllerMoreItemsRef.current = new AbortController();
        const signal = abortControllerMoreItemsRef.current.signal;

        let { success: success, data: data, error: error} = await getData(`{"$id":"1","TOP":0,"ID_STORE":"${Cookies.get('id_store')}","ID_PRICING_ITEM":"efc0ce89-f8c4-4ae0-a71d-478854827877","LAST_SELL":0,"STOCK":${localAvailableOnly},"ORDERED":0,"SEASON":"${season}","FREE_SEARCH":"${localTypeSearch==='Item'?localStockSearch:''}","ID_ITEM":"00000000-0000-0000-0000-000000000000","CODE":"","SEASON_LIST":"","REPARTO":"${department}","BRAND":"","SUPPLIER":"${supplier}","BARCODE":"${localTypeSearch==='Barcode'?localStockSearch:''}","P_LENGTH":"10","P_NUMBER":"${localStockPagination}"}`, signal) //todo: check velocita chiamata con 10 20 50 items 
        if(success){
        // if(stockPagination != 1){
          data = stockArticles.concat(data)
        // }
        setStockArticles(data);
        setNoResultsMessage(data.length == 0) //bool
        setStockPagination(prevPag => prevPag + 1)
      } else {
        setErrors(error?.message);
      }
      } catch (error) {
        if (!error.name === 'CanceledError') {  //if api call not aborted 
          notify(translate(error.message) || translate('Error'), 'error', 3000);
        }
      } finally {
        setActiveRequestCountMoreItems(prevCount => prevCount - 1);
      }
    }


    //to check the current number of ongoing calls (avoids wrong isLoading setting upon abort)
    useEffect(() => {
      setLoadingMoreItems(activeRequestCountMoreItems > 0)
    }, [activeRequestCountMoreItems])
    

    //Get my store name using its ID
  const fetchStoreName = async () => {
    try {
      let { success: success, data: data, error: error } = await getStores(`{"$id":"1","FILTER":{"Sortings":[{"Field":"SEARCH_NAME","Ascending":true}]}}`);
      // if(success){        
        let storeName = data.find((store) => store.ID == Cookies.get('id_store')).SEARCH_NAME
        Cookies.set('store_name', storeName)
      // }
    } catch (error) {
      console.log(error);
      // notify(translate(error.message) || translate('Error'), 'error', 3000);
    }
  }

    useEffect(() => {
      if(role != 0 && role != 1) {
        fetchStoreName()
      }
    }, [role]);


    //search store by barcode
    const fetchStoresWithErpCode = async () => {
      // setErrors(prevErr => ({...prevErr, stores: null}))
      try {
        let { success: success, data: data, error: error } = await getStoresWithErpCode(`{"$id":"1","FILTER":{"Sortings":[{"Field":"SEARCH_NAME","Ascending":true}]}}`);
        if(success){  
          let actualStores = data.filter((store) => store.IS_POS == 1) //only stores with IS_POS = 1 are real stores  
          setStoresWithErpCode(actualStores);
        // } else {
        //   setErrors(prevErr => ({...prevErr, stores: error?.message}))
        }
      } catch (error) {
        notify(translate(error.message) || translate('Error'), 'error', 3000);
      }
    }

    useEffect(()=>{
      if(storesWithErpCode && storesWithErpCode.length >0 && storeBarcode){
        let selectedStore = storesWithErpCode.find((store) => store.CODE.replace(/\s/g,'') == storeBarcode.replace(/\s/g,'')) 
        // || {SEARCH_NAME: 'bilbo', ID: 'baggins'}
        // console.log('selectedStore', selectedStore);
        if(selectedStore){
          setReprintLabelsStore(selectedStore.SEARCH_NAME)
          setReprintLabelsStoreId(selectedStore.ID)
        } else {
          notify(translate('Store not found, select a valid store'), 'error', 3000)
        }
      }
    },[storeBarcode])

    // useEffect(()=>{
    //   let selectedStore = storesWithErpCode.find((store) => store.CODE == storeBarcode)
    //   setReprintLabelsStore(selectedStore.SEARCH_NAME)
    //   setReprintLabelsStoreId(selectedStore.ID)
    // },[storeBarcode])


    //questa diventerà la search per taglie generica, riceve il parametro da cercare   da confront con searchText in mainReprintLAbels
    //reprintLabelsBarcode reader
    const fetchItemWithSizes = useCallback(async (searchTextBarcode, fromQRscanner) => {
      console.log('fetchItemWithSizes', searchTextBarcode);
      
      // if(!fromQRscanner) aborter ? diventa incasinato? No, legge un codice, aspetta risposta, presenta articolo, user sceglie, poi fa altra chiamata

      // setActiveRequestCount(prevCount => prevCount + 1); //keep trak of aborted calls to set loader properly
      // if(reprintLabelsBarcode && reprintLabelsBarcode != ''){
        
      // setIsScanning(false)
      setLoading(true);
      setErrors(null)
      setNoResultsMessage(false)
      // const localBarcode = reprintLabelsBarcode ? reprintLabelsBarcode : '';
      // const localBarcode = reprintLabelsBarcode 
      // let localIdStore = reprintLabelsStoreId
      // let localSearchText = reprintLabelsSearchText == undefined ? '' : reprintLabelsSearchText.replace(/\s+/g, '%');   
      let localSearchText = searchTextBarcode.replace(/\s+/g, '%') || '';   // \s is white space, + is for multiple, /g is global ; this replaces all spaces with %, if multiple spaces in a row, just puts one %
      let localTypeSearch = reprintLabelsTypeSearch;
      // let idSeason = seasons.find((season) => {season.DESCRIPTION == reprintLabelsSeason}).ID || "00000000-0000-0000-0000-000000000000"
        // reprintLabelsSeason || '';

        // console.log('idSeason', idSeason);
        
  
  
      //TODO: capire se vogliamo aborter o se vogliamo che prima finisca la chiamata prima; FINISCE CHIMATA PRIMA 

//TODO: uncomment when api call is ready
      try {
  
      //     // if (abortControllerRef.current) {
      //     //   abortControllerRef.current.abort();
      //     // }
      //     // abortControllerRef.current = new AbortController();
      //     // const signal = abortControllerRef.current.signal;
          
        //   let { success: success, data: data, error: error} = await getData(`{
        //     "$id":"1",
        //     "TOP":0,
        //     "ID_STORE":"${localIdStore}","ID_PRICING_ITEM":"efc0ce89-f8c4-4ae0-a71d-478854827877",
        //     "LAST_SELL":0,
        //     "STOCK":0,
        //     "ORDERED":0,
        //     "SEASON":"${localTypeSearch==='Item'?localSeason:''}",
        //     "FREE_SEARCH":"${localTypeSearch==='Item'?localSearchText:''}",
        //     "ID_ITEM":"",
        //     "CODE":"",
        //     "SEASON_LIST":"",
        //     "REPARTO":"",
        //     "BRAND":"",
        //     "BARCODE":"${localTypeSearch==='Barcode'?localSearchText:''}",
        //     "P_LENGTH":"1",
        //     "P_NUMBER":"1"
        //     }`
        //   // , signal
        // ); 



        // "ID_SEASON":"${localTypeSearch==='Item'?idSeason:''}",

                  
        let { success: success, data: data, error: error} = await getItemsWithSizes(`{
          "ID_SEASON":"00000000-0000-0000-0000-000000000000",
          "FREE_SEARCH":"${localTypeSearch==='Item'?localSearchText:''}",
          "BARCODE":"${localTypeSearch==='Barcode'?localSearchText:''}",
          "P_LENGTH":"1000",
          "P_NUMBER":"1"
          }`
        // , signal
      ); 
          // if(success){
              if (data && data.length > 0) {
                    if(fromQRscanner){ //setta unico articolo
                      // console.log(data[0]);
                      
                      setQRscannedItem(data[0])
                    } else { //setta array di articoli
                      setReprintLabelsArticles(data)
                    }
                    return data

                  // addItemToLabelsList(data[0]); //directly add the item scanned from barcode to the list, instead of saving it temporally in article
              } else if(!data || data.length == 0) {
                notify({
                  message: translate('Product not found.'),
                  position: {
                    at: "top center",
                    my: "top center",
                    offset: "0 150"
                  }
                }, 'error', 3000)
                // notify(translate('Product not found.'), 'error', 3000);  //ITA Errore nel recupero dei dati     
                if(fromQRscanner){ //setta unico articolo
                  setQRscannedItem(null)
                } else { //setta array di articoli
                  setReprintLabelsArticles([])
                }             
                // setNoResultsMessage(true)
                // setReprintLabelsBarcode(''); // Clear the barcode to trigger useEffect in QRScannerWithList, to reserScanning
                return []
              }
          // } else {
          //     setErrors(error.message);
          // }
        setLoading(false);
        // return data

      } catch (error) {
          notify(translate('Error in fetching data'), 'error', 3000);  //ITA Errore nel recupero dei dati
          // setReprintLabelsBarcode(''); // Clear the barcode to trigger useEffect in QRScannerWithList, to reserScanning
        setLoading(false);
        return []

      // } finally {
        // setIsScanning(false)

        //delay Clear the barcode to trigger useEffect in QRScannerWithList, to reserScanning, to avoid reading 500 items in 1 second --> removed useEffect that feels barcode changes, added action sheet give user ability to control the actions
        
        // setTimeout(() => {
        //   setReprintLabelsBarcode('');
        // }, 500);
        
        // setReprintLabelsBarcode('');
      //   setLoading(false);
        
      // }
      //TODO: end uncomment when api call ready


      //for test purposes, to delete when real call is available
        // console.log('i am going to add the item', mockItemFetchedByBarcode);
                          // setQRscannedItem(mockItemFetchedByBarcode);

        // addItemToLabelsList(mockItemFetchedByBarcode)
        // setTimeout(() => {
        //   setReprintLabelsBarcode('');
        // }, 5000);

      
    }
    },[reprintLabelsStoreId, reprintLabelsTypeSearch]); //TODO: compilare dipendenze, se concat per aggiungere altri dati con pagination 2, add anche reprintLabelsArticles 

    const addItemToLabelsList = useCallback((selectedItem) => {
      console.log('print selected item', selectedItem);

      //based on location, displays notify in different positions
      // let notifyPosition = location.pathname == '/reprint-labels' ? {
      //   at: "top center",
      //   my: "top center",
      //   offset: '0 150' 

      //   // of: "#notify-container", //change   "#qr-reader" || 
      //   // offset: "0 200"
      // } : { 
      //   at: 'top center', 
      //   my: 'top center', 
      //   offset: '0 150' 
      //   // offset: '0 -20' 
      // }

      let notifyPosition = {
        at: "top center",
        my: "top center",
        offset: '0 150' 
      }
      
      if(selectedItem && selectedItem != null){
        // Deep copy the selected item to prevent modifications to mocklist / articles list
        const deepCopiedItem = {
          ...selectedItem,
          SIZEZ: selectedItem.SIZEZ.map(size => ({ ...size })) // Clone the sizes array
        };
        let localUpdatedList = [...updatedLabelsList]




        //check if item already present
        // const existingItemIndex = updatedLabelsList.findIndex(item => item.ID == deepCopiedItem.ID)
        // console.log('existingItemIndex', existingItemIndex);

        //changed array structure, each item has different size
        selectedItem.SIZEZ.forEach(size => {
          if (size.DETECTED_QTY > 0) { // Only add sizes with DETECTED_QTY > 0
              const existingItemIndex = localUpdatedList.findIndex(
                  item => item.ID === selectedItem.ID && item.SIZE_CODE === size.SIZE_CODE
              );
        
            //if code not already present. add article to beginning of list
            if(existingItemIndex == -1) {
              console.log('i add new');
          
          // New item, set DETECTED_QTY to 1 and add to the list
          // selectedItem.DETECTED_QTY = 1;
          // localUpdatedList = [deepCopiedItem, ...updatedLabelsList]

          //changed array structure, each item has different size
              localUpdatedList = [
                { 
                    ...selectedItem, 
                    SIZEZ: undefined,  // Remove original SIZEZ array
                    ID_SIZE: size.ID_SIZE,
                    SIZE_CODE: size.SIZE_CODE, 
                    DETECTED_QTY: size.DETECTED_QTY, 
                    ID_FOR_LIST: selectedItem.ID + size.ID_SIZE
                },
                ...localUpdatedList // Add to the beginning of the list
            ];

            } else { // Existing item, 
              console.log('i add to prev');

          // localUpdatedList[existingItemIndex].DETECTED_QTY += 1;
          
          //version: substitute the new qty to the previous
          // localUpdatedList[existingItemIndex] = selectedItem;

          //version: add the new qty to the old
          // Existing item, for each size, sums new qty to prev, increase DETECTED_QTY

          //set the sizes of the selected item to the old sizes + the new
          // localUpdatedList[existingItemIndex].SIZEZ = localUpdatedList[existingItemIndex].SIZEZ.map((size) => {
          // // Find the matching size in the selected item and sum the quantities
          //   const selectedSize = deepCopiedItem.SIZEZ.find(s => s.ID_SIZE === size.ID_SIZE);
          //   if (selectedSize) {// Add the new detected quantity to the existing quantity
          //     console.log('quantity of deepCopiedItem', selectedSize.DETECTED_QTY);
          //     size.DETECTED_QTY += selectedSize.DETECTED_QTY //now correctly updates the list render
          //   }
          //   return size;
          // });


          //changed array structure, each item has different size
          localUpdatedList[existingItemIndex].DETECTED_QTY += size.DETECTED_QTY;

          console.log('updated list with summed quantities', localUpdatedList); //giusto
          }
        }
      });
        setUpdatedLabelsList(localUpdatedList)

        notify({
            message: translate('Item added successfully'),
            position: notifyPosition 
            // {
            //   at: "top center",
            //   my: "top center",
            //   of: "#qr-reader", //change   "#notify-container" || 
            //   // offset: "0 200"
            // }
          }, 'success', 2000)
  
        // setArticle(null) // update article
        // setReprintLabelsBarcode('') //reset barcode
        console.log('updated list ' , localUpdatedList);
      } else {
        notify({
          message: translate('Error in adding item'),
          position: notifyPosition
          // {
          //   at: "top center",
          //   my: "top center",
          //   of: "#notify-container", //change
          //   // offset: "0 200"
          // }
        }, 'danger', 3000)
      }
    }, [updatedLabelsList, QRscannedItem]) 


  

  // useEffect(() => {
  //   if(reprintLabelsBarcode && reprintLabelsBarcode != '') fetchItemFromQR()
  // }, [reprintLabelsBarcode])

      
//when export sth here, also clean the data on logout in the sidebar component
  return (
    <ProductContext.Provider value={{ filter, setFilter, seasons, setSeasons, departments, setDepartments, suppliers, setSuppliers, stockSearch, setStockSearch, typeSearch, setTypeSearch, stockArticles, setStockArticles, availableOnly, setAvailableOnly, stockPagination, setStockPagination, loadMoreItems, stockScrollPosition, setStockScrollPosition, stockSeason, setStockSeason, stockDepartment, setStockDepartment, stockSupplier, setStockSupplier, isLoading, isLoadingMoreItems, setLoading, search, noResultsMessage, 

    filterDash, setFilterDash, suppliersDash, setSuppliersDash, stores, setStores, dashboardSupplier, setDashboardSupplier, dashboardSupplierId, setDashboardSupplierId, dashboardStore, setDashboardStore, dashboardStoreId, setDashboardStoreId, 
    calendarDate, setCalendarDate, 
    // tab, setTab, 
    timeFrame, setTimeFrame, salesByStore, setSalesByStore, salesByChannel, setSalesByChannel, isGross, setIsGross, isCumulative, setIsCumulative, dashboardRawData, setDashboardRawData,
    QRcode, setQRcode, errors, setErrors,
    filterOrders, setFilterOrders, ordersStatus, setOrdersStatus, ordersStore, setOrdersStore, ordersStoreId, setOrdersStoreId, ordersChannel, setOrdersChannel, ordersChannelId, setOrdersChannelId, saleChannels, setSaleChannels, ordersSearch, setOrdersSearch, openClosedOrders, setOpenClosedOrders, ordersPagination, setOrdersPagination,  ordersCustScrollPosition, setOrdersCustScrollPosition, ordersItemScrollPosition, setOrdersItemScrollPosition, visualization, setVisualization, //orders
    isOrdersLoading, setOrdersLoading, noResultsMessageOrders, setNoResultsMessageOrders, datesRange, setDatesRange,
    inventoryBarcode, setInventoryBarcode, inventorySearch, setInventorySearch, inventoryApi, setInventoryApi, updatedInventoryList, setUpdatedInventoryList, //inventory
    internalBarcode, setInternalBarcode, manufacturerBarcode, setManufacturerBarcode, // barcode association
    reprintLabelsStore, setReprintLabelsStore,  reprintLabelsStoreId, setReprintLabelsStoreId,
    reprintLabelsSearchText, setReprintLabelsSearchText,
    reprintLabelsTypeSearch, setReprintLabelsTypeSearch,
    reprintLabelsPagination, setReprintLabelsPagination,
    reprintLabelsArticles, setReprintLabelsArticles,
    // reprintLabelsBarcode, setReprintLabelsBarcode,
    updatedLabelsList, setUpdatedLabelsList, selectedLabelFormat, setSelectedLabelFormat, manualStockLabelPrint, setManualStockLabelPrint, addItemToLabelsList, fetchItemWithSizes, QRscannedItem, setQRscannedItem, fetchStoresWithErpCode, storesWithErpCode, setStoresWithErpCode, storeBarcode, setStoreBarcode, reprintLabelsSeason, setReprintLabelsSeason, fetchSeasons //, isScanning, setIsScanning // reprint labels
    }}>
      {children}
    </ProductContext.Provider>
  );
};

export { ProductContext, ProductProvider };