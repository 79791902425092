import { Route, Navigate, createBrowserRouter, createRoutesFromElements} from 'react-router-dom';
import { Suspense } from 'react';
import Login from './pages/Login';
import ProductLookup from './pages/ProductLookupWithProvider.jsx';
import SalesDashboard from './pages/SalesDashboardWithProvider.jsx';

import Orders from './pages/Orders.jsx';
import Inventory from './pages/Inventory.jsx';
import BarcodeAssociation from './pages/BarcodeAssociation.jsx';
import QRScannerPage from './pages/QRscannerPage.jsx';
import InventoriesManagement from './pages/InventoriesManagement.jsx';
import Providers from './Providers.jsx';

import { Customer, OrderDetail, OrdersSingleCustomer, ProductDetails, Settings } from './lazyComponents.jsx'
import ReprintLabels from './pages/ReprintLabels.jsx';
import QRScannerWithListPage from './pages/QRscannerWithListPage.jsx';
import Faq from './pages/Faq.jsx';


export const router = createBrowserRouter (
    createRoutesFromElements (
    <>
    {/* <AnimatePresence mode='wait'> */}
      {/* <AnimatedPage animation={getAnimation(location.pathname)}>
        <Routes location={location} key={location.key}> */}
        {/* <Routes> */}

    <Route element={<Providers />}>
        <Route path="/" element={<Login />} />     
        {/* <Route element={<PrivateRoute /> } >    */}
        {/* todo: uncomment after login allows to enter */}
        {/* this allows navigation in the following routes only if */}    
        <Route path="/product-lookup" element={<ProductLookup/> } />
        <Route path="/product-lookup/:id" element={
          <Suspense fallback={<div></div>}>
            <ProductDetails />
          </Suspense>
          } />
        <Route path="/qr-scanner" element={
        <Suspense fallback={<div></div>}>
          <QRScannerPage />
        </Suspense>
        } />

        <Route path="/qr-scanner-list" element={
        <Suspense fallback={<div></div>}>
          <QRScannerWithListPage />
        </Suspense>
        } />

      <Route path="/sales-dashboard" element={<SalesDashboard />} /> 
    
      {/* <Route path="/orders" element={<Orders/> } />
      <Route path="/orders/:id" element={
        <Suspense fallback={<div></div>}>
          <OrderDetail />
        </Suspense>
        } />
      <Route path="/orders/customer/:id" element={
        <Suspense fallback={<div></div>}>
          <OrdersSingleCustomer />
        </Suspense>
        } /> */}


{/* TO UNCOMMENT BEFORE RELEASE NEW VERSION */}

        <Route path="/reprint-labels" element={
          <Suspense fallback={<div></div>}>
            <ReprintLabels />
          </Suspense>
         } />


        <Route path="/customer" element={
          <Suspense fallback={<div></div>}>
          <Customer />
        </Suspense>
        } />




{/* TO UNCOMMENT BEFORE RELEASE NEW VERSION */}
         {/* <Route path="/inventory" element={
          <Suspense fallback={<div></div>}>
          <InventoriesManagement />
        </Suspense>
        } />

        <Route path="/inventory/:id" element={
          <Suspense fallback={<div></div>}>
          <Inventory />
        </Suspense>
        } />

        <Route path="/barcode-association" element={
          <Suspense fallback={<div></div>}>
          <BarcodeAssociation />
        </Suspense>
        } /> */}

        
        <Route path="/settings" element={
          <Suspense fallback={<div></div>}>
          <Settings />
          </Suspense>
        } />

        <Route path="/faq" element={
          <Suspense fallback={<div></div>}>
            <Faq />
          </Suspense>
         } />
         
        <Route path='*' element= {<Navigate to="/product-lookup"/>}/> {/*todo handle this correctly */}



        {/* version with require auth, for now not used */}
        {/* <Route path="/product-lookup" element={<RequireAuth><ProductLookup/></RequireAuth> } />
        <Route path="/product-lookup/:item" element={<RequireAuth><ProductDetails /></RequireAuth>} />
        <Route path="/sales-dashboard" element={<RequireAuth><SalesDashboard /></RequireAuth>} />
        <Route path="/customer" element={<RequireAuth><Customer /></RequireAuth>} />
        <Route path='*' element= {<Navigate to="/product-lookup"/>}/>  */}
        {/* todo handle this correctly */}
        {/* <Route path='/' element= {<RedirectToLogin/>}/> todo handle this correctly */}
        {/* </Route>   */}
    </Route>
      {/* </Routes> */}
        {/* </Routes>
      </AnimatedPage>*/}
    {/* </AnimatePresence>  */}
    </>
  )
)