import { Popup } from 'devextreme-react/popup';
import { useCallback, useMemo } from 'react';
import useTranslate from '../utils/useTranslate';
import { Button } from 'devextreme-react/button';
import ButtonsList from './ButtonsList';

const mockButtons = {
    id: '',
    type: '', //"normal", "success", "default", "danger"
    text: '',
    function: () => {},
    stylingMode: '', //default is "contained"
    className: '',
} 


const ConfirmationPopup = ({popupTitle, popupMessage, popupButtons, dependency, dependency2, isPopupVisible, hidePopup, hideOnOutsideClick= true, showTitle = true, showCloseButton = true }) => {
    const translate = useTranslate()

    const justifyButtons = popupButtons?.length > 1 ? 'justify-content-between' : 'justify-content-around'
    const renderContent = useCallback(() => {
        return (
        <div className="px-4 py-3">
            <p style={{ whiteSpace: 'pre-line'}}>
            {translate(popupMessage)}
            </p>
            <div className={`d-flex ${justifyButtons} mt-3`}>
                <ButtonsList buttonsList={popupButtons} />
            </div>
        </div>
        );
    }, [dependency, dependency2]); //prev openItemId for delete confirmation
    
    return (
        <Popup
        contentRender={renderContent}
        visible={isPopupVisible}
        hideOnOutsideClick={hideOnOutsideClick}
        onHiding={hidePopup}
        showTitle={showTitle}
        title={popupTitle}
        showCloseButton={showCloseButton}
        defaultWidth={290}
        defaultHeight={'auto'}
        maxHeight={350}
        />     
    )
}

export default ConfirmationPopup


                {/* {popupButtons.map((button) => (
                    <Button
                    key={button.id}
                    type={button.type}
                    text={translate(button.text)}
                    onClick={button.function}
                    stylingMode={button.stylingMode || "contained"}
                    className={button.className || ''}
                    // className='bg-grey'
                    />
                ))} */}
{/* <Button
type={firstBtnType}
text={translate(firstBtnText)}
onClick={firstBtnFunction}
stylingMode="contained"
className='bg-grey'
/>
<Button
type={secondBtnType}
text={translate(secondBtnText)}
onClick={secondBtnFunction}
stylingMode="contained"
/> */}