import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { httpToHttps } from '../utils/functions'
import useTranslate from '../utils/useTranslate'
import { NumberBox, Button as NumberBoxButton } from 'devextreme-react/number-box'
import { ProductContext } from '../contexts/ProductProvider'
// import { AnimatePresence, motion } from 'framer-motion'
// import InventoryItemDetails from './InventoryItemDetails'
// import ReprintLabelsItemDetails from './ReprintLabelsItemDetails'

//mock
// const initialDetails = [
//   {
//       BARCODE: 'XS',
//       ID: 1,
//       DETECTED_QTY: 10,
//       STOCK_QTY: 20,
//       DIFFERENCE: 10,
//   },
//   {
//       BARCODE: 'S',
//       ID: 2,
//       DETECTED_QTY: 20,
//       STOCK_QTY: 30,
//       DIFFERENCE: 10,
//   },
//   {
//       BARCODE: 'M',
//       ID: 3,
//       DETECTED_QTY: 30,
//       STOCK_QTY: 40,
//       DIFFERENCE: 10,
//   },
//   {
//       BARCODE: 'L',
//       ID: 4,
//       DETECTED_QTY: 40,
//       STOCK_QTY: 50,
//       DIFFERENCE: 10,
//   },
//   {
//       BARCODE: 'XL',
//       ID: 5,
//       DETECTED_QTY: 50,
//       STOCK_QTY: 60,
//       DIFFERENCE: 10,
//   },
//   {
//       BARCODE: 'UNICA',
//       ID: 6,
//       DETECTED_QTY: 60,
//       STOCK_QTY: 70,
//       DIFFERENCE: 10,
//   }
// ]


// const animationContent = {
//   initial: { height: 0, opacity: 0 },
//   animate: { height: 'auto', opacity: 1 },
//   exit: { height: 0, opacity: 0 },
//   transition: {
//     duration: 0.3,
//     ease: 'easeInOut'
//   },
// }


const ReprintLabelsItem = ({item, showQuantities}) => {
    const translate = useTranslate()
    
    // const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
    // const [isEditable, setIsEditable] = useState(false);    
    // const [totalQty, setTotalQty] = useState(0);
    // const [details, setDetails] = useState(initialDetails)  // TODO da recuperare con chiamata se invnetory non è nuovo (capire come definire "non è nuovo")
    
    // const [editedSizes, setEditedSizes] = useState(null); //set the sizes to a copy of the updatedListSizes
    


    const {updatedLabelsList, setUpdatedLabelsList, manualStockLabelPrint} = useContext(ProductContext)


    // useEffect(() => {
    //   setEditedSizes(item.SIZEZ)
    // },[item])

    
   
//changed array structure, each item has different size

  //   useEffect(() => {
  //     //sum of the qty of each size
  //     const total = editedSizes?.reduce((acc, size) => acc + size.DETECTED_QTY, 0);
  //     setTotalQty(total); // pass total qty to parent component to update the total inventory qty in the header
  // }, [editedSizes, updatedLabelsList]) //removed setTotalQty



  // const onValueChanged = useCallback((e) => {
  //   //trova item nella list, add e.value

  //   setUpdatedLabelsList(currentList => 
  //     prevSizes.map(size => 
  //       size.ID_SIZE === sizeId 
  //         ? { ...size, DETECTED_QTY: e.value } 
  //         : size
  //     )
  //   );
  // }, []);


// Update the global list whenever editedSizes changes
const onValueChanged = useCallback((e) => {
    setUpdatedLabelsList(currentList => {
        // Find the index of the current item in the list
        const itemIndex = currentList.findIndex(listItem => listItem.ID_FOR_LIST === item.ID_FOR_LIST);
        
        // If the item doesn't exist in the list, return the current list
        if (itemIndex === -1) return currentList;
        
        // Create a new list with the updated item
        const newList = [...currentList];
        newList[itemIndex] = {
            ...newList[itemIndex],
            DETECTED_QTY: e.value
        };
        
        return newList;
    })    
},[])




  //EX: accordion for size details
    // const handleAccordionExpansion = useCallback(() => {
    //   // if (isAccordionExpanded && isEditable) {
    //   //   setIsEditable(false)
    //   //   setEditableId(null)
    //   // }
    //   setIsAccordionExpanded(!isAccordionExpanded)
    // }, [isAccordionExpanded]) //isEditable

    // useEffect(()=>{
    //   if(editableId == item.ID) {
    //     setIsAccordionExpanded(true)
    //     setIsEditable(true)
    //   } else {
    //     setIsEditable(false)
    //     // setEditableId(null)
    //   }
    // }, [editableId])



//OLD
    // const onValueChanged = useCallback((e) => {
    //   const newValue = e.value
    //   if (newValue < 0) return // prevent negative values
    //     // Update the global context list (updatedLabelsList)
    //   setUpdatedLabelsList((prevList) =>
    //     prevList.map((label) =>
    //       label.BARCODE === item.BARCODE ? { ...label, DETECTED_QTY: newValue } : label
    //     )
    //   );
    // }, [item.BARCODE])

    useEffect(()=>{
      console.log('listaAggiornata', updatedLabelsList);
    }, [updatedLabelsList])



  return (
    <>
    <div className='w-100'>
    <div className="stock-item-child d-flex justify-content-between align-items-center w-100">
      <div className="stock-item border border-0 d-flex align-items-center flex-shrink-1 flex-grow-1 m-0 p-0 ">
        {/* image */}
        <div className="img-container d-flex justify-content-center align-items-center">
          <img src={httpToHttps(item.IMAGE_01)} alt="image" />
        </div>

        {/* data */}
        <div className="d-flex justify-content-between text-start me-2 pe-0 pe-sm-4 flex-grow-1">
          <div>
            <p className="text-uppercase fw-semibold fs-14 lh-1">
              {item.CODE}
              <span className="grey"> {item.AUX_CODE}</span>
              {item.BASE_CODE != item.CODE && 
                  <span className='text-secondary fs-12'> {item.BASE_CODE}</span>
              }
            </p>
            <div className="grey">
              <p className="description fs-12">{item.DESCRIPTION}</p>
              {/* <p className="variant fs-12">{item.VARIANT_DESCRIPTION}</p> */}
              {/* <p className="supplier-name text-uppercase d-inline grey-emphasis fs-12">{item.BRAND}</p>  */}
              {/* <p className="grey-emphasis fs-12">{item.BARCODE}</p> */}
            </div>
          </div>
          <div className='d-flex flex-column align-items-center text-center flex-shrink-0'>
            <p>{translate('sz')}: </p>
            <span className='fs-5'>{item.SIZE_CODE}</span>
          </div>
        
        
          {/* {showQuantities && */}
            {/* <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="title text-uppercase fw-semibold flex-shrink-0 fs-4">
                {/* {totalQty} *
                {item.DETECTED_QTY}
              </p>
            </div> */}

              
            {/* } */}
            </div>



        {/* arrow */}
        {/* {showQuantities &&
          <div 
            className="arrow pe-0 pe-sm-5 flex-shrink-0"
            onClick={handleAccordionExpansion}
          >
            <i 
              className={`fa-solid fa-chevron-down accordion-chevron-icon ${isAccordionExpanded ? 'expanded' : ''}`}
            ></i>
          </div>
        } */}

      </div>

      
      <div className='text-end p-0 labels-numberbox-container flex-shrink-0'>
          <NumberBox
              value={item.DETECTED_QTY}
              min={0}
              max={1000000000000}
              showSpinButtons={false}
              onValueChanged={(e) => onValueChanged(e)}
              className='inventory-number-box pe-4'
              valueChangeEvent='keyup'
          >
          </NumberBox>
        </div>

    </div>
    {/* <AnimatePresence>
      {(isAccordionExpanded && showQuantities) &&(
        <motion.div 
          className='w-100' 
          initial={animationContent.initial}
          animate={animationContent.animate}
          exit={animationContent.exit}
          transition={animationContent.transition}
          // style={{ visibility: isAccordionExpanded ? 'visible' : 'hidden' }}  // Toggle visibility based on isAccordionExpanded
          >
                  <ReprintLabelsItemDetails item={item} setTotalQty={setTotalQty} editedSizes={editedSizes} setEditedSizes={setEditedSizes}/>
        </motion.div>
      )}
    </AnimatePresence> */}


    </div>
    </>
  )
}

export default ReprintLabelsItem





  // const updatedList = updatedLabelsList?.map((label) => {
      //   if (label.BARCODE === item.BARCODE) {
      //     return { ...label, DETECTED_QTY: newValue };
      //   }
      //   return label;
      // });

      // setUpdatedLabelsList(updatedList);

      // update size.DETECTED_QTY with e.value

      //steps
      // 1. get the current item from array? in teoria gia lo passa, cerco barcode in mia updatedList, per aggiornare la quantita anche li
      // 2. update item.DETECTED_QTY with e.value (item.DETECTED_QTY) 
      // 3. update size in details array
      // 4. rerender the component to reflect the changes


      

// {manualStockLabelPrint != 'from stock' &&
//       <div className='labels-numberbox-container'>
//       <NumberBox
//         value={item.DETECTED_QTY}
//         min={0}
//         max={1000000000000}
//         showSpinButtons={false}
//         readOnly={false}
//         onValueChanged={onValueChanged}
//         className='inventory-number-box pe-4'
//       >
//       </NumberBox>
//       </div>
      
// }



    // const onValueChanged = useCallback((newValue, item) => {
    //   if (newValue < 0) return // prevent negative values
    //     // Update the global context list (updatedLabelsList)
    //   const updatedList = updatedLabelsList?.map((label) => {
    //     if (label.BARCODE === item.BARCODE) {
    //       return { ...label, DETECTED_QTY: newValue };
    //     }
    //     return label;
    //   });

    //   setUpdatedLabelsList(updatedList);

    //   // update size.DETECTED_QTY with e.value

    //   //steps
    //   // 1. get the current item from array? in teoria gia lo passa, cerco barcode in mia updatedList, per aggiornare la quantita anche li
    //   // 2. update item.DETECTED_QTY with e.value (item.DETECTED_QTY) 
    //   // 3. update size in details array
    //   // 4. rerender the component to reflect the changes
    // }, [])

    
    // const minusBtn = useMemo(()=>({
    //     icon: 'minus',
    //     stylingMode: 'text',
    //     onClick: (item) => {
    //         const newValue = item.DETECTED_QTY - 1;
    //         onValueChanged(newValue, item);
    //     }
    // }),[])

    // const plusBtn = useMemo(()=>({
    //     icon: 'plus',
    //     stylingMode: 'text',
    //     onClick: (item) => {
    //         const newValue = item.DETECTED_QTY + 1;
    //         onValueChanged(newValue, item)
    //     }
    // }),[])