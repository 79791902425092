import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ProductContext } from '../../contexts/ProductProvider';
import useTranslate from '../../utils/useTranslate';
import Cookies from 'js-cookie';
import { motion } from 'framer-motion';
import { DateBox } from 'devextreme-react/date-box';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import CardSmall from '../CardSmall';
import CardSmallTotal from '../CardSmallTotal';
import CardSmallPartition from '../CardSmallPartition';
import CardBig from '../CardBig';
import { capitalizeFirstLetter } from '../../utils/functions';
import SalesDashboardChart from './SalesDashboardChart';
// import DashCardBigByStoreTable from './DashCardBigByStoreTable';
// import DashCardBigByChannelPie from './DashCardBigByChannelPie';
import config from '../../utils/constants/config';
import SalesDashboardTable2 from './SalesDashboardTable2';
import SalesDashboardPieChart from './SalesDashboardPieChart';
import Stock from '../../api/ApiCallsStock';
import notify from 'devextreme/ui/notify';

import {LanguageContext} from '../../contexts/LanguageProvider'
import itMessages from "devextreme/localization/messages/it.json";
import enMessages from "devextreme/localization/messages/en.json"; // Optional, as en is often default
import { locale, loadMessages } from "devextreme/localization";
// import DxButtonGroup from '../DxButtonGroup';
import DropDownMenu from '../DropDownMenu';
import { formatDate } from 'devextreme/localization';
import TabSwitch from '../TabSwitch';
// import useDateHandling from '../../utils/useDateHandling';
import { format, endOfDay, endOfMonth, endOfYear, subDays, subMonths, subYears, isToday, isSameMonth, isSameYear} from 'date-fns';
import TabNavigation from '../TabNavigation';

loadMessages(itMessages);
loadMessages(enMessages);

//consts moved here to avoid re-rendering (alternatively can use useMemo if need variants internal to the component)
const formatMap = {
  day: 'dd MMM yyyy',
  month: 'MMM yyyy',
  year: 'yyyy',
}
const minZoomLevel = {
  day: 'year',
  month: 'decade',
  year: 'century',
}
const maxZoomLevel = {
  day: 'month',
  month: 'year',
  year: 'decade',
}

const timeFrameOptions = [
  {
    id: 0,
    value: 'day'
  },
  {
    id: 1,
    value:'month'
  },
  {
    id: 2,
    value: 'year'
  }
]

  
const animationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.3 }
}



function MainSalesDashboard({
  // handleTabChange, 
  handleCumulativeChange,
  filter, 
}) {

  const { stores, 
    calendarDate, 
    setCalendarDate, 
    // isCumulative, 
    dashboardStoreId, dashboardSupplierId, isGross, 
    // tab,
    timeFrame, setTimeFrame,
    //, isCurrentPeriod, setIsCurrentPeriod, 
    // thisPrevYearMonth, setThisPrevYearMonth
    // dashboardRawData, setDashboardRawData
   } = useContext(ProductContext)

  const { getDashboard4 } = Stock() // removed getDashboard2,

  
  const translate = useTranslate();
  const currency = Cookies.get('currency');
  // console.log('target:', target);
  
  const [isCurrentPeriod, setIsCurrentPeriod] = useState()
  // const [timeFrame, setTimeFrame] = useState('day');
  const now = new Date();
  const endOfYearMaxDate = new Date(now.getFullYear(), 11, 31); // user can select dates till the end of the current year, trick to avoid error message in date box when switching timeframes
  // const customFormatLabel = { 'aria-label': 'Custom Format' };
  const customDisplayFormat = formatMap[timeFrame];
  const customMinZoomLevel = minZoomLevel[timeFrame];
  const customMaxZoomLevel = maxZoomLevel[timeFrame];
  const customCalendarOptions = {
    minZoomLevel: customMinZoomLevel,
    maxZoomLevel: customMaxZoomLevel,
    firstDayOfWeek: 1
  }
  //handles calendar translation
  const {language} = useContext(LanguageContext)
  useEffect(() => {
    locale(language)
  }, [language])
  
  //moved from parent component
  const abortControllerRefDash = useRef(null);
  const [errors, setErrors] = useState({
    running: false,
    last: false,
    lastToDate: false,
    // chartData: null,
  })

  const [running, setRunning] = useState(  {
    INSTORE: null,
    ONLINE: null,
    ALL: null,
    INSTORE_QTY: null,
    ONLINE_QTY: null,
    ALL_QTY: null
  });      
  const [lastToDate, setLastToDate] = useState(  {
    INSTORE: null,
    ONLINE: null,
    ALL: null,
    INSTORE_QTY: null,
    ONLINE_QTY: null,
    ALL_QTY: null
  });
  const [last, setLast] = useState(  {
    INSTORE: null,
    ONLINE: null,
    ALL: null,
    INSTORE_QTY: null,
    ONLINE_QTY: null,
    ALL_QTY: null
  });
  const [lastDate, setLastDate] = useState();
  const [target, setTarget] = useState(null); //MOVED TO CHILD COMPOENNT
  // const [chartData, setChartData] = useState()  //datasource for chart
  const [activeRequestCount, setActiveRequestCount] = useState(0); 
  const [isLoading, setLoading] = useState(true)

  const currencyQtyOptions = useMemo(()=> [{
    id: 0,
    text: `${currency}`,
    hint: "currency"
  }, {
    id: 1,
    text: translate("Qty"),
    hint: "quantity"
  }], [currency, translate])
  const [currencyQty, setCurrencyQty] = useState(currencyQtyOptions[0].hint)
  
  const thisPrevYearMonthOptions = [             
    {id: 'current', text: translate('Compare with last month')}, //current year, last month
    {id: 'previous', text: translate('Compare with same month of last year')}, //last year, same month
  ]
  const [thisPrevYearMonth, setThisPrevYearMonth] = useState('current')

  const [dataPresentStored, setDataPresentStored] = useState();
  const [dataLastStored, setDataLastStored] = useState();
  const [dataLTDStored, setDataLTDStored] = useState();


//timeFrame handling
  const handleTimeFrameChange = useCallback((item) => {
    // handleTabChange(timeFrame)
    setTimeFrame(item.value);
    // onValueChanged()

    // console.log(timeFrame);
  }, []);

///timeframe new

  const formatDateToYMD = useCallback((date) => {
    return format(date, 'yyyy-MM-dd');
  }, []);

  const formatDateToYMDHM = useCallback((date) => {
    const now = new Date();
    date.setHours(now.getHours(), now.getMinutes());
    return format(date, "yyyy-MM-dd HH:mm");
  }, []);

  // const formatMonthYear = useCallback((date)=>{
  //   return date ? format(date, 'MMM yyyy') : ''
  // },[])

  //dont use date-fns libr bc it does not allow atomatic translation of the month
  const formatMonthYear = useCallback((date)=>{
    const data = date ? formatDate(new Date(date), 'MMM yyyy') : ''
    return data
  },[])


  const nowFormatted = formatDateToYMD(now);


  const calculateIfCurrentPeriod = useCallback((date) => {
    // console.log('calculateIfCurrentPeriod', timeFrame );
    
    switch (timeFrame) {
      case 'day':
        // console.log('ree currenPeriod day', isToday(date) && isSameMonth(date, new Date()) && isSameYear(date, new Date()));
        return isToday(date);
      case 'month':
        return isSameMonth(date, new Date()) && isSameYear(date, new Date());
      case 'year':
        return isSameYear(date, new Date());
      default:
        return false;
    }
  }, [timeFrame]);



  const calculateDatePastLTD = useCallback((calendarDate) => { //indipendent from date, calculates based on now
    // console.log('calculateDatePastLTD');
    
    const now = new Date();
    let pastDate;

    switch (timeFrame) {
      case 'day':
        pastDate = subDays(now, 1); //sub-tracts 1 to now
        break;
      case 'month':
        pastDate = thisPrevYearMonth == 'previous'
          ? subYears(now, 1)  //credo non vada end of year perch evoglio stessa day e month attuae
          : subMonths(now, 1); //credo non vada end of year perch evoglio stessa day e month attuae
        break;
      case 'year':
        pastDate = subYears(now, 1); //credo non vada end of year perch evoglio stessa day e month attuae
        break;
      default:
        pastDate = now;
    }
    return formatDateToYMDHM(pastDate);
  }, [formatDateToYMDHM, timeFrame, thisPrevYearMonth]);



  const calculateDatePast = useCallback((calendarDate) => {
    // console.log('calculateDatePast');
    
    let pastDate;

    switch (timeFrame) {
      case 'day':
        pastDate = subDays(calendarDate, 1);
        break;
      case 'month':
        // console.log('thisPrev-Month', thisPrevYearMonth);
        
        pastDate = thisPrevYearMonth === 'previous'
          ? endOfMonth(subYears(calendarDate, 1)) //qui ci va end of month perche non siam in currentdate (caso coperto dalla funzione LTD)
          : endOfMonth(subMonths(calendarDate, 1));
        break;
      case 'year':
        pastDate = endOfYear(subYears(calendarDate, 1));
        break;
      default:
        pastDate = calendarDate;
    }
    return formatDateToYMD(pastDate);
  }, [formatDateToYMD, timeFrame, thisPrevYearMonth]);



  // useEffect(()=>{
  //   setIsCurrentPeriod(calculateIfCurrentPeriod(calendarDate));
  // }, [timeFrame, calendarDate])


  
    //takes e?.value || calendar date, sets the current date to send to api
    const onValueChanged = useCallback((e) => {
      // console.log(`Selected Date: e ${e?.value}, claendarDate ${calendarDate}`);
      // let updatedDate = e ? new Date(e.value) : new Date(calendarDate);
      let updatedDate = new Date(e?.value || calendarDate);
      // console.log('data oggi', now)
        // Check if the selected date is after today, i need to format now as calendar date to delete the hours and then i need to set them as new date to compare them
        // console.log('ree upd date', updatedDate, 'nowForm', new Date(nowFormatted) );
        
      if (updatedDate >= new Date(nowFormatted)) {
        // if (new Date(updatedDate) >= now) {
        updatedDate = now; // If the date is in the future, set it to today
        // setCalendarDate(updatedDate); 
      } else {

          switch (timeFrame) { // i don't need hours and minutes as 23:59 bc if is not current period, the date will be passed as 2024-10-10 (there is not LTD whcih is the only one which uses hh mm)
            case 'day':
              updatedDate = endOfDay(updatedDate);
              break;
            case 'month':
              updatedDate = endOfMonth(updatedDate);
              break;
            case 'year':
              updatedDate = endOfYear(updatedDate);
              break;
          }
        }

        // if (timeFrame == 'month') {

        //   updatedDate = new Date(updatedDate.getFullYear(), updatedDate.getMonth() + 1, 0); // set to the last day of the selected month //setting the day to 0 of the next month gets the last day of the previous month
        // } else if (timeFrame == 'year') {
        //   updatedDate = new Date(updatedDate.getFullYear(), 11, 31); // set to 31/12 of the selected year
        // }
        // const formattedDate = updatedDate.toISOString().split('T')[0];
        const formattedDate = formatDateToYMD(updatedDate);
        // console.log(`Formatted Date: ${formattedDate}`);
        // setCalendarDate(formattedDate);
        setCalendarDate(updatedDate);
        setIsCurrentPeriod(calculateIfCurrentPeriod(updatedDate))
      // }
  
    // }
  }, [timeFrame]);  //removed calendarDate, ; timeframe is needed bc senno quando cambio da giorno a mese torna a "oggi"

  //if tab (timeframe) changes, onValueChange is triggered regardless of value change in dateBox
  useEffect(()=>{
    onValueChanged()
  }, [timeFrame]);






  // ////20240911
  // useEffect(()=>{
  //   setIsCurrentPeriod(calculateIfCurrentPeriod(new Date(calendarDate)))
  // }, [calendarDate])
  const cardSmallTotal2Subtitle1 = isCurrentPeriod ? translate('whole') + ' ' + translate(timeFrame) : '\u00A0'
  // // const cardSmallTotal2Subtitle1 = isCurrentPeriodProvider ? translate(timeFrame) : '\u00A0'



  const formattedMonthYear = useMemo(()=>{
    // console.log('formattedMonthYear', thisPrevYearMonth, lastDate);
    
    return formatMonthYear(lastDate) // use last and not LTD becausse if the period is not current then this date will be undefined 
  }, [formatMonthYear, lastDate, thisPrevYearMonth]);

//to store the date and timeframe through the session, still available upon refresh
  //on compoentn load, checks the storage, if present: setCalendardate to storage, else sets storage to nowFormatted

  

  ///////////////////////////////////////TO ADD
  //update sessionStorage when calendarDate changes
  useEffect(() => {
    sessionStorage.setItem('calendarDate', calendarDate)
  }, [calendarDate])


  //update sessionStorage when timeFrame changes
  useEffect(() => {
    sessionStorage.setItem('timeFrame', timeFrame)
  }, [timeFrame])
  ////////////////////////////////////////////



// end timeFrame handling

    //populates 2 small cards on top
  
  const calculateParagraphsData = useCallback((dataPresent, dataLast, dataLTD) => {
    const createNullObject = () => ({
      INSTORE: null,
      ONLINE: null,
      ALL: null,
      INSTORE_QTY: null,
      ONLINE_QTY: null,
      ALL_QTY: null
    });
  
    let localRunning = createNullObject();
    let localLast = createNullObject();
    let localLastToDate = createNullObject();
  
    const processData = (data, target) => {
      // if (data?.Message) {
      //   return createNullObject();
      // }
      
      let result = {
        INSTORE: 0,
        ONLINE: 0,
        ALL: 0,
        INSTORE_QTY: 0,
        ONLINE_QTY: 0,
        ALL_QTY: 0
      };
  
      data?.forEach(item => {
        if (item.DOCTYPE === "INSTORE") {
          result.INSTORE = isGross ? (item.TOTAL_GROSS || 0) : (item.TOTAL_NET || 0);
          result.INSTORE_QTY = (item.TOTAL_QTY || 0);
        } else if (item.DOCTYPE === "ONLINE") {
          result.ONLINE = isGross ? (item.TOTAL_GROSS || 0) : (item.TOTAL_NET || 0);
          result.ONLINE_QTY = (item.TOTAL_QTY || 0);
        }
      });
  
      result.ALL = (result.INSTORE || 0) + (result.ONLINE || 0);
      result.ALL_QTY = (result.INSTORE_QTY || 0) + (result.ONLINE_QTY || 0);
  
      return result;
    };
  
    localRunning = processData(dataPresent);
    localLast = processData(dataLast);
    localLastToDate = processData(dataLTD);

    setRunning(localRunning);
    setLastToDate(localLastToDate);
    setLast(localLast);   
    // console.log('localRunning:' , localRunning );
    // console.log('localLast:' , localLast );
    // console.log('localLastToDate:' , localLastToDate );
     
    // setTarget(localLastToDate?.ALL == 0 ? 0 : (localRunning?.ALL * 100 / localLastToDate?.ALL) - 100);  
    if (isCurrentPeriod){
      // setTarget(localLastToDate?.ALL == 0 ? 0 : ((localRunning?.ALL - localLastToDate?.ALL)/localLastToDate.ALL) *100)
      setTarget(localLastToDate?.ALL == null ? null : (localRunning?.ALL == null ? null : (localLastToDate?.ALL == 0 ? 0 : ((localRunning?.ALL - localLastToDate?.ALL) / localLastToDate.ALL) * 100)));
    } else {
      // setTarget(localLast?.ALL == 0 ? 0 : ((localRunning?.ALL - localLast?.ALL)/localLast.ALL) *100);  
      setTarget(localLast?.ALL == null ? null : (localRunning?.ALL == null ? null : (localLast?.ALL == 0 ? 0 : ((localRunning?.ALL - localLast?.ALL) / localLast.ALL) * 100)));
    }
  }, [isCurrentPeriod, isGross])

  
  
  const dashboard = useCallback(async (period) => {
    setActiveRequestCount(prevCount => prevCount + 1); //keep trak of aborted calls to set loader properly
    setLoading(true)
    setErrors({
      running: false,
      last: false,
      lastToDate: false,
      // chartData: null,
    })

    // const optionalDate = calendarDate ? formatDateToYMD(calendarDate) : ''
    // const optionalDatePast = calculateDatePast(optionalDate, thisPrevYearMonth)
    // const optionalDatePastLTD = isCurrentPeriod ? calculateDatePastLTD(optionalDate, thisPrevYearMonth) : ''
    // setLastDate(optionalDatePast)


    const currentCalendarDate = calendarDate; // Get the latest calendarDate
    const optionalDate = currentCalendarDate ? formatDateToYMD(currentCalendarDate) : '';
    // const optionalDate = currentCalendarDate || '';
    const optionalDatePast = calculateDatePast(currentCalendarDate);
    const optionalDatePastLTD = isCurrentPeriod ? calculateDatePastLTD(currentCalendarDate) : '';
    setLastDate(optionalDatePast);

    // console.log('isCurrentPeriod', isCurrentPeriod);
    
    // console.log('ree currentDate', optionalDate);
    // console.log('ree previousDate', optionalDatePast);
    // console.log('ree previousDateLTD', optionalDatePastLTD);
    

    try {
      if (abortControllerRefDash.current) {
        abortControllerRefDash.current.abort();
      }
      abortControllerRefDash.current = new AbortController();
      const signal = abortControllerRefDash.current.signal;

      // console.log('period:', period, optionalDate)
      const docType = 'DIVIDED'
    //   let data = await getDashboard2(`{
    //     "$id": "1",
    //     "PERIOD": "${period}",
    //     "DOCTYPE": "${docType}",
    //     "ID_STORE": "${dashboardShopId}",
    //     "ID_SUPPLIER": "${dashboardSupplierId}",
    //     "ID_SALE_CHANNEL": "00000000-0000-0000-0000-000000000000",
    //     "TO_DATE": "${optionalDate}"
    // }`, signal)

  //OLD API CALL
      // let data = await getDashboard2(`{"$id":"1","PERIOD":"${period}","DOCTYPE":"${docType}","ID_STORE":"${dashboardShopId}","ID_SUPPLIER":"${dashboardSupplierId}","ID_SALE_CHANNEL":"00000000-0000-0000-0000-000000000000","TO_DATE":"${optionalDate}"}`,signal)

  //NEW API CALL
      
  //MOVED TO CHILD
      // let { success: successDivided, data: dataDivided, error: errorDivided } = await getDashboard4(`{"PERIOD":"${period}","ID_STORE":"${dashboardShopId}","ID_SUPPLIER":"${dashboardSupplierId}","ID_SALE_CHANNEL":"00000000-0000-0000-0000-000000000000","DOCTYPE":"${docType}","TO_DATE":"${optionalDate}","PARTITION_BY":"PERIOD"}`,signal)

      

      //DOCTYPE     ("ALL" tutti i dati assieme,"DIVIDED" tutti i dati divisi ,"INSTORE","ONLINE") 
      //PARTITION_BY  ("" tutti i dati assieme,"PERIOD" divisi per frazione del periodo ,"STORE","SALE_CHANNEL")     
      let { success: successPresent, data: dataPresent, error: errorPresent } = await getDashboard4(`{"PERIOD":"${period}","ID_STORE":"${dashboardStoreId}","ID_SUPPLIER":"${dashboardSupplierId}","ID_SALE_CHANNEL":"00000000-0000-0000-0000-000000000000","DOCTYPE":"${docType}","TO_DATE":"${optionalDate}","PARTITION_BY":""}`,signal)

      let { success: successLast, data: dataLast, error: errorLast } = await getDashboard4(`{"PERIOD":"${period}","ID_STORE":"${dashboardStoreId}","ID_SUPPLIER":"${dashboardSupplierId}","ID_SALE_CHANNEL":"00000000-0000-0000-0000-000000000000","DOCTYPE":"${docType}","TO_DATE":"${optionalDatePast}","PARTITION_BY":""}`,signal)

      let dataLTD = []
      let dataLTDobj = { success: true, data: [], error: null };
      if(isCurrentPeriod && optionalDatePastLTD){
        // console.log('isCurent data', isCurrentPeriod);
        // console.log(('optionalDatePastLTD', optionalDatePastLTD ));       
        dataLTDobj = await getDashboard4(`{"PERIOD":"${period}","ID_STORE":"${dashboardStoreId}","ID_SUPPLIER":"${dashboardSupplierId}","ID_SALE_CHANNEL":"00000000-0000-0000-0000-000000000000","DOCTYPE":"${docType}","TO_DATE":"${optionalDatePastLTD}","PARTITION_BY":""}`,signal)
        
        dataLTD = dataLTDobj?.data
        // console.log('obj', dataLTDobj);
        
        // if(dataLTDobj?.success){
          setDataLTDStored(dataLTD)
    //todo understand why this code is run before resolution of api call (3 times)
        // } else {
        //   setErrors( prev => ({...prev, 
        //     lastToDate: true,
        //     // lastToDate: dataLTD.error || 'Failed to fetch last-to-date data',
        //   }));        
        // }
      }

      // if(successPresent && successLast){
        setDataPresentStored(dataPresent)
        setDataLastStored(dataLast)

      // setDashboardRawData(dataDivided)

      // LUCA: nuovo parametro opzionale TO_DATE che permette di personalizzare la data di riferimento.
      // Per visualizzare correttamente i dati di un mese/anno passato andrebbe settato sull'ultimo giorno di quel periodo (es. 31/12 o 31/07, 30/05, ...)
      
      //PARAGHRAPS
        calculateParagraphsData(dataPresent, dataLast, dataLTD)


  //CHART
      // calculateChartData(dataDivided);
      // setChartCommonSeriesSettings(part3)
      // } else if (!successPresent) {
      //   setErrors(prev => ({...prev, 
      //     running: true
      //   }))
      //   //   prev => ({...prev, 
      //   //   running: errorPresent?.message || 'Failed to fetch current data',
      //   //   last: errorLast?.message || 'Failed to fetch last period data',
      //   //   // lastToDate: dataLTD.error || 'Failed to fetch last-to-date data',
      //   //   // chartData: errorDivided || 'Failed to fetch chart data',
      //   // }));
      // } else if (!successLast) {
      //   setErrors(prev =>({...prev,
      //     last: true
      //   }))
      // }
    } catch (error) {
      // console.log('Request canceled dash1 PROVIDER ERR', error.name);
      if (!error.name === 'CanceledError') {  //if api call not aborted 
        notify(translate(error.message) || translate('Error'), 'error', 3000);
        // setErrors({
        //   running: true,
        //   last: true,
        //   lastToDate: true
        // });
      }
    } finally {
      setActiveRequestCount(prevCount => prevCount - 1);
      // setErrors({
      //   running: dataPresentStored ? false : true,
      //   last: dataLastStored ? false : true,
      //   lastToDate: dataLTDStored ? false : true
      // })
    }
  // }, [calculateDatePast, calculateDatePastLTD, formatDateToYMD, isCurrentPeriod, getDashboard4, calculateParagraphsData]);
  },[calculateDatePast, calculateDatePastLTD, calendarDate, timeFrame, dashboardStoreId, dashboardSupplierId]);
  
  //removed getDashboard2, calculateChartData
// },[calendarDate, dashboardShopId, dashboardSupplierId, setDashboardRawData]); //removed getDashboard2, calculateChartData



  useEffect(() => {
    setLoading(activeRequestCount > 0);
  }, [activeRequestCount])

  // if after loading is set to false, data is not populated, then set the error
  useEffect(() =>{
    if(!isLoading) setErrors({
      running: dataPresentStored ? false : true,
      last: dataLastStored ? false : true,
      lastToDate: dataLTDStored ? false : true
    })
  }, [dataLTDStored, dataLastStored, dataPresentStored, isLoading])


  useEffect(() => {  
    // console.log('useEffect dash ID', dashboardSupplierId, dashboardStoreId);

    if (dashboardSupplierId && dashboardStoreId) {
      // console.log('useEffect dash');
      
      dashboard(timeFrame); // executes funct with updated value
    }
  }, [dashboardSupplierId, dashboardStoreId, timeFrame, calendarDate, thisPrevYearMonth]); //removed docType , isCumulative, isGross, dashboard, filter ( bc function is already triggered when supplID or storeID change, gross is handled downstream)

useEffect(() => {
  calculateParagraphsData(dataPresentStored, dataLastStored, dataLTDStored)
}, [isGross])



  //handle isCumulative change to affect only the dash main graph
  // useEffect(() => {
  //   calculateChartData(dashboardRawData);
  // }, [isCumulative])

  
  return (
    <>
      <motion.div 
        className="scroll-view dash-bg"
        {...animationProps}

        // initial={{ opacity: 0 }}   
        // animate={{ opacity: 1 }}   
        // exit={{ opacity: 0 }}     
        // transition={{ duration: 0.3 }}   
      >
        <div id='dashboard-container' className='inside-scroll-view'>

          <div className='d-flex justify-content-between align-items-baseline w-100 px-2'>
            <div className='tab d-flex'>
              <TabNavigation dataSource={timeFrameOptions} onItemClick={handleTimeFrameChange} selectedValue={timeFrame} fontSize='fs-12' />
            </div>
            <div className='ms-3'>
              <DateBox
                className='border-bottom-0'
                value={calendarDate}
                // defaultValue={nowFormatted}
                defaultValue={now}
                // max={endOfYearMaxDate}
                max={now}
                // inputAttr={customFormatLabel}
                displayFormat={customDisplayFormat}
                onValueChanged={onValueChanged}
                width={'120px'}
                height={'35px'}
                calendarOptions={customCalendarOptions}
                acceptCustomValue={false}
                pickerType='calendar'
                openOnFieldClick={true}
              />
            </div>
          </div>



          <div className="d-flex justify-content-between flex-wrap">  {/* ex compact class */}
          <CardSmall 
            className='card-small-dash me-3'
            // title={timeFrame == 'day' ? `${translate('Today')} (${currency})` : `${translate(`Current ${timeFrame}`)} (${currency})`} 
            title={timeFrame == 'day' ? `${translate('Today')}` : `${translate(`Current ${timeFrame}`)}`} 
            // percentage={Number(target?.toFixed(0))}
            percentage={target}
            isLoading={isLoading}
            addFormat='percentToFixed0'
            

            // icon={`${running >= 0 ? 'arrowup' : 'arrowdown' }`} 
            // value={Intl.NumberFormat("it-IT").format(running.toFixed(0))} 
            >
              {isLoading ? (
              <LoadIndicator />
              ) : (
              <CardSmallTotal
                addFormat='euFormatToFixed0'
                fwNormal={false}
                // value={Number(running?.ALL?.toFixed(0))} 
                value={running?.ALL} 
                subtitle={'\u00A0'}  // using non-breaking space to keep elements aligned between 2 cards
                // totalQty={Number(running?.ALL_QTY?.toFixed(0))}
                totalQty={running?.ALL_QTY}
                error500={errors.running}
                >
                <CardSmallPartition
                                addFormat='euFormatToFixed0'

                  // instore={Number(running?.INSTORE?.toFixed(0))}
                  // online={Number(running?.ONLINE?.toFixed(0))}
                  // instoreQty={Number(running?.INSTORE_QTY?.toFixed(0))}
                  // onlineQty={Number(running?.ONLINE_QTY?.toFixed(0))}
                  instore={running?.INSTORE}
                  online={running?.ONLINE}
                  instoreQty={running?.INSTORE_QTY}
                  onlineQty={running?.ONLINE_QTY}
                  error500={errors.running}
                  />
              </CardSmallTotal>
              )}
          </CardSmall>

          <CardSmall 
            className='card-small-dash ms-3'
            // title={timeFrame == 'day' ? translate('Yesterday') : translate(`Last ${timeFrame}`)} 
            title={
              timeFrame == 'day' 
              // ? `${translate('Yesterday')} (${currency})` 
              ? `${translate('Yesterday')}` 
              : timeFrame == 'month' 
                // ? `${translate(`Last month`)} (${currency}) - ${formattedMonthYear}`
                ? `${translate(`Last month`)} - ${formattedMonthYear}`
                // : `${translate(`Last ${timeFrame}`)} (${currency})`
                : `${translate(`Last ${timeFrame}`)}`
            } 
            // additionalHeaderContent={
            //   timeFrame == 'month' 
            //   ? (
            //   <DxButtonGroup 
            //     options={thisPrevYearMonthOptions} 
            //     setValue={setThisPrevYearMonth} 
            //     defaultValue={'current'}
            //   />
            //   ) : undefined // ensures the prop is not passed at all if the condition is false
            // }
            menu={
              timeFrame == 'month' 
              ? (
              <DropDownMenu 
                menuItems={thisPrevYearMonthOptions}
                setValue={setThisPrevYearMonth} 
              />
               ) : undefined}

            // menuVisible={timeFrame == 'month' ? true : false}
            // menuSetOption={setThisPrevYearMonth}



            // icon={`${lastToDate >= 0 ? 'arrowup' : 'arrowdown' }`}
            // 'datatrending' 
          >
            {/* <div className='d-flex flex-wrap just justify-content-between'> */}
            {isLoading ? (
              <LoadIndicator />
              ) : (
            <>
              {isCurrentPeriod && (
              <CardSmallTotal
                addFormat='euFormatToFixed0'
                fwNormal={true}
                // value={Number(lastToDate?.ALL?.toFixed(0))}
                value={lastToDate?.ALL}

                //TODO
                // subtitle={ timeFrame == 'day' ? translate('to this time') : translate('to date')}     //TODO : uncomment when the call actually gives back the partition by hour
                subtitle={translate('to date')}

                
                // totalQty={Number(lastToDate?.ALL_QTY?.toFixed(0))}
                totalQty={lastToDate?.ALL_QTY}
                error500={errors.lastToDate}
                >
                <CardSmallPartition
                                addFormat='euFormatToFixed0'

                  // instore={Number(lastToDate?.INSTORE?.toFixed(0))}
                  // online={Number(lastToDate?.ONLINE?.toFixed(0))}
                  // instoreQty={Number(lastToDate?.INSTORE_QTY?.toFixed(0))}
                  // onlineQty={Number(lastToDate?.ONLINE_QTY?.toFixed(0))}

                  instore={lastToDate?.INSTORE}
                  online={lastToDate?.ONLINE}
                  instoreQty={lastToDate?.INSTORE_QTY}
                  onlineQty={lastToDate?.ONLINE_QTY}
                  error500={errors.lastToDate}
                  />
              </CardSmallTotal>
              )}

            <CardSmallTotal
                addFormat='euFormatToFixed0'
              fwNormal={true}
              // value={Number(last?.ALL?.toFixed(0))}
              value={last?.ALL}
              // subtitle={translate(timeFrame)}
              subtitle={cardSmallTotal2Subtitle1}
              // totalQty={Number(last?.ALL_QTY?.toFixed(0))}
              totalQty={last?.ALL_QTY}
              error500={errors.last}
              >
              <CardSmallPartition
                              addFormat='euFormatToFixed0'

                              // instore={Number(last?.INSTORE?.toFixed(0))}
                              // online={Number(last?.ONLINE?.toFixed(0))}
                              // instoreQty={Number(last?.INSTORE_QTY?.toFixed(0))}
                              // onlineQty={Number(last?.ONLINE_QTY?.toFixed(0))}
                instore={last?.INSTORE}
                online={last?.ONLINE}
                instoreQty={last?.INSTORE_QTY}
                onlineQty={last?.ONLINE_QTY}
                error500={errors.last}
                />
            </CardSmallTotal>
              

            </>
          )}
            {/* </div> */}

          </CardSmall>
          </div>

          <CardBig 
            title={translate(`${capitalizeFirstLetter(timeFrame)} sales`)}
            heightCustom={'270px'}
            //optionsVisible={true}
            //optionsText={translate('Cumulative')}
            // isLoading={isLoadingChart}
            info={true}
            infoText={translate('Click on the legend to show and hide the series')}
            infoPopID='infoPop1'
            >
            {/* {chartData && */}
            <SalesDashboardChart 
              filter={filter}
              // setLoadingChart={setLoadingChart}
              timeFrame={timeFrame}
              formatDateToYMD={formatDateToYMD}
              // docType={docType} 
              // chartData={chartData}
              // isCumulative={isCumulative}
              // chartCommonSeriesSettings={chartCommonSeriesSettings} 
            />
            {/* } */}
           </CardBig>



          {/* shops > 2 bc we insert "ALL" as first voice of the list, hence if there is 1 shop it will be ALL + SHOP1 = 2 */}


          {(filter?.store == config.all && stores?.length > 2) &&
          (
            // <DashCardBigByShopsTable />
          <CardBig
            title={translate('Sales in store')}
            additionalHeaderContent={
            <TabSwitch 
              options={currencyQtyOptions}
              setValue={setCurrencyQty} 
              selectedItem={currencyQty}
              width={40}
              display='text'
            />
            }
            //             {/* <DxButtonGroup 
            //   options={currencyQtyOptions} 
            //   setValue={setCurrencyQty} 
            //   defaultValue={'currency'}
            // />
            // */}
          >
            <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
              <div className='dash-table-container'>
              <SalesDashboardTable2 
                filter={filter} 
                isCurrentPeriod={isCurrentPeriod}
                calculateDatePastLTD={calculateDatePastLTD}
                calculateDatePast={calculateDatePast}
                formatDateToYMD={formatDateToYMD}
                currencyQty={currencyQty}
                /> 
              </div>
            </div>
          </CardBig>
          )}
          <CardBig
            title={translate('Sales by channel')}
            heightCustom={'auto'}
            info={true}
            infoText={translate('Click on the legend to show and hide the series')}
            infoPopID='infoPop2'
          >
            <SalesDashboardPieChart filter={filter} formatDateToYMD={formatDateToYMD}/>
          </CardBig>
        </div>
      </motion.div>


    </>
  )
}

export default MainSalesDashboard





// substituted handmade tab navigation with general component
              {/* {timeFrameOptions.map((option, index) => (
                <div 
                  key={index}
                  onClick={()=>handleTimeFrameChange(option)} className={option === timeFrame ? 'tab-selected' : '' }><p className='m-0 position-relative d-flex align-items-center justify-content-center cursor-pointer'>
                    {translate(option)}</p>
                  {option === timeFrame && (
                    <div className="tab-selected-bottom-el position-absolute bottom-0 start-50 translate-middle-x rounded-top transition bg-light-blue w-100"
                    style={{
                      // Debug styles
                      height: '3px', 
                      backgroundColor: 'red', // Temporary to verify positioning
                      zIndex: 10 
                    }}
                     />
                  )}
                </div>
                  
                ))
              } */}
              {/* <div onClick={()=>handleTimeFrameChange('day')} className={timeFrame === 'day' ? 'tab-selected' : '' }><p className='m-0'>{translate('day')}</p></div>
              <div onClick={()=>handleTimeFrameChange('month')} className={timeFrame === 'month' ? 'tab-selected' : '' }><p className='m-0'>{translate('month')}</p></div>
              <div onClick={()=>handleTimeFrameChange('year')} className={timeFrame === 'year' ? 'tab-selected' : '' }><p className='m-0'>{translate('year')}</p></div> */}







  // const calculateDatePastLTD = useCallback(() => {
  //   let newDate = new Date();
  //   switch (timeFrame) {
  //     case 'day':
  //       newDate.setDate(newDate.getDate() - 1);
  //       break;
  //     case 'month':
  //       if (newDate.getMonth() === 0) {
  //         newDate.setFullYear(newDate.getFullYear() - 1); // If it's January, set to December of previous year
  //         newDate.setMonth(11);
  //         break;
  //       } else {
  //         newDate.setMonth(newDate.getMonth() - 1); // Otherwise, just move to the previous month
  //         break;
  //       }
  //     case 'year':
  //       newDate = new Date(newDate.getFullYear() - 1, newDate.getMonth(), newDate.getDate());
  //       break;
  //     default:
  //       newDate
  //   }
  //   return formatDateToYMDHM(newDate)
  // },[formatDateToYMDHM, timeFrame])

  
  // const calculateDatePast = useCallback(() => {
  //   let newDate = new Date(calendarDate);
  //   switch (timeFrame) {
  //     case 'day':
  //       newDate.setDate(newDate.getDate() - 1);
  //       break;
  //     case 'month':
  //       if (newDate.getMonth() === 0) {
  //         newDate.setFullYear(newDate.getFullYear() - 1);
  //         newDate.setMonth(11);
  //       } else {
  //         // Otherwise, just move to the previous month
  //         // newDate.setMonth(newDate.getMonth() - 1);
  //         newDate = new Date(newDate.getFullYear(), newDate.getMonth(), 0)
  //         // console.log('calendar:', calendarDate, 'past:', newDate)
  //       }
  //       break;
  //     case 'year':
  //       newDate = new Date(newDate.getFullYear() -1, 11, 31)
  //       // newDate.setFullYear(newDate.getFullYear() - 1);
  //       break;
  //     default:
  //       newDate
  //   }
  //   return formatDateToYMD(newDate);
  // }, [calendarDate, formatDateToYMD, timeFrame])

















  //web04 needs all dates given by hand
  // const calculateDatePastLTD = useCallback(() => {
  //   let newDate = new Date();   
  //   switch (timeFrame) {
  //     case 'day':
  //       newDate.setDate(newDate.getDate() - 1);
  //       // newDate.setHours(newDate.getHours())
  //       // newDate.setMinutes(newDate.getMinutes())
  //       break;
  //     case 'month':
  //       if (newDate.getMonth() === 0) { // If it's January, set to December of previous year
  //         // newDate.setFullYear(newDate.getFullYear() - 1); 
  //             const year = thisPrevYearMonth == 'previous' ? (newDate.getFullYear() -2) : (newDate.getFullYear() -1);
  //             newDate.setFullYear(year);
  //             newDate.setMonth(11);
  //         break;
  //       } else {
  //         const year = thisPrevYearMonth == 'previous' ? (newDate.getFullYear() -1) : (newDate.getFullYear());
  //         newDate.setFullYear(year);
  //         // newDate.setMonth(newDate.getMonth() - 1); // Otherwise, just move to the previous month
  //         const month = thisPrevYearMonth == 'previous' ? (newDate.getMonth()) : (newDate.getMonth() - 1)
  //         newDate.setMonth(month);
  //         break;
  //       }
  //     case 'year':
  //       newDate = new Date(newDate.getFullYear() - 1, newDate.getMonth(), newDate.getDate());
  //       break;
  //     default:
  //       newDate
  //   }        
  //   return formatDateToYMDHM(newDate)
  // },[formatDateToYMDHM, thisPrevYearMonth, timeFrame]) 

  
  // const calculateDatePast = useCallback(() => {
  //   let newDate = new Date(calendarDate);
  //   switch (timeFrame) {
  //     case 'day':
  //       newDate.setDate(newDate.getDate() - 1);
  //       break;
  //     case 'month':
  //       if (newDate.getMonth() === 0) {
  //         // newDate.setFullYear(newDate.getFullYear() - 1);
  //         const year = thisPrevYearMonth == 'previous' ? (newDate.getFullYear() -2) : (newDate.getFullYear() -1);
  //         newDate.setFullYear(year);
  //         newDate.setMonth(11);
  //       } else {
  //         // Otherwise, just move to the previous month
  //         // newDate.setMonth(newDate.getMonth() - 1);
  //         // newDate = new Date(newDate.getFullYear(), newDate.getMonth(), 0)        
  //         newDate = new Date(
  //           thisPrevYearMonth == 'previous' ? (newDate.getFullYear() -1) : (newDate.getFullYear()), 
  //           thisPrevYearMonth == 'previous' ? (newDate.getMonth() +1) : (newDate.getMonth()),
  //            0)
  //         // const month = thisPrevYearMonth == 'previous' ? (newDate.getMonth()) : (newDate.getMonth() - 1)
  //         // newDate.setMonth(month);
  //       }
  //       break;
  //     case 'year':
  //       newDate = new Date(newDate.getFullYear() -1, 11, 31)
  //       // newDate.setFullYear(newDate.getFullYear() - 1);
  //       break;
  //     default:
  //       newDate
  //   }
  //   return formatDateToYMD(newDate);
  // }, [calendarDate, formatDateToYMD, thisPrevYearMonth, timeFrame])

  /////fine 20240911


  // const formatMonthYear = useCallback((date)=>{
  //   const data = date ? formatDate(new Date(date), 'MMM yyyy') : ''
  //   return data
  // },[])






//////////////////////MOVED OUT AFTER INFINITE LOOP ONVALUECHANGE AND ISCURRENT PERIOD


  // const isToday = useCallback((date) => {
  //   const today = new Date();
  //   return date.getDate() === today.getDate() &&
  //          date.getMonth() === today.getMonth() &&
  //          date.getFullYear() === today.getFullYear();
  // }, []);

  // const isCurrentMonth = useCallback((date) => {
  //   const today = new Date();
  //   return date.getMonth() === today.getMonth() &&
  //          date.getFullYear() === today.getFullYear();
  // }, []);

  // const isCurrentYear = useCallback((date) => {
  //   const today = new Date();
  //   return date.getFullYear() === today.getFullYear();
  // }, []);

  // //true if timeFrame=day and isToday, else if timefreme is month and isCurrentMonth, else if timefreme is year and isCurrentYear
  // const calculateIfCurrentPeriod = useCallback((date) => {
  //   if (timeFrame === 'day') {
  //     return isToday(date); //true if timeframe= today and is oday of the date
  //   } else if (timeFrame === 'month') {
  //     return isCurrentMonth(date);
  //   } else if (timeFrame === 'year') {
  //     return isCurrentYear(date);
  //   }
  //   return false; // Default case if timeframe doesn't match
  // }, [timeFrame, isToday, isCurrentMonth, isCurrentYear]);


  

  // const onValueChanged = useCallback((e) => {
  //     // console.log(`Selected Date: e ${e?.value}, claendarDate ${calendarDate}`);
  //     // let updatedDate = e ? new Date(e.value) : new Date(calendarDate);
  //     let updatedDate = new Date(e?.value || calendarDate);
  //     // const currentDate = new Date();
  //     // console.log('data oggi', now)
  //       // Check if the selected date is after today
  //     // if (new Date(updatedDate) >= now) {
  //       if (updatedDate >= now) {
  //       updatedDate = now; // If the date is in the future, set it to today
  //       // setCalendarDate(updatedDate); 
  //     } else {
  //       if (timeFrame === 'month') {
  //         updatedDate = new Date(updatedDate.getFullYear(), updatedDate.getMonth() + 1, 0); // set to the last day of the selected month //setting the day to 0 of the next month gets the last day of the previous month
  //       } else if (timeFrame === 'year') {
  //         updatedDate = new Date(updatedDate.getFullYear(), 11, 31); // set to 31/12 of the selected year
  //       }
  //       // const formattedDate = updatedDate.toISOString().split('T')[0];
  //       const formattedDate = formatDateToYMD(updatedDate);
  //       // console.log(`Formatted Date: ${formattedDate}`);
  //       setCalendarDate(formattedDate);
  //       setIsCurrentPeriod(calculateIfCurrentPeriod(updatedDate))
  //     }
  
  //   // }
  // }, [timeFrame]);  //removed calendarDate, timeframe

  //DO NOT TOUCH if tab (timeframe) changes, onValueChange is triggered regardless of value change in dateBox





      // const onValueChanged = useCallback((e) => {
    //   console.log('onValueChanged');
      
    //   const now = new Date();
    //   let updatedDate = new Date(e?.value || calendarDate);
    //   // console.log('ree now', now, 'ree updatedDate', updatedDate);
      
  
    //   if (updatedDate > now) {
    //     updatedDate = now;
    //   // } else {
    //   //     if(isCurrentPeriod) {
    //   //         updatedDate = now
    //       } else {
    //           switch (timeFrame) { // i don't need hours and minutes as 23:59 bc if is not current period, the date will be passed as 2024-10-10 (there is not LTD whcih is the only one which uses hh mm)
    //             case 'day':
    //               updatedDate = endOfDay(updatedDate);
    //               break;
    //             case 'month':
    //               updatedDate = endOfMonth(updatedDate);
    //               break;
    //             case 'year':
    //               updatedDate = endOfYear(updatedDate);
    //               // console.log('ree updatedDateYear', updatedDate);
    //               break;
    //           }
    //       }
    //   // }
    //   // console.log('ree onValueChange date', updatedDate);
      
    //   setCalendarDate(updatedDate); //full date, not formatted 
    //   setIsCurrentPeriod(calculateIfCurrentPeriod(updatedDate));
    // }, [timeFrame]);
  
  
  
  
  // useEffect(()=>{
  //   onValueChanged()
  // }, [timeFrame]);

  // const {
  //   // formattedCalendarDate,
  //   // calendarDate,
  //   // setCalendarDate,
  //   // timeFrame,
  //   // setTimeFrame,
  //   // isCurrentPeriod,
  //   // thisPrevYearMonth,
  //   // setThisPrevYearMonth,
  //   // onValueChanged,
  //   calculateDatePastLTD,
  //   calculateDatePast,
  //   formatDateToYMD,
  //   // formatDateToYMDHM,
  //   formatMonthYear
  // } = useDateHandling();


//////////////////////////////////////////////////



  // const thisPrevYearMonthOptions = useMemo(()=> [{
  //   text: translate('Current year'),
  //   hint: "current"
  // }, {
  //   text: translate('Last year'),
  //   hint: "previous"
  // }], [translate])
  // const [thisPrevYearMonth, setThisPrevYear] = useState(thisPrevYearOptions[0].hint)


//MOVED TO CHILD COMPONENT SalesDashboardChart
    // // populate the chart based on isCumulative and isGross
    // const calculateChartData = (data) => {
    //   // let part1 = isCumulative ? 'RUNNING' : 'TOTAL';
    //   let part1 = 'TOTAL';
    //   let part2 = isGross ? 'GROSS' : 'NET';
    //   // let part3 = isCumulative ? 'area' : 'stackedbar' //moved to the child component, not needed here
    //   const valueField = `${part1}_${part2}`
    //   const selectedMonth = new Date(calendarDate).getMonth() +1     
    //   const selectedYear = new Date(calendarDate).getFullYear()

    //   //process data so that each obj has the fields interval, instore, online and is correctly populated
    //   // const transformed = [];

    //   let intervalNumber;
    //   if (timeFrame === 'day') {
    //     intervalNumber = 24;
    //   } else if (timeFrame === 'month') {
    //     intervalNumber = new Date(selectedYear, selectedMonth, 0).getDate(); // Days in month
    //   } else if (timeFrame === 'year') {
    //     intervalNumber = 12;
    //   }

    //   const transformed = Array.from({ length: intervalNumber }, (_, index) => ({
    //     INTERVAL: timeFrame === 'day' ? index : index + 1, // For days, use index (0-23), else (1-31) or (1-12)
    //     INSTORE: 0,
    //     ONLINE: 0
    //   }));

    //   data.forEach(item => {
    //     const intervalIndex = item.INTERVAL - (timeFrame === 'day' ? 0 : 1); // Adjust for 1-based indexing in non-day timeframes
    //     if (transformed[intervalIndex]) {
    //       transformed[intervalIndex][item.DOCTYPE] = item[valueField];
    //     }
    //   });
    //   setChartData(transformed)
    // }





            // let localRunning = {
        //   INSTORE: 0,
        //   ONLINE: 0,
        //   ALL: 0,
        //   INSTORE_QTY: 0,
        //   ONLINE_QTY: 0,
        //   ALL_QTY: 0
        // };            
        // dataPresent.forEach(data => {
        //   if (data.DOCTYPE === "INSTORE") {
        //     localRunning.INSTORE = isGross ? data.TOTAL_GROSS : data.TOTAL_NET;
        //     localRunning.INSTORE_QTY = data.TOTAL_QTY;
        //   } else if (data.DOCTYPE === "ONLINE") {
        //     localRunning.ONLINE = isGross ? data.TOTAL_GROSS : data.TOTAL_NET;
        //     localRunning.ONLINE_QTY = data.TOTAL_QTY;
        //   }
        // });
        // localRunning.ALL = (localRunning.INSTORE || 0) + (localRunning.ONLINE || 0);
        // localRunning.ALL_QTY = (localRunning.INSTORE_QTY || 0) + (localRunning.ONLINE_QTY || 0);      

        // //problema quando la data è corrente
        // let localLast = {
        //   INSTORE: 0,
        //   ONLINE: 0,
        //   ALL: 0,
        //   INSTORE_QTY: 0,
        //   ONLINE_QTY: 0,
        //   ALL_QTY: 0
        // };            
        // dataLast.forEach(data => {
        //   if (data.DOCTYPE === "INSTORE") {
        //     localLast.INSTORE = isGross ? data.TOTAL_GROSS : data.TOTAL_NET;
        //     localLast.INSTORE_QTY = data.TOTAL_QTY;
        //   } else if (data.DOCTYPE === "ONLINE") {
        //     localLast.ONLINE = isGross ? data.TOTAL_GROSS : data.TOTAL_NET;
        //     localLast.ONLINE_QTY = data.TOTAL_QTY;
        //   }
        // });
        // localLast.ALL = (localLast.INSTORE || 0) + (localLast.ONLINE || 0);
        // localLast.ALL_QTY = (localLast.INSTORE_QTY || 0) + (localLast.ONLINE_QTY || 0);      

        // let localLastToDate = {
        //   INSTORE: 0,
        //   ONLINE: 0,
        //   ALL: 0,
        //   INSTORE_QTY: 0,
        //   ONLINE_QTY: 0,
        //   ALL_QTY: 0
        // };            
        // dataLTD.forEach(data => {
        //   if (data.DOCTYPE === "INSTORE") {
        //     localLastToDate.INSTORE = isGross ? data.TOTAL_GROSS : data.TOTAL_NET;
        //     localLastToDate.INSTORE_QTY = data.TOTAL_QTY;
        //   } else if (data.DOCTYPE === "ONLINE") {
        //     localLastToDate.ONLINE = isGross ? data.TOTAL_GROSS : data.TOTAL_NET;
        //     localLastToDate.ONLINE_QTY = data.TOTAL_QTY;
        //   }
        // });
        // localLastToDate.ALL = (localLastToDate.INSTORE || 0) + (localLastToDate.ONLINE || 0);
        // localLastToDate.ALL_QTY = (localLastToDate.INSTORE_QTY || 0) + (localLastToDate.ONLINE_QTY || 0);      


        // setRunning(localRunning);
        // setLastToDate(localLastToDate);
        // setLast(localLast); 
        
        
        // // setTarget(localLastToDate?.ALL == 0 ? 0 : (localRunning?.ALL * 100 / localLastToDate?.ALL) - 100);  
        // if (isCurrentPeriod){
        //   setTarget(localLastToDate?.ALL == 0 ? 0 : ((localRunning?.ALL - localLastToDate?.ALL)/localLastToDate.ALL) *100)
        // } else {
        //   setTarget(localLast?.ALL == 0 ? 0 : ((localRunning?.ALL - localLast?.ALL)/localLast.ALL) *100);  
        // }

//MOVED TO INITIAL SETSTATE, in productProvider <-- on compoentn load, checks the storage, if present: setTimeframe to storage, else sets storage to defaultTimeFrame

  // useEffect(() => {
  //   const storedDate = sessionStorage.getItem('calendarDate');
  //   if (storedDate){
  //     setCalendarDate(storedDate); //is already formatted
  //   } else  {
  //     sessionStorage.setItem('calendarDate', calendarDate)
  //   }
  // }, []); // removed nowFormatted, setCalendarDate


  //MOVED TO INITIAL SETSTATE, in productProvider <-- on compoentn load, checks the storage, if present: setTimeframe to storage, else sets storage to defaultTimeFrame
  // useEffect(() => {
  //   const storedTimeFrame = sessionStorage.getItem('timeFrame');
  //   if (storedTimeFrame){
  //     setTimeFrame(storedTimeFrame); //is already formatted
  //   } else  {
  //     sessionStorage.setItem('timeFrame', timeFrame) //onComponentMount is 'day'
  //   }
  // }, []); //emty bc only onMount,also else it goes in loop

  







//20240924 refactoring code



    ///////////////
    // let localRunning = {
    //   INSTORE: 0,
    //   ONLINE: 0,
    //   ALL: 0,
    //   INSTORE_QTY: 0,
    //   ONLINE_QTY: 0,
    //   ALL_QTY: 0
    // };           
    // if(dataPresent?.Message) {
    //   localRunning = {
    //     INSTORE: null,
    //     ONLINE: null,
    //     ALL: null,
    //     INSTORE_QTY: null,
    //     ONLINE_QTY: null,
    //     ALL_QTY: null
    //   };
    // } else {
    //   dataPresent?.forEach(data => {
    //     if (data.DOCTYPE === "INSTORE") {
    //       localRunning.INSTORE = isGross ? (data.TOTAL_GROSS || 0) : (data.TOTAL_NET || 0);
    //       localRunning.INSTORE_QTY = (data.TOTAL_QTY || 0);
    //     } else if (data.DOCTYPE === "ONLINE") {
    //       localRunning.ONLINE = isGross ? (data.TOTAL_GROSS || 0) : (data.TOTAL_NET || 0);
    //       localRunning.ONLINE_QTY = (data.TOTAL_QTY || 0);
    //     }
    //   });
    //   localRunning.ALL = (localRunning.INSTORE || 0) + (localRunning.ONLINE || 0);
    //   localRunning.ALL_QTY = (localRunning.INSTORE_QTY || 0) + (localRunning.ONLINE_QTY || 0);      
    // }

    // //problema quando la data è corrente
    // let localLast = {
    //   INSTORE: 0,
    //   ONLINE: 0,
    //   ALL: 0,
    //   INSTORE_QTY: 0,
    //   ONLINE_QTY: 0,
    //   ALL_QTY: 0
    // };
    // if(dataLast?.Message) {
    //   localLast = {
    //     INSTORE: null,
    //     ONLINE: null,
    //     ALL: null,
    //     INSTORE_QTY: null,
    //     ONLINE_QTY: null,
    //     ALL_QTY: null
    //   };
    // } else {            
    // dataLast?.forEach(data => {
    //   if (data.DOCTYPE === "INSTORE") {
    //     localLast.INSTORE = isGross ? (data.TOTAL_GROSS || 0) : (data.TOTAL_NET || 0);
    //     localLast.INSTORE_QTY = (data.TOTAL_QTY || 0);
    //   } else if (data.DOCTYPE === "ONLINE") {
    //     localLast.ONLINE = isGross ? (data.TOTAL_GROSS || 0) : (data.TOTAL_NET || 0);
    //     localLast.ONLINE_QTY = (data.TOTAL_QTY || 0);
    //   }
    // });
    // localLast.ALL = (localLast.INSTORE || 0) + (localLast.ONLINE || 0);
    // localLast.ALL_QTY = (localLast.INSTORE_QTY || 0) + (localLast.ONLINE_QTY || 0);      
    // }

    // let localLastToDate = {
    //   INSTORE: 0,
    //   ONLINE: 0,
    //   ALL: 0,
    //   INSTORE_QTY: 0,
    //   ONLINE_QTY: 0,
    //   ALL_QTY: 0
    // };      
    // if(dataLTD?.Message) {
    //   localLastToDate = {
    //     INSTORE: null,
    //     ONLINE: null,
    //     ALL: null,
    //     INSTORE_QTY: null,
    //     ONLINE_QTY: null,
    //     ALL_QTY: null
    //   };
    // } else {      
    // dataLTD?.forEach(data => {
    //   if (data.DOCTYPE === "INSTORE") {
    //     localLastToDate.INSTORE = isGross ? (data.TOTAL_GROSS || 0) : (data.TOTAL_NET || 0);
    //     localLastToDate.INSTORE_QTY = (data.TOTAL_QTY || 0);
    //   } else if (data.DOCTYPE === "ONLINE") {
    //     localLastToDate.ONLINE = isGross ? (data.TOTAL_GROSS || 0) : (data.TOTAL_NET || 0);
    //     localLastToDate.ONLINE_QTY = (data.TOTAL_QTY || 0);
    //   }
    // });
    // localLastToDate.ALL = (localLastToDate.INSTORE || 0) + (localLastToDate.ONLINE || 0);
    // localLastToDate.ALL_QTY = (localLastToDate.INSTORE_QTY || 0) + (localLastToDate.ONLINE_QTY || 0);     
    // } 
    //////////////////////









      // let intervalNumber;
      // if (timeFrame == 'day') {
      //   intervalNumber = 24
      // } else if (timeFrame == 'month') {
      //   const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate(); // Number of days in the month
      //   intervalNumber = daysInMonth
      // } else if (timeFrame == 'year') {
      //   intervalNumber = 12
      // }

      // for (let index = 0; index < intervalNumber; index++) {
      //   const element = {
      //     INTERVAL: timeFrame == 'day' ? index : index+1,
      //     INSTORE: 0,
      //     ONLINE: 0
      //   }
      //   transformed.push(element);
      // }

      // data.forEach(item => {
      //   const existing = transformed.find(d => d.INTERVAL === item.INTERVAL);
        
      //   if (existing) {
      //     // existing[item.DOCTYPE] = item.RUNNING_NET; //
      //     existing[item.DOCTYPE] = item[valueField];
      //   // } else {
      //   //   transformed.push({
      //   //     INTERVAL: item.INTERVAL,
      //   //     INSTORE: item.DOCTYPE === 'INSTORE' ? item[valueField] : 0,
      //   //     ONLINE: item.DOCTYPE === 'ONLINE' ? item[valueField] : 0
      //   //   });
      //   }
      // });





// //PARAGHRAPS
// let localRunning = {};
// localRunning.ALL = isGross
//   ? (data[data.length - 2].RUNNING_GROSS) + (data[data.length - 1].RUNNING_GROSS)
//   : (data[data.length - 2].RUNNING_NET) + (data[data.length - 1].RUNNING_NET)
// localRunning.INSTORE = isGross
//   ? data[data.length - 2].RUNNING_GROSS
//   : data[data.length - 2].RUNNING_NET
// localRunning.ONLINE = isGross
//   ? data[data.length - 1].RUNNING_GROSS
//   : data[data.length - 1].RUNNING_NET

// let localLastToDate = {};
// localLastToDate.ALL = isGross 
//   ? (data[data.length - 2].LTD_TOTAL_GROSS) + (data[data.length - 1].LTD_TOTAL_GROSS)
//   : (data[data.length - 2].LTD_TOTAL_NET) + (data[data.length - 1].LTD_TOTAL_NET)
// localLastToDate.INSTORE = isGross 
//   ? data[data.length - 2].LTD_TOTAL_GROSS
//   : data[data.length - 2].LTD_TOTAL_NET
// localLastToDate.ONLINE = isGross 
//   ? data[data.length - 1].LTD_TOTAL_GROSS
//   : data[data.length - 1].LTD_TOTAL_NET
  
// let localLast = {};
// localLast.ALL = isGross 
//   ? (data[data.length - 2].LAST_TOTAL_GROSS) + (data[data.length - 1].LAST_TOTAL_GROSS)
//   : (data[data.length - 2].LAST_TOTAL_NET) + (data[data.length - 1].LAST_TOTAL_NET)
// localLast.INSTORE = isGross
//   ? data[data.length - 2].LAST_TOTAL_GROSS
//   : data[data.length - 2].LAST_TOTAL_NET
// localLast.ONLINE = isGross
//   ? data[data.length - 1].LAST_TOTAL_GROSS
//   : data[data.length - 1].LAST_TOTAL_NET






   // if (timeFrame === 'day') {
    //   previous.setDate(now.getDate() - 1); // Subtract 1 day from the current date
    //   return formatDateToYMDHM(now); // return with time NO; ADD TIME
    // } else if (timeFrame === 'month') {
    //   previous.setMonth(now.getMonth() - 1);
    //   if (now.getMonth() === now.getMonth() - 2 && now.getDate() !== 1) { // Handle month-end overflows (e.g., 31 March -> 28 February)
    //     previous.setDate(0); // go to the last day of the previous month
    //   }
    //   return formatDateToYMDHM(now); // return with time
    // } else if (timeFrame === 'year') {
    //   previous.setFullYear(now.getFullYear() - 1);
    //   return formatDateToYMDHM(now); // return with time
    // }
    // return null; // In case no valid timeframe is selected

  // console.log('calculateDatePastLTD', calculateDatePastLTD());



  
  // if (timeFrame === 'month') {
  //   updatedDate = new Date(updatedDate.getFullYear(), updatedDate.getMonth() + 1, 0); // set to the last day of the selected month //setting the day to 0 of the next month gets the last day of the previous month
  // } else if (timeFrame === 'year') {
  //   updatedDate = new Date(updatedDate.getFullYear(), 11, 31); // set to 31/12 of the selected year
  // }



  // let date = new Date(calendarDate);
  // if (timeFrame === 'day') {
  //   date.setDate(date.getDate() - 1);
  //   return formatDateToYMD(date);
  // } else if (timeFrame === 'month') {
  //   date.setMonth(date.getMonth() - 1);
  //   if (date.getMonth() === date.getMonth() - 2 && date.getDate() !== 1) {
  //     date.setDate(0); // go to the last day of the previous month // Handle month-end overflows (e.g., 31 March -> 28 February)
  //   }
  //   return formatDateToYMD(date); 
  // } else if (timeFrame === 'year') {
  //   date.setFullYear(date.getFullYear() - 1);
  //   return formatDateToYMD(date); 
  // }
  // return null; // In case no valid timeframe is selected





// MainSalesDashboard.propTypes = {
//   running: PropTypes.object,
//   last: PropTypes.object,
//   lastToDate: PropTypes.object,
// }



//old
// todo uncomment quando abbiamo i filtri dal be
  // const [selectedFilters, setSelectedFilters] = useState(
  //   {
  //     shop: 'all',
  //     supplier: 'all',
  //     options: 
  //     {
  //       gross: true,
  //       cumulative: false
  //     },
  //   }
  // );

  // const selectedFilters= 
  //   {
  //     shop: 'all',
  //     supplier: 'all',
  //     options: 
  //     {
  //       gross: true,
  //       cumulative: false
  //     },
  //   }


  
          {/* to be moved */}
            {/* <div className='tab py-3 d-flex'>
            <div onClick={()=>handleDocTypeChange('ALL')} className={docType === 'ALL' ? 'tab-selected' : '' }><p className='m-0 py-1'>{translate('all')}</p></div>
            <div onClick={()=>handleDocTypeChange('INSTORE')} className={docType === 'INSTORE' ? 'tab-selected' : '' }><p className='m-0 py-1'>{translate('instore')}</p></div>
            <div onClick={()=>handleDocTypeChange('ONLINE')} className={docType === 'ONLINE' ? 'tab-selected' : '' }><p className='m-0 py-1'>{translate('online')}</p>
            </div>
            </div> */}
          {/* end of to be moved */}


          
  // const dataFieldEditorOptions ={
  //   labelMode: 'floating', 
  //   stylingMode: 'filled', 
  //   // mode: 'text', 
  //   value: '' ,
  //   acceptCustomValue: false,
  //   openOnFieldClick: true,
  //   type: 'date',
  //   pickerType: "calendar",
  //   displayFormat:"dd/MM/yyyy",
  //   showClearButton: true
  
  // }

  //  todo {default è oggi, dd/MM/yyyy, se schiaccio month diventa MMM yyyy, se schiacico year diventa yyyy



// old code of old interface
            {/* <div className="line my-2">
              </div>
              <div className="compact">
                <table className='grey' width={600}>
                
                {
                  timeFrame === 'day' && (
                    <tbody>
                      <tr className='fs-6'>
                        <td className="text-center pb-3 lh-lg">{translate('Today')}
                          <br/>
                          {/* <strong className='display-6 fw-400 pt-2 grey-blue'>{currency} {running.toFixed(0)}</strong> 
                          <strong className='display-6 fw-400 pt-2 grey-blue'>{currency} {Intl.NumberFormat("it-IT").format(running.toFixed(0))}</strong>
                        </td>
                        <td className="text-center pb-3 lh-lg">{translate('Yesterday')}
                          <br/>
                          <strong className='display-6 fw-400 grey'>{currency} {Intl.NumberFormat("it-IT").format(last.toFixed(0))}</strong>
                        </td>
                      </tr>
                    </tbody>
                )
                }
                {
                  timeFrame === 'month' && (
                    <tbody>
                      <tr className='fs-6'>
                        <td className="text-center pb-3 lh-lg">{translate('Current month')}
                          <br/>
                          <strong className='display-6 fw-400 pt-2 grey-blue'>{currency} {Intl.NumberFormat("it-IT").format(running.toFixed(0))}</strong>
                        </td>
                        <td className="text-center pb-3 lh-lg">{translate('Last month to date')}
                          <br/>
                          <strong className='display-6 fw-400 grey'>{currency} {Intl.NumberFormat("it-IT").format(lastToDate.toFixed(0))}</strong>
                        </td>
                      </tr>
                      <tr className='fs-6'>
                        <td className="text-center lh-lg">{translate('diff Cm/Lmtd')}
                          <br/>
                          <strong className='display-6 fw-400 grey'>{target.toFixed(0)}%</strong>
                        </td>
                        <td className="text-center lh-lg">{translate('Last month')}
                          <br/>
                          <strong className='display-6 fw-400 grey'>{currency} {Intl.NumberFormat("it-IT").format(last.toFixed(0))}</strong>
                        </td>
                      </tr>
                    </tbody>
                  )
                }
                {
                  timeFrame === 'year' && ( 
                    <tbody>
                      <tr className='fs-6'>
                        <td className="text-center pb-3 lh-lg">{translate('Current year')}
                          <br/>
                          <strong className='display-6 fw-400 pt-2 grey-blue'>{currency} {Intl.NumberFormat("it-IT").format(running.toFixed(0))}</strong>
                        </td>
                        <td className="text-center pb-3 lh-lg">{translate('Last year to date')}
                          <br/>
                          <strong className='display-6 fw-400 grey'>{currency} {Intl.NumberFormat("it-IT").format(lastToDate.toFixed(0))}</strong>
                        </td>
                      </tr>
                      <tr className='fs-6'>
                        <td className="text-center lh-lg">{translate('diff Cy/Lytd')}
                          <br/>
                          <strong className='display-6 fw-400 grey'>{target.toFixed(0)}%</strong>
                        </td>
                        <td className="text-center lh-lg">{translate('Last year')}
                          <br/>
                          <strong className='display-6 fw-400 grey'>{currency} {Intl.NumberFormat("it-IT").format(last.toFixed(0))}</strong>
                        </td>
                      </tr>
                    </tbody>
                  )
                } 
                </table>
              </div>
            
              
              
              <div className='tab py-3 d-flex'>
                <div onClick={()=>handleDocTypeChange('ALL')} className={docType === 'ALL' ? 'tab-selected' : '' }><p className='m-0 py-1'>{translate('all')}</p></div>
                <div onClick={()=>handleDocTypeChange('INSTORE')} className={docType === 'INSTORE' ? 'tab-selected' : '' }><p className='m-0 py-1'>{translate('instore')}</p></div>
                <div onClick={()=>handleDocTypeChange('ONLINE')} className={docType === 'ONLINE' ? 'tab-selected' : '' }><p className='m-0 py-1'>{translate('online')}</p></div>
              </div> 
            */}