import { useCallback } from 'react';
import { motion } from 'framer-motion';

const animationTransition = {
  type: "spring",
  bounce: 0, 
  duration: 0.3
  // stiffness: 500,
  // damping: 35
}

const TabSwitch = ({options, setValue, selectedItem, width, height = '24px', display}) => {  //default 24px

    const onItemClick = useCallback((item) => {
        // console.log(item, 'target')
        setValue(item.hint)       
    }, [setValue])

    // calculate the X position based on selected index
    const selectedIndex = options.findIndex(item => item.hint === selectedItem);
    const translateX = `${selectedIndex * 100}%`;

      return (
        <>    
          <div className='tab-switch-container position-relative d-flex justify-content-between px-1 py-1 border-dx'>
            <motion.div
              // layout
              className='position-absolute tab-switch-selected-bg'
              transition={animationTransition}
              animate={{x: translateX}}
              style={{width: width, height: height}}
            />             
            {options.map((item, index)=>(
              <div 
                key={index} 
                onClick={()=>onItemClick(item)} 
                className={`tab-switch-option position-relative d-flex justify-content-center align-items-center ${selectedItem === item.hint ? 'tab-switch-selected' : '' }`}
                style={{width: width, height: height}}
              >
                  {display == 'text' &&
                    <p className='fs-12'>{item.text}</p>
                  }
                  {display == 'icon' &&
                    <i className={`${item.icon} fs-16`}></i>
                  }
              </div>
            ))}
          </div>
        </>
      );
    };
    export default TabSwitch;