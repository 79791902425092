import { useContext, useEffect, useState } from 'react';
// import DeletableItemList from './DeletableItemList';
import InventoryItem from './InventoryItem';
import { ProductContext } from '../contexts/ProductProvider';
import DeletableEditableItemList from './DeletableEditableItemList';
// import InventoriesHistoryList from './InventoriesHistoryList';
// import DeletableItemList from './DeletableItemList';
import InventoryHeader from './InventoryHeader';
import useTranslate from '../utils/useTranslate';
import ReprintLabelsItem from './ReprintLabelsItem';


const ReprintLabelsList = ({showTitle = true, showQuantities=true}) => {
  const {updatedLabelsList, setUpdatedLabelsList} = useContext(ProductContext)
  const translate = useTranslate();

  // const [editableId, setEditableId] = useState(null);
  // const ApiInventoryList = inventoryApi?.ITEMS

  // console.log('inventory items', inventoryApi.ITEMS);
  // console.log('inventoryList', updatedLabelsList);
  

  // useEffect(()=>{
  //   setUpdatedInventoryList(ApiInventoryList)
  // }, [])

  // const onEdit = (itemId) => {
  //   // TODO: implement editing logic
  //   console.log('onEdit', itemId);
  //   setEditableId(itemId);
  //   //TODO: open accordion
  // };


  //TODO: modify logic, cosi se scannerizzo 2 volte lo stesso barcode allora mi rimuove entrambi, che non è quello che voglio, voglio toglierne uno solo?
  const onDelete = (itemId) => {
    const editedList = updatedLabelsList.filter((item) => item.ID_FOR_LIST !== itemId)
    setUpdatedLabelsList(editedList);
    console.log('labelsList' , updatedLabelsList);
  };

  return (
    <>
      {/* <DeletableItemList list={inventoryList} onEdit={onEdit} onDelete={onDelete}>
        {(item) => <InventoryItem item={item} />}
      </DeletableItemList>       */}
      {updatedLabelsList &&
        // inventoryApi.STATUS == 'RILEVATO' ? ( 
        <>
          <div className='d-flex justify-content-start align-items-start flex-column h-fit-content'>
          {showTitle &&
          <div className='list-title'>{translate('Items list')}</div>
          }
          <DeletableEditableItemList list={updatedLabelsList} onDelete={onDelete} editOption={false} additionalClasses='no-scroll w-100 h-100' itemKey='ID_FOR_LIST'>
            {(item) => <ReprintLabelsItem item={item} showQuantities={showQuantities}/>}
          </DeletableEditableItemList>
          </div>
          </>
        // ) : (
        //   <ul className='stock-item-ul list-unstyled'>
        //     {updatedLabelsList?.map((item, index) => (
        //       <li key={index} className="stock-item order-item m-0 text-start hover-grey">
        //         <InventoryItem item={item}/>
        //       </li>
        //     )
        //     )}
            
        // </ul>
        // )
      }
    </>
  );
};

export default ReprintLabelsList;


      // const [editedList, setEditedList] = useState(inventoryList);
    // setEditedList(editedList.filter((item) => item.ID !== itemId));



// const inventoryList = [
//   {
//     ID: '18a055ef-7e17-4e08-831e-c6fb358c26a1', //serve? si per navigare alla pagina dettaglio prodotto
//     IMAGE_01:
//       'https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png',
//     CODE: 'AAAAAA',
//     AUX_CODE: '1234',
//     DESCRIPTION: 'camicia',
//     VARIANT_DESCRIPTION: 'rossa',
//     BRAND: 'superga',
//     SUPPLIER_NAME: 'supplier01', //da mettere?
//     SIZE: 'XXL',
//     ITEM_QTY: 3,
//     BARCODE: '6886460464864', //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
//   },
//   {
//     ID: '18a055ef-7e17-4e08-831e-c6fb358c26a2', //serve? si per navigare alla pagina dettaglio prodotto
//     IMAGE_01:
//       'https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png',
//     CODE: 'BBBBBB',
//     AUX_CODE: '1234',
//     DESCRIPTION: 'scarpe ',
//     VARIANT_DESCRIPTION: 'rosse',
//     BRAND: 'riegel',
//     SUPPLIER_NAME: 'supplier01', //da mettere?
//     SIZE: 'XXL',
//     ITEM_QTY: 3,
//     BARCODE: '6886460464864', //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
//   },
//   {
//     ID: '18a055ef-7e17-4e08-831e-c6fb358c26a3', //serve? si per navigare alla pagina dettaglio prodotto
//     IMAGE_01:
//       'https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png',
//     CODE: 'CCCCCC',
//     AUX_CODE: '1234',
//     DESCRIPTION: 'scarpe ',
//     VARIANT_DESCRIPTION: 'rosse',
//     BRAND: 'riegel',
//     SUPPLIER_NAME: 'supplier01', //da mettere?
//     SIZE: 'XXL',
//     ITEM_QTY: 3,
//     BARCODE: '6886460464864', //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
//   },
//   {
//     ID: '18a055ef-7e17-4e08-831e-c6fb358c26a4', //serve? si per navigare alla pagina dettaglio prodotto
//     IMAGE_01:
//       'https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png',
//     CODE: 'DDDDDD',
//     AUX_CODE: '1234',
//     DESCRIPTION: 'scarpe ',
//     VARIANT_DESCRIPTION: 'rosse',
//     BRAND: 'riegel',
//     SUPPLIER_NAME: 'supplier01', //da mettere?
//     SIZE: 'XXL',
//     ITEM_QTY: 3,
//     BARCODE: '6886460464864', //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
//   },
//   {
//     ID: '18a055ef-7e17-4e08-831e-c6fb358c26a5', //serve? si per navigare alla pagina dettaglio prodotto
//     IMAGE_01:
//       'https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png',
//     CODE: 'EEEEEE',
//     AUX_CODE: '1234',
//     DESCRIPTION: 'scarpe ',
//     VARIANT_DESCRIPTION: 'rosse',
//     BRAND: 'riegel',
//     SUPPLIER_NAME: 'supplier01', //da mettere?
//     SIZE: 'XXL',
//     ITEM_QTY: 3,
//     BARCODE: '6886460464864', //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
//   },
//   {
//     ID: '18a055ef-7e17-4e08-831e-c6fb358c26a6', //serve? si per navigare alla pagina dettaglio prodotto
//     IMAGE_01:
//       'https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png',
//     CODE: 'FFFFFF',
//     AUX_CODE: '1234',
//     DESCRIPTION: 'scarpe ',
//     VARIANT_DESCRIPTION: 'rosse',
//     BRAND: 'riegel',
//     SUPPLIER_NAME: 'supplier01', //da mettere?
//     SIZE: 'XXL',
//     ITEM_QTY: 3,
//     BARCODE: '6886460464864', //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
//   },
//   {
//     ID: '18a055ef-7e17-4e08-831e-c6fb358c26a7', //serve? si per navigare alla pagina dettaglio prodotto
//     IMAGE_01:
//       'https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png',
//     CODE: 'GGGGGG',
//     AUX_CODE: '1234',
//     DESCRIPTION: 'scarpe ',
//     VARIANT_DESCRIPTION: 'rosse',
//     BRAND: 'riegel',
//     SUPPLIER_NAME: 'supplier01', //da mettere?
//     SIZE: 'XXL',
//     ITEM_QTY: 3,
//     BARCODE: '6886460464864', //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
//   },
// ];
