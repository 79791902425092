//https://js.devexpress.com/React/Demos/WidgetsGallery/Demo/Tabs/Overview/MaterialBlueLight/
// import Tabs from 'devextreme-react/tabs';
// import { useCallback } from 'react';

import useTranslate from '../utils/useTranslate';

const TabNavigation = ({dataSource, onItemClick, selectedValue, fontSize='fs-14', noMarginTop, roleString}) => {
  const translate = useTranslate(); 
  return (
    <>
      <div className={`tab-nav-container d-flex justify-content-around align-items-baseline grey ${noMarginTop ? 'm-0' : ''}`}>
        {dataSource.map((item, index)=>(
          // if i pass a roleString for restricting tabs view to certain roles, then filter by role, else dont filter
          roleString 
          ? item.visibleFor.includes(roleString) && (
          <div 
            key={index} 
            onClick={()=>onItemClick(item)} 
            // className={`w-100 tab-option ${selectedItem.id === item.id ? 'tab-selected' : '' } `}>
            className={`w-100 tab-option ${item.value === selectedValue ? 'tab-selected' : '' }
              position-relative d-flex align-items-center justify-content-center cursor-pointer  `}>
               
            {/* removed  */}
            <div className='d-flex align-items-center'>
              {item.icon && (
                <i className={`${item.icon} fs-18 pe-2`}></i>
              )}
              <p className={`py-1 d-inline ${fontSize}`}>{translate(item.text) || translate(item.value)}</p>
            </div>

            {item.value === selectedValue && (
              <div className="tab-selected-bottom-el position-absolute bottom-0 start-50 translate-middle-x rounded-top transition bg-light-blue w-100"
              />
            )}
          </div>
          ) : ( 
            <div 
            key={index} 
            onClick={()=>onItemClick(item)} 
            // className={`w-100 tab-option ${selectedItem.id === item.id ? 'tab-selected' : '' } `}>
            className={`w-100 tab-option ${item.value === selectedValue ? 'tab-selected' : '' }
              position-relative d-flex align-items-center justify-content-center cursor-pointer  `}>
               
            {/* removed  */}
            <div className='d-flex align-items-center'>
              {item.icon && (
                <i className={`${item.icon} fs-18 pe-2`}></i>
              )}
              <p className={`py-1 d-inline ${fontSize}`}>{translate(item.text) || translate(item.value)}</p>
            </div>

            {item.value === selectedValue && (
              <div className="tab-selected-bottom-el position-absolute bottom-0 start-50 translate-middle-x rounded-top transition bg-light-blue w-100"
              />
            )}
          </div>
          )
        ))}
      </div>
    </>
  );
};
export default TabNavigation;

{/* <div 
          key={index}
          onClick={() => onItemClick(item)} */}
        //   className={`
        //     position-relative 
        //     d-flex 
        //     align-items-center 
        //     justify-content-center 
        //     px-4 py-1
        //     cursor-pointer 
        //     ${item.value === selectedValue 
        //       ? 'text-primary' 
        //       : 'text-secondary'}
        //   `}
        //   style={{
        //     cursor: 'pointer',
        //     transition: 'color 0.3s ease'
        //   }}
        // >
          {/* Tab Content */}
          // <div className="d-flex align-items-center">
          //   <i className={`${item.icon} me-2`}></i>
          //   <span className="small">{item.text}</span>
          // </div>

          {/* Highlighting Element */}
          // {item.value === selectedValue && (
          //   <div 
          //     className="
          //       position-absolute 
          //       bottom-0 
          //       start-50 
          //       translate-middle-x 
          //       bg-primary 
          //       rounded-top 
          //       transition
          //     "
          //     style={{
          //       width: '80%',
          //       height: '4px',
          //       borderRadius: '8px 8px 0 0'
          //     }}
          //   />
          // )}
        // </div>

// not working
  // const selectedIndex = useCallback(()=>{
  //   return (dataSource.findIndex(item => item.id === selectedItem.id)
  // )}, [])




// const tabsText = [
//   {
//     id: 0,
//     text: 'User',
//   },
//   {
//     id: 1,
//     text: 'Analytics',
//   },
//   {
//     id: 2,
//     text: 'Clients',
//   },
//   {
//     id: 3,
//     text: 'Orders',
//   },
//   {
//     id: 4,
//     text: 'Favorites',
//   },
//   {
//     id: 5,
//     text: 'Search',
//   },
// ];
//  const tabsIconAndText = [
//   {
//     id: 0,
//     text: 'User',
//     icon: 'user',
//   },
//   {
//     id: 1,
//     text: 'Analytics',
//     icon: 'chart',
//   },
//   {
//     id: 2,
//     text: 'Clients',
//     icon: 'accountbox',
//   },
//   {
//     id: 3,
//     text: 'Orders',
//     icon: 'ordersbox',
//   },
//   {
//     id: 4,
//     text: 'Favorites',
//     icon: 'bookmark',
//   },
//   {
//     id: 5,
//     text: 'Search',
//     icon: 'search',
//   },
// ];
//  const tabsIcon = [
//   {
//     id: 0,
//     icon: 'user',
//   },
//   {
//     id: 1,
//     icon: 'chart',
//   },
//   {
//     id: 2,
//     icon: 'accountbox',
//   },
//   {
//     id: 3,
//     icon: 'ordersbox',
//   },
//   {
//     id: 4,
//     icon: 'bookmark',
//   },
//   {
//     id: 5,
//     icon: 'search',
//   },
// ];
// const orientations = ['horizontal', 'vertical'];
// const stylingModes = ['primary', 'secondary'];
// const orientationLabel = { 'aria-label': 'Orientation' };
// const stylingModeLabel = { 'aria-label': 'Styling Mode' };
// const iconPositionLabel = { 'aria-label': 'Icon Position' };
// const iconPositions = ['top', 'start', 'end', 'bottom'];


// const STRICT_WIDTH_CLASS = 'strict-width';
// function OptionWrapper(props) {
//   return (
//     <div className="option">
//       {props.caption && <span>{props.caption}</span>}
//       {props.children}
//     </div>
//   );
// }






// const [orientation, setOrientation] = useState(orientations[0]);
  // const [stylingMode, setStylingMode] = useState(stylingModes[1]);
  // const [iconPosition, setIconPosition] = useState(iconPositions[0]);
  // const [showNavigation, setShowNavigation] = useState(false);
  // const [scrollContent, setScrollContent] = useState(false);
  // const [fullWidth, setFullWidth] = useState(false);
  // const [width, setWidth] = useState('auto');
  // const [rtlEnabled, setRtlEnabled] = useState(false);
  // const [widgetWrapperClasses, setWidgetWrapperClasses] = useState(
  //   'widget-wrapper widget-wrapper-horizontal',
  // );
  // const enforceWidthConstraint = useCallback(
  //   (shouldRestrictWidth) => {
  //     const callback = (prevClasses) => {
  //       const restClasses = prevClasses
  //         .split(' ')
  //         .filter((className) => className !== STRICT_WIDTH_CLASS)
  //         .join(' ');
  //       const strictWidthClass = shouldRestrictWidth ? STRICT_WIDTH_CLASS : '';
  //       return `${restClasses} ${strictWidthClass}`;
  //     };
  //     setWidgetWrapperClasses(callback);
  //   },
  //   [setWidgetWrapperClasses],
  // );
  // const stylingModeChanged = useCallback(
  //   (e) => {
  //     setStylingMode(e.value);
  //   },
  //   [setStylingMode],
  // );
  // const iconPositionChanged = useCallback(
  //   (e) => {
  //     setIconPosition(e.value);
  //   },
  //   [setIconPosition],
  // );
  // const orientationChanged = useCallback(
  //   (e) => {
  //     const isVertical = e.value === 'vertical';
  //     const callback = (prevClasses) => {
  //       const restClasses = prevClasses
  //         .split(' ')
  //         .filter(
  //           (className) =>
  //             className !== (isVertical ? 'widget-wrapper-horizontal' : 'widget-wrapper-vertical'),
  //         )
  //         .join(' ');
  //       return `${restClasses} widget-wrapper-${e.value}`;
  //     };
  //     setWidgetWrapperClasses(callback);
  //     setOrientation(e.value);
  //   },
  //   [setOrientation, setWidgetWrapperClasses],
  // );
  // const showNavigationChanged = useCallback(
  //   (e) => {
  //     const shouldRestrictWidth = e.value || scrollContent;
  //     enforceWidthConstraint(shouldRestrictWidth);
  //     setShowNavigation(e.value);
  //   },
  //   [scrollContent, setShowNavigation, enforceWidthConstraint],
  // );
  // const scrollContentChanged = useCallback(
  //   (e) => {
  //     const shouldRestrictWidth = e.value || showNavigation;
  //     enforceWidthConstraint(shouldRestrictWidth);
  //     setScrollContent(e.value);
  //   },
  //   [showNavigation, setScrollContent, enforceWidthConstraint],
  // );
  // const fullWidthChanged = useCallback(
  //   (e) => {
  //     setFullWidth(e.value);
  //     setWidth(e.value ? '100%' : 'auto');
  //   },
  //   [setFullWidth, setWidth],
  // );
  // const rtlEnabledChanged = useCallback(
  //   (e) => {
  //     setRtlEnabled(e.value);
  //   },
  //   [setRtlEnabled],
  // );
