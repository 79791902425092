import MainFaq from '../components/MainFaq';
import SidebarHeader from '../components/SidebarHeader';
import useTranslate from '../utils/useTranslate';

const Faq = () => {
    const translate = useTranslate();
    const pageTitle = translate('FAQs')
    
  return (
    <>
        <SidebarHeader title={pageTitle} backArrowVisible={true} />
        <div className={`page-container d-flex flex-column`}>
            <MainFaq/>
        </div>
  </>
  )
}

export default Faq