import {Button} from 'devextreme-react/button';
import { useCallback, useState } from 'react';
import useTranslate from '../utils/useTranslate';
import { Popover } from 'devextreme-react/popover';
import { Link } from 'react-router-dom';


const HeaderInfo = ({inventoryApi, infoText, buttonText, onButtonClick, dependency, withPopOver = false, link}) => {
    // const [newOrdersNumber, setNewOrdersNumber] = useState(4);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const translate = useTranslate();
    
    //questa andra passata dal padre che fa effettivamente la api call
    // const showNewOrders = () => {
    //     console.log('Showing new orders');
    //     setIsPopupVisible(false);
    //     // setNewOrdersNumber(0);
    //     // fetchOdreds
    //     // set notiifcations to 0
    //     // Add your logic to show new orders
    // };

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
    };

    const hidePopup = () => {
        setIsPopupVisible(false);
    };

    const renderContent = useCallback(() => {
        return (
            <div className="notification-popup-content container text-wrap overflow-hidden">
                <div className="row mt-2">
                    <div className="col-4 text-end light-grey"><p>{translate('Store')}: </p></div>
                    <div className="col-8 text-start"><p>{inventoryApi.STORE}</p></div>
                </div>
                <div className="row">
                    <div className="col-4 text-end light-grey"><p>{translate('Date')}: </p></div>
                    <div className="col-8 text-start"><p>{inventoryApi.DATE}</p></div>
                </div>
                <div className="row">
                    <div className="col-4 text-end light-grey"><p>{translate('Description')}: </p></div>
                    <div className="col-8 text-start"><p>{inventoryApi.DESCRIPTION}</p></div>
                </div>
                <div className="row">
                    <div className="col-4 text-end light-grey"><p>{translate('Type')}: </p></div>
                    <div className="col-8 text-start"><p>{translate(inventoryApi.TYPE)}</p></div>
                </div>
                <div className="row mb-2">
                    <div className="col-4 text-end light-grey"><p>{translate('Status')}: </p></div>
                    <div className="col-8 text-start"><p>{translate(inventoryApi.STATUS)}</p></div>
                </div>
                  
                  
                <Button
                    className='mt-2'
                    text={translate('ok')} 
                    onClick={hidePopup}
                    stylingMode="contained"
                    type='default'
                    width={80}
                    // disabled={!newOrdersNumber}
                /> 
            </div>
        )
    }, [inventoryApi])

  return (
    withPopOver ? 
    (
    <div className="notification-bell-container">
        <div className="notification-bell" onClick={togglePopup} id='notification-bell'>
        <button className='p-0 border-0 bg-light-grey'>
            <i id='notification-bell-target' className='fa-solid fa-circle-info light-grey'></i>
        </button>
        </div>
        <Popover 
            id="notification-popup"
            target='#notification-bell'
            contentRender={renderContent} 
            visible={isPopupVisible}
            hideOnOutsideClick={true}
            onHiding={hidePopup}
            showTitle={false}
            showCloseButton={false}
            width={300}
            height={'auto'}
            position='bottom'
            />
    </div>  
    ) : (
        <div className="notification-bell-container">
            <div className="notification-bell" id='notification-bell'>
            <button className='p-0 border-0 bg-light-grey'>
                {/* <i id='notification-bell-target' className='fa-solid fa-circle-info light-grey'></i> */}
                <Link to={link}><i className="fa-solid fa-circle-info light-grey"></i></Link>
            </button>
            </div>
        </div>  
    )
    )
}

export default HeaderInfo