import { TextBox } from 'devextreme-react/text-box';
import { useContext } from 'react';
import { ProductContext } from '../contexts/ProductProvider';
import useTranslate from '../utils/useTranslate';

const dropdownOptions = {
    inputAttr:{
        autocomplete: 'new-password',
    }
}


const  SearchTextbox = ({value, editorOptions, onValueChange, placeholder = 'Search', onEnterKey}) => {
    const translate = useTranslate();
    // const {stockSearch, setStockSearch} = useContext(ProductContext)

    // useEffect(()=>{
    //     // console.log(state.stockSearch, 'stato'); //todo: remove
    //     onSearchChanged() //question: what does this function do, hence eventully change order of onSearch and dispatch
    // }, [state.stockSearch])

    return (
        <TextBox 
            mode= "search"
            placeholder={translate(placeholder)}
            showClearButton={true}
            value={value}
            valueChangeEvent="input"
            onValueChange={onValueChange}
            editorOptions={editorOptions}
            onEnterKey={onEnterKey}
        />
    );
}
 
export default SearchTextbox;

// ProductLookupTextbox.propTypes = {
//     onSearchChanged: PropTypes.func
// }