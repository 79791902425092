// import { Button } from 'devextreme-react/button'
// import useTranslate from '../utils/useTranslate'
import { useContext, useEffect, useMemo, useState } from 'react'
import ButtonsList from './ButtonsList'
import { ProductContext } from '../contexts/ProductProvider'
import { isArraysEqual } from '../utils/functions'


const ReprintLabelsFooter = (
  // {firstBtnType, firstBtnText, firstBtnFunction, secondBtnType, secondBtnText, secondBtnFunction}
  {buttonsList}
) => {

  // const { updatedLabelsList } = useContext(ProductContext)
  // const buttonsList = useMemo(() => [
  //   {
  //     id: '1',
  //     type: 'normal', //"normal", "success", "default", "danger"
  //     text: 'exit',
  //     function: () => handleExit(),
  //     stylingMode: '', //default is "contained"
  //     className: 'w-40',
  //   },
  //   {
  //     id: '2',
  //     type: 'default', //"normal", "success", "default", "danger"
  //     text: 'continue', //complete detection
  //     // function: () => {},
  //     function: () => handleContinue(), //TODO: scrivere funzione per concludi rilievo
  //     stylingMode: '', //default is "contained"
  //     className: 'w-40',
  //     disabled: updatedLabelsList && updatedLabelsList.length > 0 ? false : true
  //   }
  // ] , [updatedLabelsList])

 
    return (
      buttonsList &&
      <div className="footer-menu d-flex justify-content-evenly align-items-center">
        <ButtonsList buttonsList={buttonsList} />
      </div>
    
  )
}

export default ReprintLabelsFooter