import { Popup } from 'devextreme-react/popup';
import { useCallback, useMemo } from 'react';
import useTranslate from '../utils/useTranslate';
import { Button } from 'devextreme-react/button';
import ConfirmationPopup from './ConfirmationPopup';

const ConfirmResumeListPopup = ({handleNewList, handleResumePreviousList, dependency, isPopupVisible, hidePopup}) => {
    const translate = useTranslate()
    const popupResumeListTitle = useMemo(()=>translate('Resume previous list?'),[])
    const popupResumeListBody = useMemo(()=>translate('A previous list is available in the memory, do you want to continue it?'),[])
    const popupResumeListButtons= useMemo(() => [
            {
                id: '1',
                type: 'normal', //"normal", "success", "default", "danger"
                text: translate('new list'),
                function: () => handleNewList(),
                stylingMode: 'contained', //default is "contained"
                className: 'bg-grey w-40',
            },
            {
                id: '2',
                type: 'default', //"normal", "success", "default", "danger"
                text: translate('continue list'),
                function: () => handleResumePreviousList(),
                stylingMode: 'contained', //default is "contained"
                className: 'w-40',
            },
    ], [dependency])
   
    return (
        <ConfirmationPopup
        popupTitle={popupResumeListTitle} 
        popupMessage={popupResumeListBody}
        popupButtons={popupResumeListButtons}
        dependency={popupResumeListButtons} //allows the enable of btn when store is selected
        isPopupVisible={isPopupVisible}
        // hidePopup
        hideOnOutsideClick={false}
        // showTitle = {false}
        showCloseButton = {false} 
        // hidePopup={()=>handleHidePopup()}
        />
    )
}

export default ConfirmResumeListPopup
