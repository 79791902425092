import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ProductContext } from '../contexts/ProductProvider';
import QRscannerWithList from '../components/QRscannerWithList'
import useTranslate from '../utils/useTranslate';
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarHeader from '../components/SidebarHeader';
import ReprintLabelsList from '../components/ReprintLabelsList';
import QRScannerWithList from '../components/QRscannerWithList';
import ConfirmationPopup from '../components/ConfirmationPopup';
import { saveToLocalStorage } from '../utils/functions';
import ConfirmResumeListPopup from '../components/ConfirmResumeListPopup';
// import SidebarHeader from './SidebarHeader';


const QRScannerWithListPage = () => {
  const { 
    // setQRcode, setInventoryBarcode, setInternalBarcode, setManufacturerBarcode, 
    setReprintLabelsBarcode, updatedLabelsList, fetchItemWithSizes,reprintLabelsStoreId, setUpdatedLabelsList
  } = useContext(ProductContext); // removed setTypeSearch, setStockSearch,
  const translate = useTranslate()
  const navigate = useNavigate()
  // const [result, setResult] = useState(null);
  const location = useLocation();

  // const [isScanning, setIsScanning] = useState(false)
  const [isResumeListPopupVisible, setIsResumeListPopupVisible] = useState(false)

  


  const ACTIONS = {
    // 'product-lookup': {
    //   default: {
    //     onScan: (code) => {
    //       setQRcode(code);
    //       navigate('/product-lookup/:id');
    //     },
    //     // title: 'Barcode reader'
    //   }
    // },
    // // TODO move QR reader to separate section with tab navigation choice 
    // 'inventory': {
    //   default: {
    //     onScan: (code) => {
    //       setInventoryBarcode(code);
    //       navigate('/inventory/:id');
    //     },
    //     // title: 'Barcode reader'
    //   }
    // },
    // //end of move QR
    // 'barcode-association': {
    //   internal: {
    //     onScan: (code) => {
    //       console.log('scanned', code);
    //       setInternalBarcode(code);
    //       navigate('/barcode-association', { 
    //         state: { 
    //           scannedCode: code,
    //           field: 'internal'
    //         }
    //       });
    //     },
    //     // title: 'Scan Internal Barcode'
    //   },
    //   manufacturer: {
    //     onScan: (code) => {
    //       setManufacturerBarcode(code)
    //       navigate('/barcode-association', { 
    //         state: { 
    //           scannedCode: code,
    //           field: 'manufacturer'
    //         }
    //       });
    //     },
    //     // title: 'Scan Manufacturer Barcode'
    //   }
    // },
    'reprint-labels': {
      default: {
        onScan: (code) => {
          console.log('new barcode', code);
          
          // setReprintLabelsBarcode(code);
          fetchItemWithSizes(code, true)
          // if (onQRscanned) onQRscanned(code); // Uses the fresh 'code' value directly
          // navigate('/inventory/:id');
        },
        // title: 'Barcode reader'

        //future: when need other functions for same popup, just add them in new path as 'reprint-labels'
        handleNewList: () => { //no need to reset local storage list bc it will be automatically overwritten
          setUpdatedLabelsList([])
          // localStorage.removeItem("labelsList") //case scenario in cui avere lista vecchia potrebbe dare fastidio: la persona naviga ripetutamente tra le pagine, cliccando ogni volta "nuova lista". RARO+
          setIsResumeListPopupVisible(false)
        }, 
        handleResumePreviousList: () => { 
          let savedLists = JSON.parse(localStorage.getItem("labelsList")) //the useEffect checks if there is a savedList, so here can't be null
          setUpdatedLabelsList(savedLists);
          setIsResumeListPopupVisible(false)
        },
        localStorageList: "labelsList",
        updatedProviderList: updatedLabelsList
      }
    },
  };

  // Get the source from the state passed during navigation
  // const { source = 'product-lookup', textField = 'default' } = location.state || {}; //with defaults
  // const action = ACTIONS[source]?.[textField] || ACTIONS['product-lookup'].default;
  const { source, textField, data  } = location.state || {};
  const action = ACTIONS[source]?.[textField] || ACTIONS[source]?.default;

  
  useEffect(()=>{ //al caricam controlla se mandare popup
    const savedListsExists = localStorage.getItem(action.localStorageList) != null;
    if((!action.updatedProviderList || action.updatedProviderList.length < 1) && savedListsExists){ //if there is no useState list but there is one in the local storage
      setIsResumeListPopupVisible(true)
    } 
  },[]) //mi serve solo al caricmento, removed  action.updatedProviderList

  useEffect(() => {
    if(action.updatedProviderList && action.updatedProviderList.length > 0){
      saveToLocalStorage(action.localStorageList, action.updatedProviderList);
    }
  }, [action.localStorageList, action.updatedProviderList]);

  return (
    <>
      <SidebarHeader title={translate('Barcode reader')} backArrowVisible={true} />
      <QRScannerWithList action={action} list={data}/>

      <ConfirmResumeListPopup 
        handleNewList={action.handleNewList}
        handleResumePreviousList={action.handleResumePreviousList}
        // dependency={dependency}
        isPopupVisible={isResumeListPopupVisible}
        // hidePopup={hidePopup}
      />

    </>
  )
}
export default QRScannerWithListPage
//fine giapponese
