import { useRef, useState } from 'react'
import Sidebar from './Sidebar'
import PageHeader from './PageHeader'

const SidebarHeader = ({title, subtitle, backArrowVisible, bell, info, store }) => {
    const burgerMenuRef = useRef(null);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const toggleSidebar = (e) => {
      e.stopPropagation();
      setIsSidebarVisible(!isSidebarVisible);
    }

  return (
    <>
        <Sidebar isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible} burgerMenuRef={burgerMenuRef}/>
        <PageHeader title={title} subtitle={subtitle} toggleSidebar={toggleSidebar} backArrowVisible={backArrowVisible} burgerMenuRef={burgerMenuRef} bell={bell} info={info} store={store}/>
    </>
  )
}

export default SidebarHeader