//List in this item is an array of obj, each with an ID

import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useTranslate from '../utils/useTranslate';
// import { Popup, ToolbarItem } from 'devextreme-react/popup';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react/button';
import ConfirmDeletePopup from './ConfirmDeletePopup';
import ConfirmEditPopup from './ConfirmEditPopup';

const deleteAnimation = {
  // initial: { height: 'auto', opacity: 1 },
  exit: {
    height: 0,
    // scaleY: 0,
    opacity: 0,
    // x: '-100%',
    transition: {
      type: 'spring',
      duration: 0.2,
      ease: [0.32, 0.72, 0, 1], // Custom easing for smoother motion
      // ease: easeOut // Custom easing for smoother motion
    },
  },
  // transition: {
  // opacity: {
  // transition: {
  //     duration: 5
  // }
  // }
  // }
};

const dragAnimation = {
  transition: {
    type: 'spring',
    damping: 30, // Increased damping for smoother motion
    stiffness: 200, // Adjusted stiffness for better feel
    mass: 0.5, // Reduced mass for lighter feel
  },
};


const DeletableEditableItemList = ({
  children,
  list,
  onDelete,
  onEdit,
  itemKey = 'ID',
  additionalClasses = '',
  editOption = false
}) => {
  const translate = useTranslate();
  // const [ editedList, setEditedList ] = useState(list)
  const [openItemId, setOpenItemId] = useState(null);
  const [isDeletePopupVisible, setDeletePopupVisibility] = useState(false);
  const [isEditPopupVisible, setEditPopupVisibility] = useState(false);
  // const popupTitle = useMemo(() => translate('Delete confirmation'), []);
  
  const dragThreshold = useMemo(()=>{
    if(editOption) return -140
    else return -80;
  },[editOption])  //if change this, have to change css trashcan
    
    const showPopup = useCallback((editOrDelete) => {
      editOrDelete == 'edit' ? setEditPopupVisibility(true) 
      : editOrDelete == 'delete' ? setDeletePopupVisibility(true)
      : console.log('popup undefined');
    }, []);

    const hidePopup = useCallback((editOrDelete) => {
      editOrDelete == 'edit' ? setEditPopupVisibility(false) 
      : editOrDelete == 'delete' ? setDeletePopupVisibility(false)
      : (setEditPopupVisibility(false), setDeletePopupVisibility(false));
    }, []);

    const handleEdit = useCallback(() => {
        onEdit(openItemId);
        setOpenItemId(null);
        hidePopup('edit');
    }, [hidePopup, onEdit, openItemId]);

    const handleDelete = useCallback(() => {
        onDelete(openItemId);
        setOpenItemId(null);
        hidePopup('delete');
    }, [hidePopup, onDelete, openItemId]);

    const handleCancel = useCallback(() => {
        setOpenItemId(null);
        hidePopup();
    }, [hidePopup]);

    const onDragEnd = useCallback(({ offset }, item) => {
        const itemId = item[itemKey];
        // If already open and dragged right, close it
        if (openItemId === itemId && offset.x > dragThreshold / 2) {
        setOpenItemId(null);
        return;
        }
        // If closed and dragged left past threshold, open it
        if (offset.x < dragThreshold / 2) {
        setOpenItemId(itemId);
        console.log('set open item', itemId);
        
        } else {
        setOpenItemId(null);
        }
    }, [itemKey, openItemId]);


  return (
    <>
      <ul className={`stock-item-ul  list-unstyled ${additionalClasses}`}>
        <AnimatePresence mode="popLayout">
          {list?.map((item) => (
            // rsponsible for height to 0 disappearance
            <motion.li
              key={item[itemKey]}
              className="stock-item order-item m-0 text-start hover-grey"
              layout
              // initial={deleteAnimation.initial}
              exit={deleteAnimation.exit}
              // // animate={{ x: openItemId === item[itemKey] ? dragThreshold : 0 }}
              // // transition={deleteAnimation.transition}
            >
              <motion.div
                drag="x"
                dragConstraints={{ left: dragThreshold, right: 0 }}
                dragElastic={0.2}
                dragTransition={{ 
                    // Add these properties to make dragging more sensitive
                    power: 0.5, // Lower values make it more sensitive
                    timeConstant: 200, // Adjust this to control drag responsiveness
                    modifyTarget: (target) => {
                      // Force snapping to threshold if dragged past a certain point
                      return target < dragThreshold / 2 ? dragThreshold : 0;
                    }
                  }}
                animate={{
                  x: openItemId === item[itemKey] ? dragThreshold : 0,
                }}
                transition={dragAnimation.transition}
                // animate={dragAnimation.animate}
                // onDragEnd={(_, info) => handleDragEnd(info, item.barcode)}
                onDragEnd={(_, info) => onDragEnd(info, item)} // _ stands for event, i don't need event
                className="deletable-li"
              >
                {children(item)}

                <div
                  className="delete-div ps-3"
                  //   onClick={() => handleDelete(item[itemKey])}
                  // onClick={() => showPopup()}
                >
                 {editOption && 
                  <div className="edit-div-child"
                    onClick={() => showPopup('edit')}
                  >
                    <i className="delete-icon fa-solid fa-pen-to-square"></i>
                  </div>
                  }

                  <div className="delete-div-child"
                    onClick={() => showPopup('delete')}
                  >
                    <i className="delete-icon fa-solid fa-trash-can"></i>
                  </div>
                </div>
              </motion.div>
            </motion.li>
          ))}
        </AnimatePresence>
      </ul>

    <ConfirmDeletePopup 
      handleDelete={handleDelete}
      handleCancel={handleCancel}
      openItemId={openItemId}
      isPopupVisible={isDeletePopupVisible}
      hidePopup={hidePopup}
    />

    <ConfirmEditPopup
      handleEdit={handleEdit}
      handleCancel={handleCancel}
      openItemId={openItemId}
      isPopupVisible={isEditPopupVisible}
      hidePopup={hidePopup}
    />
    </>
  );
};

export default DeletableEditableItemList;





    // const renderContent = useCallback(() => {
    //     return (
    //     <div className="px-4 py-3">
    //         <p>
    //         {translate('Are you sure you want to delete this item from the current inventory?')}
    //         </p>
    //         <div className='d-flex justify-content-between mt-3'>
    //         <Button
    //         text={translate('Cancel')}
    //         stylingMode="contained"
    //         type="normal"
    //         className='bg-grey'
    //         //   onClick={hidePopup}
    //         onClick={() => handleCancel()}
    //         />
    //         <Button
    //         text={translate('Delete')}
    //         stylingMode="contained"
    //         type="danger"
    //         onClick={() => handleDelete()}
    //         />
    //         </div>
    //     </div>
    //     );
    // }, [openItemId]);


    
      {/* <Popup
        contentRender={renderContent}
        visible={isPopupVisible}
        hideOnOutsideClick={true}
        onHiding={hidePopup}
        showTitle={true}
        title={popupTitle}
        showCloseButton={true}
        defaultWidth={290}
        defaultHeight={'auto'}
        // resizeEnabled={true}
      >     
      </Popup> */}


//   const cancelButton = useMemo(
//     () => ({
//       text: translate('Cancel'),
//       stylingMode: 'contained',
//       type: 'normal',
//       elementAttr: { class: 'bg-grey'},
//       onClick: togglePopup,
//     }),
//     [togglePopup]
//   );

//   const confirmButton = useMemo(
//     () => ({
//       text: translate('Delete'),
//       stylingMode: 'contained',
//       type: 'danger',
//       onClick: () => {
//         togglePopup();
//         handleDelete(openItemId);
//       },
//     }),
//     [togglePopup, openItemId]
//   );

        {/* <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={cancelButton}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={confirmButton}
        /> */}


// const deleteAnimation = { height: 0, width:0}
// const deleteTransition = {
//     opacity: {
//         transition: {
//             duration: 0
//         }
//     }
// }


{
  /* <div className="stock-item-child d-flex justify-content-between align-items-center w-100">
                  <div className="stock-item border border-0 d-flex align-items-center flex-shrink-1 flex-grow-1 m-0 p-0 ">
                    {/* image 
                    <div className="img-container d-flex justify-content-center align-items-center">
                      <img src={httpToHttps(item.IMAGE_01)} alt="image" />
                    </div>

                    {/* data 
                    <div className="d-flex justify-content-between  text-start me-2 pe-0 pe-sm-4 flex-grow-1">
                      <div>
                        <p className="text-uppercase fw-semibold fs-14 lh-1">
                          {item.CODE}
                          <span className="grey"> {item.AUX_CODE}</span>
                        </p>
                        <div className="grey">
                          <p className="description fs-12">
                            {item.DESCRIPTION}
                          </p>
                          <p className="variant fs-12">
                            {item.VARIANT_DESCRIPTION}
                          </p>
                          <p className="supplier-name text-uppercase d-inline grey-emphasis fs-12">
                            {item.BRAND}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="title text-uppercase fw-semibold flex-shrink-0 fs-4">
                          {item.SIZE}
                        </p>
                        {item.ITEM_QTY > 1 && (
                          <p className="fs-5 pt-1">
                            <span className="light-grey fs-12">
                              {translate('qty')}:{' '}
                            </span>
                            {item.ITEM_QTY}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* arrow 
                  <div className="arrow pe-0 pe-sm-5 flex-shrink-0">
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div> */
}
